import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getWebinarLink, getWebinars, getWebinarStats } from "../api/webinar";
import { errorHandler } from "../utils/errorHandler";

const initialState = {
  currentWebinar: {
    id: "",
    title: "",
    startDay: "",
    startTime: "",
    endDay: "",
    endTime: "",
    description: "",
  },
  items: [],
  type: "actual",
  stats: {
    actual: 0,
    moderation: 0,
    draft: 0,
    denied: 0,
  },
};

export const fetchWebinarLink = createAsyncThunk("webinar/link", async (params) => {
  try {
    const data = await getWebinarLink(params);

    if (data?.success && data?.data?.link) {
      // open webinar
      window.open(data?.data?.link);
      return data?.data?.link;
    } else errorHandler(data?.data);
  } catch (error) {
    errorHandler(error);
  }
});

export const fetchWebinars = createAsyncThunk("webinars/get", async (params) => {
  try {
    const { data } = await getWebinars(params);
    if (!data.success) {
      return;
    }

    const resp = data.data?.data;

    return { type: params.type, items: resp };
  } catch (error) {}
});

export const fetchWebinarStats = createAsyncThunk("webinars/stats", async (params) => {
  try {
    const { data } = await getWebinarStats(params);
    if (!data.success) {
      return;
    }

    const resp = data.data;

    return { type: params.type, stats: resp };
  } catch (error) {}
});

export const webinarSlice = createSlice({
  name: "webinars",
  initialState,
  reducers: {
    setCurrentWebinar(state, { payload }) {
      state.currentWebinar = payload;
    },

    resetCurrentWebinar(state) {
      state.currentWebinar = initialState.currentWebinar;
    },

    resetWebinars(state) {
      state.items = [];
    },

    setWebinarType(state, { payload }) {
      state.type = payload;
    },

    setAllWebinars(state, { payload }) {
      state.items = payload;
    },

    addNewWebinar(state, { payload }) {
      state.stats[payload.webinarType] = state.stats[payload.webinarType] + 1;

      if (payload.selectedWebinarType === payload.webinarType) {
        state.items.push(payload.webinar);
      }
    },

    updateWebinar(state, { payload }) {
      const webinar = payload.webinar;

      state.items = state.items.map((w) => (w.id === webinar.id ? webinar : w));
    },

    deleteWebinar(state, { payload }) {
      state.items = state.items.filter((w) => w.id !== payload.webinarId);
      state.stats[payload.webinarType] = state.stats[payload.webinarType] - 1;
    },
  },
  extraReducers: {
    [fetchWebinarLink.fulfilled]: (state, action) => {
      // state.currentEvent = action.payload;
    },
    [fetchWebinarStats.fulfilled]: (state, { payload }) => {
      if (payload && payload.stats) {
        state.stats = {
          actual: payload.stats.actual || 0,
          moderation: payload.stats.moderation || 0,
          draft: payload.stats.draft || 0,
          denied: payload.stats.denied || 0,
        };
      }
    },
    [fetchWebinars.fulfilled]: (state, { payload }) => {
      if (payload && payload.items) {
        state.items = [
          ...payload.items.map((w) => {
            const timeStart = w.time_start.split(" ");
            const timeEnd = w.time_end.split(" ");

            return {
              id: w.id,
              title: w.name,
              startDay: new Date(timeStart[0]).toString(),
              startTime: new Date(timeStart).toString(),
              endDay: new Date(timeEnd[0]).toString(),
              endTime: new Date(timeEnd).toString(),
              company: w.company,
              position: w.position,
              description: w.description,
            };
          }),
        ];
      }
    },
  },
});

export const {
  resetWebinars,
  setWebinarType,
  setCurrentWebinar,
  resetCurrentWebinar,
  setAllWebinars,
  addNewWebinar,
  updateWebinar,
  deleteWebinar,
} = webinarSlice.actions;
export default webinarSlice.reducer;
