import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import { uploadDocument } from "../../../api";
import { errorHandler } from "../../../utils/errorHandler";
import { Dropfield, UploadPopup } from "../../shared";

const Popup = ({ onClose, onLoadClick, isFileSending = false }) => {
  const [loadedFile, setLoadedFile] = useState({
    image: "",
    file: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(isFileSending);
  }, [isFileSending]);

  const onDocumentChange = async (e) => {
    try {
      setIsLoading(true);

      const file = e.target.files[0];
      let hasError = false;

      if (!file) {
        setIsLoading(false);
        return;
      }

      const { data } = await uploadDocument({ file });

      if (data.success) {
        setLoadedFile({ image: data.data.thumb, file });
      } else {
        hasError = true;
      }

      if (hasError) {
        immediateToast("error", {
          message: "Формат файла некорректный, попробуйте загрузить другой файл.",
          position: "topCenter",
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="stand-documents__popup">
      <UploadPopup
        title="Загрузить pdf файл"
        onCloseClick={onClose}
        onLoadClick={() => onLoadClick({ file: loadedFile.file })}
        dropField={
          <Dropfield
            text="Загрузить pdf"
            notation={["Используйте только .pdf"]}
            value={loadedFile.image}
            onChange={onDocumentChange}
            isLoading={isLoading}
            allowedFileTypes="application/pdf"
            maxFileSize={20}
          />
        }
      />
    </div>
  );
};

export default Popup;
