import classNames from "classnames";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setCurrentEvent } from "../../redux/eventReducer";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

const EventItem = ({ eventInfo, onEventClick, className }) => {
  const {
    title,
    text,
    availableSeats,
    poster,
    isPayed,
    id,
    standId,
    visitTypeId,
    startDate,
    isUserRegistered,
    isStandCreated,
    endDate,
    status,
    standStatus,
    availableStandsCount,
    isEventCreated,
    type,
    comment,
  } = eventInfo;

  let eventType;
  if (type === "default") {
    eventType = "Выставка";
  }
  if (type === "gallery") {
    eventType = "Презентация";
  }

  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.profile?.role);
  const splitedText = text ? text.split("\n") : [];

  const startDateDayAndMonth = moment(startDate).format("DD.MM");
  const endDateDayAndMonth = moment(endDate).format("DD.MM");

  const onClick = () => {
    if (onEventClick) {
      onEventClick({
        startDate,
        isUserRegistered,
        id,
        visitTypeId,
        isStandCreated,
        availableSeats,
        availableStandsCount,
        isEventCreated,
        type,
      });
    }

    dispatch(
      setCurrentEvent({
        ...eventInfo,
        startDate: eventInfo.startDate.toISOString(),
        endDate: eventInfo.endDate.toISOString(),
      })
    );
  };

  const history = useHistory();
  const isOnFavoritePage = history.location.pathname.includes("favorite");

  return (
    <div
      onClick={onClick}
      className={classNames("event-item", { actual: isPayed }, className)}
    >
      <div className="event-item__date">
        <div className="event-item__day">
          <div>{startDateDayAndMonth}</div>
          <div className="event-item__dash">-</div>
          <div>{endDateDayAndMonth}</div>
        </div>
        <div className="event-item__week">
          {moment(startDate).format("dd") + " / " + moment(endDate).format("dd")}
        </div>
      </div>
      <div className="event-item__main">
        <div className="event-item__header">
          <h3 className="event-item__title">{title}</h3>
          <div className="event-item__info">
            <div className="event-item__type">{eventType}</div>
            {isOnFavoritePage && role === "участник" && (
              <div className="event-item__type">{capitalizeFirstLetter(standStatus)}</div>
            )}
            {isOnFavoritePage && role === "участник" && comment && (
              <div className="event-item__places danger">{comment}</div>
            )}
            {isOnFavoritePage && role === "организатор" && (
              <div className="event-item__type">{capitalizeFirstLetter(status)}</div>
            )}
            {role === "участник" && type !== "gallery" && (
              <div className="event-item__places safe">
                {availableStandsCount} свободных стендов
              </div>
            )}
            <div className="event-item__places safe">ID:{id}</div>
            {standId && <div className="event-item__places safe">Стенд ID:{standId}</div>}
          </div>
        </div>

        <div className="event-item__text">
          {splitedText.map((t, i) => (
            <div key={i}>{t}</div>
          ))}
        </div>
      </div>

      <div className="event-item__poster">{!!poster && <img src={poster} alt="" />}</div>
    </div>
  );
};

export default EventItem;
