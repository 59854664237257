import React from "react";
import { Link } from "react-scroll";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearString } from "../../../../utils";

const DemoMain = ({
  title,
  subtitle,
  urls,
  colorTitle,
  bgImage,
  buttonColor,
  colorBackground,
  text,
  products,
  advantages,
  contactInfo,
}) => {
  const event = useSelector((state) => state.events.currentEvent);
  const stand = useSelector((state) => state.events.currentStand);
  let eventId = null;
  if (event && event.id) {
    eventId = event.id;
  } else if (stand && stand.id) {
    eventId = stand.event_id;
  }

  const history = useHistory();
  const buttonStyle = {
    background: colorTitle,
    color: colorTitle === "#ffffff" ? buttonColor : "#ffffff",
  };

  const onSubmit = () => {
    if (urls?.visitLink) {
      window.location.replace(urls.visitLink);
    } else {
      history.push(`/events/registration?id=${eventId}`);
    }
  };

  return (
    <div className="demo-main">
      <div className="demo-bg">{bgImage && <img src={bgImage} alt="Stand Poster" />}</div>
      <div className="demo__container">
        <div className="demo-main__content">
          <div className="demo-main__nav demo-main__nav--part">
            <div className="demo-main__nav-main">
              {text && (
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  to={"О компании"}
                  className="demo-main__nav__item"
                  style={{ color: colorTitle }}
                >
                  О компании
                </Link>
              )}
              {advantages && advantages.length >= 1 && (
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  to={"Наши преимущества"}
                  className="demo-main__nav__item"
                  style={{ color: colorTitle }}
                >
                  Наши преимущества
                </Link>
              )}
              {products && products.length >= 1 && (
                <Link
                  spy={true}
                  smooth={true}
                  duration={500}
                  to={"Продукция"}
                  className="demo-main__nav__item"
                  style={{ color: colorTitle }}
                >
                  Продукция
                </Link>
              )}
            </div>
            <div className="demo-main__nav-contact">
              <div
                style={{ color: colorTitle }}
                className="demo-main__nav__item contacts"
              >
                {clearString(contactInfo?.mobile)}
              </div>
            </div>
          </div>
          <div className="demo-main__info">
            <h3 style={{ color: colorTitle }} className="demo-main__title">
              {clearString(title)}
            </h3>
            <h5 style={{ color: colorTitle }} className="demo-main__subtitle">
              {clearString(subtitle)}
            </h5>
            <button
              onClick={onSubmit}
              type="submit"
              className="btn demo-event-content__btn"
              style={buttonStyle}
            >
              Посетить стенд
            </button>
          </div>
        </div>
      </div>
      <div className="demo-main__overlay" style={{ background: colorBackground }}></div>
    </div>
  );
};

export default DemoMain;
