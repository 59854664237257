import { getToken, instance } from ".";

export const getCountries = () => {
  const token = getToken();

  return instance.get("/api/v1/countries", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
