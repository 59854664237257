import { useState } from "react";

export const useConfirmPopup = () => {
  const [popupInfo, setPopupInfo] = useState({
    title: "",
    text: "",
    link: "",
    onCancelClick: () => {},
    onConfirmClick: () => {},
    isOpened: false,
    additional: null,
    customConfirmText: "",
    isLoading: false,
  });

  const closePopup = () => {
    setPopupInfo((i) => ({ ...i, isOpened: false }));
  };

  return {
    popupInfo,
    setPopupInfo,
    closePopup,
  };
};
