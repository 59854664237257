import { Redirect, Route } from "react-router-dom";

export const ScopeRoute = ({ component: Component, isActive, redirect, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (isActive ? <Component {...rest} /> : <Redirect to={redirect} />)}
    />
  );
};
