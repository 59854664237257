import React, { useEffect, useState } from "react";
import { bannerSettings, ArrowLeft, ArrowRight } from "../../../assets";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPreparedContacts } from "../../../api/contacts";

const Contacts = () => {
  const { type, id } = useParams();
  const [allContacts, setAllContacts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [limit] = useState(5);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [leftActive, setLeftActive] = useState(true);
  const [rightActive, setRightActive] = useState(true);

  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const contacts = await getPreparedContacts({ type, id, selfId: profile?.id });
        if (contacts) setAllContacts(contacts);
      } catch (error) {}
    };

    fetchContacts();
  }, [id, profile?.id, type]);

  useEffect(() => {
    setCount(Math.ceil(allContacts.length / limit));

    const items = [];
    const offset = (page - 1) * limit;
    allContacts.forEach((item, k) => {
      if (k >= offset && k < offset + limit) {
        items.push(item);
      }
    });
    setContacts(items);
  }, [allContacts, page, limit]);

  useEffect(() => {
    if (page === 1) {
      setLeftActive(false);
      setRightActive(true);
    } else if (page < count) {
      setRightActive(true);
      setLeftActive(true);
    } else if (page === count) {
      setRightActive(false);
      setLeftActive(true);
    }
  }, [page, count, limit]);

  const onMove = (side) => {
    let p = page;
    if (side === "left") {
      p = page - 1;
    } else {
      p = page + 1;
    }
    if (p >= 1 && p <= count) {
      setPage(p);
    }
  };

  return (
    <div className="stand-analytics__contacts analytics-contacts">
      <div className="analytics-contacts__header">
        <h3 className="analytics-contacts__title">
          Контакты <span>{allContacts.length}</span>
        </h3>
        <button className="analytics-contacts__more-btn">
          <img src={bannerSettings} alt="" />
        </button>
      </div>
      <div className="analytics-contacts__main">
        {contacts.map((cont) => (
          <div key={cont.id} className="analytics-contacts__contact">
            <img src={cont.avatar} alt=" " className="analytics-contacts__avatar" />
            <div className="analytics-contacts__fullname">{cont.name}</div>
            <div className="analytics-contacts__mail">{cont.email}</div>
            <div className="analytics-contacts__phone">{cont.phone}</div>
          </div>
        ))}
      </div>
      {count > 1 && (
        <div className="analytics-contacts__contacts-nav contacts-nav">
          <button
            onClick={() => {
              leftActive && onMove("left");
            }}
            className={
              "contacts-nav__left" + (!leftActive ? " contacts-nav__disactive" : "")
            }
          >
            <img width="20" src={ArrowLeft} alt="" />
          </button>
          <button
            onClick={() => {
              rightActive && onMove("right");
            }}
            className={
              "contacts-nav__right" + (!rightActive ? " contacts-nav__disactive" : "")
            }
          >
            <img width="20" src={ArrowRight} alt="" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Contacts;
