import React from "react";
import classNames from "classnames";

const Badge = ({ color, onClick, isActive }) => {
  return (
    <i
      className={classNames("web-badge", {
        "web-badge--active": isActive,
      })}
      style={{ background: color }}
      onClick={onClick}
    ></i>
  );
};

export default Badge;
