import moment from "moment";
import React from "react";

export const Message = ({ userImage, text, isSelf, time, userName }) => {
  const formatedDate = moment(time);
  let date = formatedDate.format("DD.MM HH:mm:ss");
  if(formatedDate.year() !== moment().year()){
    date = formatedDate.format("DD.MM.YY HH:mm:ss");
  }

  return (
    <div className={`side-chat__message ${isSelf ? " side-chat__message--self" : ""}`}>
      <img className="side-chat__userimg" src={userImage} alt="" />
      <div className="side-chat__text">
        <div className="side-chat__username">{isSelf ? "Я" : userName}</div>
        <div className="side-chat__message-text">{text}</div>
        <span className="side-chat__time">
          {date}
        </span>
      </div>
    </div>
  );
};
