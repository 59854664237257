import React from "react";
import { FirstStep, FourthStep, SecondStep, ThirdStep } from ".";

const PageContent = ({ currentStep }) => {
  return (
    <div className="create-stand__content create-stand-content">
      <div className="create-stand-content__header">
        <h2 className="create-stand-content__title">Создание стенда</h2>
        <div className="create-stand-content__buttons"></div>
      </div>
      {currentStep === 1 && <FirstStep />}
      {currentStep === 2 && <SecondStep />}
      {currentStep === 3 && <ThirdStep />}
      {currentStep === 4 && <FourthStep />}
    </div>
  );
};

export default PageContent;
