import React from "react";
import { Loader } from ".";
import { avatarPlus } from "../../assets";
import { errorMessage } from "../../utils/errorMessage";
import { getImageSize } from "../../utils/getImageSize";
import { isFileSizeAllowed } from "../../utils/isFileSizeAllowed";
import { generateTextAboutAvailableResolution } from "../../utils/generateTextMessage";

const Dropfield = ({
  text,
  notation,
  value,
  onChange,
  isLoading = false,
  allowedFileTypes = "*",
  maxFileSize,
  minFileResolution = {
    width: 0,
    height: 0,
  },
  maxFileResolution = {
    width: 0,
    height: 0,
  },
}) => {
  return (
    <div className="dropfield">
      <div className="dropfield__main">
        {value && !isLoading && (
          <img
            src={value}
            alt=""
            className="dropfield__image big dropfield__image--shared"
          />
        )}
        {isLoading ? (
          <Loader className="dropfield__loader" />
        ) : (
          <>
            <input
              accept={allowedFileTypes}
              className="dropfield__input"
              type="file"
              onChange={async (e) => {
                const newFile = e.target.files[0];

                if (!newFile) return;

                if (maxFileSize) {
                  if (
                    !isFileSizeAllowed({
                      file: newFile,
                      size: maxFileSize,
                    })
                  ) {
                    return errorMessage(`Максимальный размер файла ${maxFileSize}МБ`);
                  }
                }

                if (allowedFileTypes?.includes("image")) {
                  const { width, height } = await getImageSize(newFile);

                  if (minFileResolution.width && minFileResolution.height) {
                    if (
                      width < minFileResolution.width ||
                      height < minFileResolution.height
                    ) {
                      return errorMessage(
                        `Минимальный размер изображения ${minFileResolution.width}px x ${minFileResolution.height}px`
                      );
                    }
                  }

                  if (maxFileResolution.width && maxFileResolution.height) {
                    if (
                      width > maxFileResolution.width ||
                      height > maxFileResolution.height
                    ) {
                      return errorMessage(
                        `Максимальный размер изображения ${maxFileResolution.width}px x ${maxFileResolution.height}px`
                      );
                    }
                  }
                }

                if (onChange) {
                  onChange(e);
                }
              }}
            />
            {!value && (
              <div className="dropfield__content">
                <div className="dropfield__btn">
                  <div className="dropfield__plus">
                    <img src={avatarPlus} alt="Add icon" />
                  </div>
                  <div className="dropfield__text">
                    <span>
                      {text}
                      <br />
                    </span>
                    Или перетащите файл сюда
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {notation.map((item, ind) => (
        <div key={ind} className="dropfield__notation">
          {item}
        </div>
      ))}
      {maxFileSize && (
        <div className="dropfield__notation">Максимальный размер: {maxFileSize} Мб</div>
      )}
      {maxFileResolution.width && maxFileResolution.height ? (
        <div className="dropfield__notation">
          Разрешение:{" "}
          {generateTextAboutAvailableResolution(minFileResolution, maxFileResolution)}
        </div>
      ) : null}
    </div>
  );
};

export default Dropfield;
