import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import { uploadDocument, uploadImage, uploadVideo } from "../../../api";
import { avatarPlus } from "../../../assets";
import { errorHandler } from "../../../utils/errorHandler";
import { errorMessage } from "../../../utils/errorMessage";
import {
  generateTextAboutAvailableResolution,
  generateTextErrorMaxResolution,
  generateTextErrorMaxSize,
  generateTextErrorMinResolution,
} from "../../../utils/generateTextMessage";
import { getImageSize } from "../../../utils/getImageSize";
import { isFileSizeAllowed } from "../../../utils/isFileSizeAllowed";
import { Loader } from "../../shared";

const FILE_TYPES = {
  "image/jpeg": "image",
  "image/jpg": "image",
  "image/png": "image",
  "image/svg": "image",
  "application/pdf": "presentation",
  "video/mp4": "video",
  "video/mov": "video",
  "video/avi": "video",
  "video/quicktime": "video",
};

const DropField = ({
  loadedThumb,
  imageFromServer,
  setIsLoading,
  setCropInfo,
  banner = {},
  setFileType,
  setLoadedFileInfo,
  isLoading,
  allowedFilesType,
  minImageResolution = { width: 1136, height: 640 },
  maxImageResolution = { width: 1920, height: 1080 },
  customInputTitle,
  withInsertFullCheckbox,
  withFileAnotation,
  aspect,
}) => {
  const [loadedImage, setLoadedImage] = useState(imageFromServer);
  const [isImageFromServer, setIsImageFromServer] = useState(true);
  const MediaFileSizeAllowedMb = {
    image: 25,
    presentation: 25,
    video: 300,
  };

  const TextAboutAllowedResolution = `Разрешение: ${generateTextAboutAvailableResolution(
    minImageResolution,
    maxImageResolution
  )}`;

  // disabling crop when image loaded from server
  useEffect(() => {
    if (loadedThumb) {
      setLoadedImage(loadedThumb);
      setIsImageFromServer(false);
    } else if (imageFromServer) {
      setLoadedImage(imageFromServer);
      setIsImageFromServer(true);
    } else {
      setLoadedImage("");
    }
  }, [imageFromServer, loadedThumb]);

  // crop dependencies
  const bannerWidth =
    banner.styles && banner.styles.width && parseInt(banner.styles.width);
  const bannerHeight =
    banner.styles && banner.styles.height && parseInt(banner.styles.height);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [isWithoutCrop, setIsWithoutCrop] = useState(false);

  const initialCropState = useMemo(
    () => ({
      unit: "%",
      width: 40,
      height: !bannerWidth && !bannerHeight && !aspect ? 40 : 0,
      aspect: !bannerWidth && !bannerHeight ? aspect : bannerWidth / bannerHeight,
      x: 0,
      y: 0,
    }),
    [aspect, bannerHeight, bannerWidth]
  );

  const [crop, setCrop] = useState(initialCropState);

  const [completedCrop, setCompletedCrop] = useState(null);

  useEffect(() => {
    if (isWithoutCrop && imgRef.current?.width && imgRef.current?.height) {
      const fullSizeCropSettings = {
        // unit: "%",
        // width: 100,
        // aspect:
        //   imgRef.current?.naturalWidth && imgRef.current?.naturalHeight
        //     ? imgRef.current?.naturalWidth / imgRef.current?.naturalHeight
        //     : 1,
        unit: "px",
        width: imgRef.current?.width,
        height: imgRef.current?.height,
        x: 0,
        y: 0,
      };

      setCrop(fullSizeCropSettings);
      setCompletedCrop(fullSizeCropSettings);
    } else {
      setCrop(initialCropState);
    }
  }, [initialCropState, isWithoutCrop, imgRef.current?.width, imgRef.current?.height]);

  // crop inner logic
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;
    let imageExt = image?.src?.split(".").pop();

    if (imageExt === "jpg") {
      imageExt = "jpeg";
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    if (isWithoutCrop) {
      ctx.drawImage(image, 0, 0);
    } else {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
    }

    setCropInfo({ crop: completedCrop, canvas: previewCanvasRef.current, imageExt });
    // eslint-disable-next-line
  }, [completedCrop, isWithoutCrop]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onFileChange = async (e) => {
    const file = e.target.files[0];

    let bannerType = banner.type;

    if (!bannerType) {
      if (file.type.includes("image")) {
        bannerType = "image";
      }

      if (file.type.includes("video")) {
        bannerType = "video";
      }

      if (file.type === "application/pdf") {
        bannerType = "presentation";
      }
    }

    if (bannerType !== FILE_TYPES[file.type]) {
      setIsImageFromServer(false);
      setIsLoading(false);
      setLoadedImage("");
      return errorMessage(`Неподходящий тип файла`);
    }

    try {
      setIsLoading(true);

      if (!file) {
        setIsLoading(false);
        return;
      }

      // check file size
      if (!isFileSizeAllowed({ file, size: MediaFileSizeAllowedMb[bannerType] })) {
        setIsImageFromServer(false);
        setIsLoading(false);
        setLoadedImage("");
        return errorMessage(generateTextErrorMaxSize(MediaFileSizeAllowedMb[bannerType]));
      }

      // check resolution
      if (bannerType === "image") {
        const { width, height } = await getImageSize(file);
        if (!width && !height) {
          setIsLoading(false);
          return;
        }
        if (width < minImageResolution.width || height < minImageResolution.height) {
          setIsLoading(false);
          return errorMessage(
            generateTextErrorMinResolution(
              minImageResolution.width,
              minImageResolution.height
            )
          );
        }
        if (width > maxImageResolution.width || height > maxImageResolution.height) {
          setIsLoading(false);
          return errorMessage(
            generateTextErrorMaxResolution(
              maxImageResolution.width,
              maxImageResolution.height
            )
          );
        }
      }

      let hasError = false;

      if (bannerType === "image") {
        const { data } = await uploadImage({ file });

        setLoadedFileInfo(data.data);
        if (data.success) {
          setLoadedImage(data.data.thumb);
        } else {
          hasError = true;
        }
      } else if (bannerType === "presentation") {
        const { data } = await uploadDocument({ file });

        setLoadedFileInfo(data.data);
        if (data.success) {
          setLoadedImage(data.data.thumb);
        } else {
          hasError = true;
        }
      } else if (bannerType === "video") {
        const { data } = await uploadVideo({ file });
        setLoadedFileInfo(data.data);
        if (data.success) {
          setLoadedImage(data.data.thumb);
        } else {
          hasError = true;
        }
      }
      if (setFileType) {
        setFileType(bannerType);
      }

      setIsImageFromServer(false);
      setIsLoading(false);

      if (hasError) {
        errorMessage("Формат файла некорректный, попробуйте загрузить другой файл.");
        setLoadedImage("");
      }
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="model-popup__file">
        {isLoading ? (
          <Loader className="model-popup__loader" />
        ) : (
          <>
            <input
              type="file"
              onChange={onFileChange}
              className="model-popup__file-input"
              accept={allowedFilesType}
            />
            {loadedImage && isImageFromServer && (
              <div className="model-popup__wrap-file-img">
                <img src={loadedImage} alt="" />
              </div>
            )}
            {!isImageFromServer && (
              <div className="model-popup__crop-wrapper">
                <ReactCrop
                  src={loadedImage}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  imageStyle={{ maxHeight: "100%", maxWidth: "100%", margin: "0 auto" }}
                  className="model-popup__crop"
                  crossorigin="anonymous"
                  style={{
                    maxWidth: imgRef.current?.clientWidth || "auto",
                  }}
                  disabled={isWithoutCrop}
                />
              </div>
            )}
            <div
              style={{ position: "absolute", width: "1px", height: "1px", opacity: 0 }}
            >
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0),
                }}
              />
            </div>
            {(isImageFromServer || !loadedImage) && (
              <div className="model-popup__file-main">
                <img src={avatarPlus} alt="" className="model-popup__file-add-img" />
                <div className="model-popup__file-text">
                  <h3>{customInputTitle || "Загрузить файл"}</h3>
                  <span>Или перетащите файл сюда</span>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      {withInsertFullCheckbox && (
        <label className="model-popup__checkbox">
          <input
            checked={isWithoutCrop}
            onChange={() => setIsWithoutCrop((prev) => !prev)}
            type="checkbox"
          />
          <span>Вставить целиком</span>
        </label>
      )}
      {withFileAnotation && (
        <div className="model-popup__posttitle">
          <h3>
            {banner.type === "presentation" || banner.type === "pdf"
              ? "Используйте только .pdf типы файлов"
              : banner.type === "video"
              ? "Используйте только .mp4, .mov, .avi типы файлов"
              : "Используйте только .jpg, .png типы файлов"}
          </h3>
          <h3>
            {"Максимальный размер: "}
            {banner.type === "presentation" || banner.type === "pdf"
              ? 25
              : banner.type === "video"
              ? 300
              : 25}
            {" Мб"}
          </h3>
          <h3>{banner.type === "image" && TextAboutAllowedResolution}</h3>
        </div>
      )}
    </>
  );
};

export default DropField;
