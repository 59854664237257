
import { immediateToast } from "izitoast-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { changeWebinarStatus } from "../../api/webinar";
import { useConfirmPopup } from "../../hooks/useConfirmPopup";
import { fetchWebinarModeration } from "../../redux/eventReducer";
import { errorHandler } from "../../utils/errorHandler";
import { ConfirmPopup } from "../shared";

const ModerationWebinar = () => {
  const { mId: webinarId } = useParams();
  const { popupInfo, setPopupInfo, closePopup } = useConfirmPopup();
  const [webinar, setWebinar] = useState(null);
  const dispatch = useDispatch();

  const moderationWebinar = useSelector(state => state.events.moderationWebinar);

  useEffect(() => {
    const item = Object.assign({}, moderationWebinar);
    item.started_at = moment(item.started_at);
    item.ended_at = moment(item.ended_at);
    setWebinar(item);
  }, [ moderationWebinar ]);

  useEffect(() => {
    dispatch(fetchWebinarModeration({ id: webinarId }));
  }, [webinarId, dispatch]);

  let comment = "";
  const onChangeTextarea = (e) => {  
    comment = e.target.value;
  }

  const onDecline = () => {
    setPopupInfo({ 
      title: "Причина отклонения",
      customConfirmText: "Подтвердить",
      isOpened: true,
      additional: (
        <div>
          <textarea id="cancelation-comment" onChange={onChangeTextarea} required placeholder="Напишите причину отклонения заявки" className="moderation__textarea" name="text"></textarea>
        </div>
      ), 
      onConfirmClick(){
        if(comment){
          (async() => {
            try{
              const { data } = await changeWebinarStatus({
                id: webinarId, comment, status: "decline"
              });
              if (data.message) {
                immediateToast('warning', {
                  message: data.message,
                  position: "topCenter",
                });
              }
            }catch(e){
              errorHandler(e);
            }finally{
              closePopup();
            }
          })();
        }else{
          immediateToast("error", {
            message: "Вы не указали причину",
            position: "topCenter",
          });
        }
      },
      onCancelClick(){
        closePopup();
      }, 
    });
  }

  
  const onApprove = async () => {    
    setPopupInfo({ 
      title: "Подтверждение",
      customConfirmText: "Подтвердить",
      isOpened: true,
      additional: (
        <div>
          <p>Вы действительно подтверждаете вебинар?</p>
        </div>
      ), 
      onConfirmClick(){
        (async() => {
          try{
            const { data } = await changeWebinarStatus({
              id: webinarId, comment, status: "approve"
            });
            if (data.message) {
              immediateToast('success', {
                message: data.message,
                position: "topCenter",
              });
            }
          }catch(e){
            errorHandler(e);
          }finally{
            closePopup();
          }
        })();
      },
      onCancelClick(){
        closePopup();
      }, 
    });
  }

  const onCloseClick = () => {
    closePopup();
  }

  return (
    <div>
      { popupInfo.isOpened && <ConfirmPopup popupInfo={popupInfo} onCloseClick={onCloseClick} /> }
      <div className="item-info__item item-info__item--documents">
        <div className="item-info__header item-info__header--participants">
          <div className="participants-cards__info participants-cards__info--documents">
            <h5 className="item-info__name item-info__name--participants">
              Основная информация
            </h5>
          </div>
          <div className="promotion-item__status">Вебинар #{ webinarId }</div>
        </div>
        { webinar && (
          <div className="item-info__main item-info__main--documents">
            <div className="participants-cards__part participants-cards__part--small">

              <div className="item-info__data">
                <div className="item-info__point point">Стенд #{ webinar.stand_id }</div>
                <div className="item-info__response response">{ webinar.stand_name }</div>
              </div>

              <div className="item-info__data">
                <div className="item-info__point point">Логотип стенда</div>
                <div className="item-info__response response">
                  <img width="100" src={ webinar.stand_logo } alt="" />
                </div>
              </div>

              <div className="item-info__data">
                <div className="item-info__point point">Название</div>
                <div className="item-info__response response">{ webinar.name }</div>
              </div>

              <div className="item-info__data">
                <div className="item-info__point point">Краткое описание</div>
                <div className="item-info__response response">{ webinar.short }</div>
              </div>

              <div className="item-info__data">
                <div className="item-info__point point">Дата проведения</div>
                <div className="item-info__response response">{ webinar.started_at.format("DD MMM YYYY HH:mm") } - { webinar.ended_at.format("DD MMM YYYY HH:mm") } </div>
              </div>
              
            </div>
          </div>
        ) }
      </div>
      
      <div className="moderation__actions">
        <div className="moderation__btn-group">
          <button onClick={onApprove} style={{marginRight: 15}} className="btn btn-lg btn-success moderation__btn">Принять</button>
          <button onClick={onDecline} className="btn btn-lg btn-danger moderation__btn">Отклонить</button>
        </div>
      </div>

    </div>
  );
};

export default ModerationWebinar;
