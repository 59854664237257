import React from "react";
import { AlertCircle } from "../../assets";
import cn from "classnames";

export const Input = ({
  helperText = "",
  label,
  error,
  isFilled,
  component = "input",
  ...props
}) => {
  return (
    <div className="input">
      <div className="input__alert">
        <label>{label}</label>
        {helperText && (
          <div className="create-stand-content__input-icon">
            <img src={AlertCircle} alt="#" />
            <div className="create-stand-content__input-promt registrationNumber">
              {helperText}
            </div>
          </div>
        )}
      </div>
      {component === "input" && (
        <input
          type="text"
          {...props}
          className={cn(
            "default-input",
            {
              "default-input--error": error,
            },
            { "default-input--filled": isFilled }
          )}
        />
      )}
      {component === "textarea" && (
        <textarea
          {...props}
          className={cn(
            "default-input",
            {
              "default-input--error": error,
            },
            { "default-input--filled": isFilled }
          )}
        />
      )}

      {error && <span className="input__text">{error}</span>}
    </div>
  );
};
