import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  getStandVoiceAvatar,
  postStandAudio,
  postStandVoiceAvatar,
} from "../../../api/stand";
import { modelMan, modelWoman } from "../../../assets";
import { errorHandler } from "../../../utils/errorHandler";
import { errorMessage } from "../../../utils/errorMessage";
import { isFileSizeAllowed } from "../../../utils/isFileSizeAllowed";
import { successMessage } from "../../../utils/successMessage";

const initialUploadedFileState = { src: "", name: "" };

const AudioPanel = ({ loadedAudio, disabled }) => {
  const { id, type } = useParams();

  const MaxFileSizeMb = 5;

  const [uploadedFile, setUploadedFile] = useState(initialUploadedFileState);
  useEffect(() => {
    if (loadedAudio?.src) {
      setUploadedFile({
        src: loadedAudio.src,
        name: loadedAudio.name,
      });
    } else {
      setUploadedFile(initialUploadedFileState);
    }
  }, [loadedAudio]);

  const onFileChange = async (e) => {
    try {
      const file = e.target.files[0];

      if (!isFileSizeAllowed({ file, size: MaxFileSizeMb })) {
        e.target.value = null;
        return errorMessage("Максимальный размер файла 5 МБ");
      }

      if (file) {
        await postStandAudio({ file, id, standType: type });

        const fileURL = URL.createObjectURL(file);
        setUploadedFile({
          src: fileURL,
          name: file.name,
        });

        successMessage("Аудиоприветствие успешно загружено");
      }
    } catch (error) {
      errorHandler(error);
    }
    e.target.value = null;
  };

  const [isVoiceLoading, setIsVoiceLoading] = useState(false);

  const [selectedVoice, setSelectedVoice] = useState("woman");

  useEffect(() => {
    const fetchVoiceAvatar = async () => {
      try {
        const { data } = await getStandVoiceAvatar({ standId: id, type });

        setSelectedVoice(data.data.type);
      } catch (error) {}
    };

    fetchVoiceAvatar();
  }, [id, type]);

  const onVoiceChange = async ({ gender }) => {
    try {
      if (selectedVoice === gender) {
        return;
      }

      setIsVoiceLoading(true);
      await postStandVoiceAvatar({ standId: id, gender, type });

      setSelectedVoice(gender);

      successMessage("Голос успешно изменен");
    } catch (error) {
      setIsVoiceLoading(false);
      errorHandler(error);
    }
    setIsVoiceLoading(false);
  };

  return (
    <div className="model__audio-panel">
      <div className="model__audio-panel-top">
        <h3 className="model__audio-title">Аудиоприветствие</h3>
        <div className="model__peoples">
          <img
            style={{ opacity: selectedVoice === "woman" ? 1 : 0.3 }}
            src={modelWoman}
            alt=""
            onClick={() => onVoiceChange({ gender: "woman" })}
          />
          <img
            style={{ opacity: selectedVoice === "man" ? 1 : 0.3 }}
            src={modelMan}
            alt=""
            onClick={() => onVoiceChange({ gender: "man" })}
          />
        </div>
        <div className="model__voice">
          <h3>Выбор хостес</h3>
          <div className="model__voice-choices">
            <label className="model__voice-choice">
              <input
                onChange={(e) => onVoiceChange({ gender: e.target.value })}
                checked={selectedVoice === "woman"}
                value="woman"
                name="audio-voice-choice"
                type="radio"
                disabled={isVoiceLoading}
              />
              Женщина
            </label>
            <label className="model__voice-choice">
              <input
                onChange={(e) => onVoiceChange({ gender: e.target.value })}
                checked={selectedVoice === "man"}
                value="man"
                name="audio-voice-choice"
                type="radio"
                disabled={isVoiceLoading}
              />
              Мужчина
            </label>
          </div>
        </div>
        {/* <div className="model__audio-notice">Используйте только .mp3 тип файла</div> */}
        <div className="model__audio-notice">
          <div>Используйте только .mp3, .m4a, .mp4 типы файлов</div>
          <div>Максимальный размер файла {MaxFileSizeMb} МБ</div>
        </div>
        <label className="model__audio-button">
          + Добавить
          <input
            onChange={onFileChange}
            type="file"
            className="model__audio-input"
            accept=".mp3, .m4a, .mp4"
            disabled={disabled}
          />
        </label>
      </div>
      {uploadedFile.src && (
        <div className="model__audio-uploaded">
          {uploadedFile.name && <span>{uploadedFile.name}</span>}
          <audio controls src={uploadedFile.src}>
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </div>
      )}
    </div>
  );
};

export default AudioPanel;
