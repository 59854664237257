import classNames from "classnames";
import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getLanding, storeLanding, uploadFile } from "../../api/landing";
import { getStandInfo } from "../../api/stand";
import { addSection, avatarPlus, CreateWebDelete, resetProfile } from "../../assets";
import { Header, Sidebar, SidePopupChat } from "../../components/shared";
import { NavStand } from "../../components/stand";
import {
  CreatedWebInfo,
  CreateWebSuccessPopup,
  LeaveCreateWebHandler,
  PromotionHeader,
  PromotionWebColors,
  PromotionWebHeader,
} from "../../components/stand/promotion";
import {
  setDemoBackgroundColor,
  setDemoTextColor,
  setDemoTitleColor,
  setNewDemoSite,
} from "../../redux/appReducer";
import { errorMessage } from "../../utils/errorMessage";
import { getImageSize } from "../../utils/getImageSize";
import {
  generateTextErrorMinResolution,
  generateTextErrorMaxResolution,
  generateTextErrorMaxSize,
  generateTextAboutAvailableResolution,
} from "../../utils/generateTextMessage";

const allowedImageFileType = "image/png, image/jpeg, image/jpg";

const CreateWeb = () => {
  const profile = useSelector((state) => state.user.profile);
  const role = useSelector((state) => state.user.profile.role);

  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const demoInfo = useSelector((state) => state.app.demo);

  const [isSiteCreated, setIsSiteCreated] = useState(demoInfo.isCreated);
  const [landingUrl, setLandingUrl] = useState("");

  const MaxPdfSizeMb = 25;
  const MaxImageSizeMb = 25;
  const MinImageResolution = { width: 1136, height: 640 };
  const MaxImageResolution = { width: 1920, height: 1080 };
  const TextAboutAllowedResolution = `Разрешение: ${generateTextAboutAvailableResolution(
    MinImageResolution,
    MaxImageResolution
  )}`;

  const [autocompletedFields, setAutocompletedFields] = useState({
    title: "",
    about: "",
    logo: "",
    email: "",
  });

  useEffect(() => {
    const fetchSiteInfo = async () => {
      try {
        let data;

        try {
          const { data: landingData } = await getLanding({ standId: id });

          data = landingData;
        } catch (error) {}

        const output = data?.data;
        let formData;

        if (output) {
          formData = output.draft;

          if (!formData) {
            formData = output.main;
          }

          setLandingUrl(output.url || "");
        }

        const { data: standInfoData } = await getStandInfo({ standId: id });
        const standInfo = standInfoData?.data;

        setAutocompletedFields({
          title: standInfo.company || "",
          about: standInfo.info?.short || "",
          logo: standInfo.logo || "",
          email: profile.email || "",
        });

        if (!formData) {
          if (standInfo) {
            setContactInfo((prev) => ({
              ...prev,
              email: profile.email || "",
            }));

            setTitle(standInfo.company || "");
            setAbout(standInfo.info?.short || "");
            setLogo(standInfo.logo || "");
            setFooterText(standInfo.info?.organization);
          }

          return;
        }

        setIsSiteCreated(true);

        dispatch(setDemoTextColor(formData.color.text));
        dispatch(setDemoTitleColor(formData.color.title));
        dispatch(setDemoBackgroundColor(formData.color.background));
        setTitle(formData.header.title || "");
        setSubtitle(formData.header.subtitle || "");
        formData.header.contactInfo && setContactInfo(formData.header.contactInfo);

        formData.header.background && setHeaderBg(formData.header.background);

        setLogo(formData.header.logo);

        setAbout(formData.main.about);

        setAdvantages(formData.main.advantages);

        setProducts(formData.main.products);
        setVisibleMobileMain(formData.header.visibleMobile);
        setMobileFooter(formData.footer.phone);
        setVisibleMobileFooter(formData.footer.visibleMobile);
        setPresentationFile(formData.main.pdf);

        setFooterText(standInfo.info?.organization);
      } catch (error) {
        console.log(error);
      }
    };

    // for not clearing data, when user comes back from demo site preview
    if (!demoInfo.isDemo) {
      fetchSiteInfo();
    }
  }, [demoInfo.isDemo, dispatch, id, profile.email]);

  const [visibleColors, setVisibleColors] = useState(true);
  const [visibleHeader, setVisibleHeader] = useState(true);
  const [visibleMain, setVisibleMain] = useState(true);
  // const [visibleFooter, setVisibleFooter] = useState(true);
  const [visibleAdvantages, setVisibleAdvantages] = useState(true);
  const [visibleProduct, setVisibleProduct] = useState(true);
  const [visiblePdf, setVisiblePdf] = useState(true);
  const [visibleMobileMain, setVisibleMobileMain] = useState(
    demoInfo.header.visibleMobile || false
  );
  const [visibleMobileFooter, setVisibleMobileFooter] = useState(
    demoInfo.footer.visibleMobile || false
  );

  const [presentationFile, setPresentationFile] = useState(demoInfo.main.pdf);
  const [headerBg, setHeaderBg] = useState(demoInfo.header.background);
  const [logo, setLogo] = useState(demoInfo.header.logo);

  const [title, setTitle] = useState(demoInfo.header.title);
  const [subtitle, setSubtitle] = useState(demoInfo.header.subtitle);
  const [contactInfo, setContactInfo] = useState(demoInfo.header.contactInfo);

  const [about, setAbout] = useState(demoInfo.main.about);

  const [mobileFooter, setMobileFooter] = useState(demoInfo.footer.phone);

  const [footerText, setFooterText] = useState(demoInfo.footer.text);
  const [successPopup, setSuccesPopup] = useState(false);

  // Наши преимущества
  const [advantages, setAdvantages] = useState(demoInfo.main.advantages || ["", "", ""]);

  const onAdvantageChange = (index, newValue) => {
    setAdvantages((prev) => prev.map((a, i) => (i === index ? newValue : a)));
  };

  const [products, setProducts] = useState(demoInfo.main.products);

  const onProductNameChange = ({ index, newValue }) => {
    setProducts((prev) => [
      ...prev.map((prod, ind) =>
        ind === index ? { ...prod, name: newValue } : { ...prod }
      ),
    ]);
  };

  const onProductDescriptionChange = ({ index, newValue }) => {
    setProducts((prev) => [
      ...prev.map((prod, ind) =>
        ind === index ? { ...prod, description: newValue } : { ...prod }
      ),
    ]);
  };

  const onProductImageChange = async ({ index, event }) => {
    const imageFile = event.target.files[0];

    if (!imageFile) {
      return;
    }

    // check size
    if (imageFile.size > MaxImageSizeMb * 1024 * 1024) {
      return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
    }

    // check resolution
    const { width, height } = await getImageSize(imageFile);
    if (!width && !height) {
      return;
    }
    if (width < MinImageResolution.width || height < MinImageResolution.height) {
      return errorMessage(
        generateTextErrorMinResolution(
          MinImageResolution.width,
          MinImageResolution.height
        )
      );
    }
    if (width > MaxImageResolution.width || height > MaxImageResolution.height) {
      return errorMessage(
        generateTextErrorMaxResolution(
          MaxImageResolution.width,
          MaxImageResolution.height
        )
      );
    }

    try {
      const { data } = await uploadFile({
        standId: id,
        file: imageFile,
      });

      const newImage = data.data.url;

      return setProducts((prev) => [
        ...prev.map((prod, ind) =>
          ind === index ? { ...prod, img: newImage } : { ...prod }
        ),
      ]);
    } catch (error) {}
  };

  const onClearProductImg = ({ index }) => {
    setProducts((prev) => [
      ...prev.map((prod, ind) => (ind === index ? { ...prod, img: "" } : { ...prod })),
    ]);
  };

  const onPresentationFileChange = async (e) => {
    try {
      const pdf = e.target.files[0];

      if (!pdf) {
        return;
      }

      // check size
      if (pdf.size > MaxImageSizeMb * 1024 * 1024) {
        return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
      }

      const { data } = await uploadFile({
        standId: id,
        file: pdf,
      });

      setPresentationFile(data.data.url);
    } catch (error) {}
  };

  const onHeaderBgChange = async (e) => {
    const bgFile = e.target.files[0];

    if (!bgFile) {
      return;
    }

    // check size
    if (bgFile.size > MaxImageSizeMb * 1024 * 1024) {
      return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
    }

    // check resolution
    const { width, height } = await getImageSize(bgFile);
    if (!width && !height) {
      return;
    }
    if (width < MinImageResolution.width || height < MinImageResolution.height) {
      return errorMessage(
        generateTextErrorMinResolution(
          MinImageResolution.width,
          MinImageResolution.height
        )
      );
    }
    if (width > MaxImageResolution.width || height > MaxImageResolution.height) {
      return errorMessage(
        generateTextErrorMaxResolution(
          MaxImageResolution.width,
          MaxImageResolution.height
        )
      );
    }

    try {
      const { data } = await uploadFile({
        standId: id,
        file: bgFile,
      });

      data.data.url && setHeaderBg(data.data.url);
    } catch (e) {}
  };

  const onLogoChange = async (e) => {
    try {
      const logo = e.target.files[0];

      if (!logo) {
        return;
      }

      // check size
      if (logo.size > MaxImageSizeMb * 1024 * 1024) {
        return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
      }

      // check resolution
      const { width, height } = await getImageSize(logo);
      if (!width && !height) {
        return;
      }
      if (width < MinImageResolution.width || height < MinImageResolution.height) {
        return errorMessage(
          generateTextErrorMinResolution(
            MinImageResolution.width,
            MinImageResolution.height
          )
        );
      }
      if (width > MaxImageResolution.width || height > MaxImageResolution.height) {
        return errorMessage(
          generateTextErrorMaxResolution(
            MaxImageResolution.width,
            MaxImageResolution.height
          )
        );
      }

      const { data } = await uploadFile({
        standId: id,
        file: logo,
      });

      setLogo(data.data.url);
    } catch (error) {
      // errorHandler(error);
    }
  };

  const prepareDemoData = () => {
    const obj = {
      color: {
        text: demoInfo.color.text,
        title: demoInfo.color.title,
        background: demoInfo.color.background,
      },
      header: {
        title: title,
        subtitle: subtitle,
        background: headerBg,
        contactInfo,
        logo,
        visibleMobile: visibleMobileMain,
      },
      main: {
        about: about,
        advantages: visibleAdvantages ? advantages.filter((ad) => ad.length > 0) : [],
        products: visibleProduct ? products : [],
        pdf: presentationFile,
      },
      footer: {
        text: footerText,
        phone: mobileFooter,
        visibleMobile: visibleMobileFooter,
      },
      isDemo: true,
      isCreated: isSiteCreated,
    };

    dispatch(setNewDemoSite(obj));
  };

  const onNewDemoSite = () => {
    prepareDemoData();

    onDemo();
  };

  const onSubmit = async (e, isDraft) => {
    e.preventDefault();
    try {
      const obj = {
        color: {
          text: demoInfo.color.text,
          title: demoInfo.color.title,
          background: demoInfo.color.background,
        },
        header: {
          title: title,
          subtitle: subtitle,
          background: headerBg,
          contactInfo,
          logo,
          visibleMobile: visibleMobileMain,
        },
        main: {
          about: about,
          advantages: visibleAdvantages ? advantages.filter((ad) => ad.length > 0) : [],
          products: visibleProduct ? products : [],
          pdf: presentationFile,
        },
        footer: {
          text: footerText,
          phone: mobileFooter,
          visibleMobile: visibleMobileFooter,
        },
      };

      const { data } = await storeLanding({
        standId: id,
        data: obj,
        title,
        isDraft,
      });
      if (data.data?.url) {
        setLandingUrl(data.data?.url);
      }

      dispatch(setNewDemoSite(obj));

      if (isDraft) {
        return immediateToast("success", {
          message: "Черновик успешно сохранен",
          position: "topCenter",
        });
      }

      setSuccesPopup(true);
    } catch (error) {}
  };

  const onDemo = () => {
    if (profile.role === "участник") {
      history.push(`/stand/${id}/settings/create-web/demo`);
    } else {
      history.push(`/event/${id}/settings/create-web/demo`);
    }
  };

  return (
    <div className="app">
      {successPopup && (
        <CreateWebSuccessPopup url={landingUrl} onClose={() => setSuccesPopup(false)} />
      )}
      <LeaveCreateWebHandler
        message={(location) => {
          return location.pathname.startsWith(`/stand/${id}/settings/create-web/demo`) ||
            location.pathname.startsWith(`/stand/${id}/site`)
            ? true
            : "Вы действительно хотите покинуть страницу? Все несохраненные изменения будут потеряны";
        }}
      />
      <Sidebar />
      <Header />
      <SidePopupChat />
      <div className="page__main">
        <NavStand />
        <div className="create-promotion">
          <PromotionHeader
            title="Создать веб сайт"
            subtitle={
              role === "участник"
                ? "Генерация landing page на основе информации о стенде"
                : "Генерация landing page на основе информации о мероприятии"
            }
            button="Предпросмотр сайта"
            onClickButton={onNewDemoSite}
            prepareDemoData={prepareDemoData}
          />
          {landingUrl && <CreatedWebInfo url={landingUrl} />}
          <form className="create-promotion__main create-promotion__main--web">
            <div className="create-promotion__item create-promotion__item--colors">
              <PromotionWebHeader
                title="Цветовая палитра"
                setVisible={() => setVisibleColors(!visibleColors)}
              />
              {visibleColors && <PromotionWebColors />}
            </div>
            <div className="create-promotion__item create-promotion__item--colors">
              <PromotionWebHeader
                title={"Шапка"}
                setVisible={() => setVisibleHeader(!visibleHeader)}
              />
              {visibleHeader && (
                <div className="web-item web-item--top">
                  <div className="web-item__form">
                    <div className="input">
                      <label className="input__label">Заголовок</label>
                      <div className="input__wrapper">
                        <input
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className={classNames("input__main")}
                          placeholder="Приветствие"
                          type="text"
                        />
                        <img
                          src={resetProfile}
                          alt=""
                          className="input__reset-icon"
                          onClick={() => setTitle(autocompletedFields.title)}
                        />
                      </div>
                    </div>
                    <div className="input">
                      <label className="input__label">Подзаголовок</label>
                      <input
                        value={subtitle}
                        onChange={(e) => setSubtitle(e.target.value)}
                        className={classNames("input__main")}
                        placeholder="Подзаголовок"
                        type="text"
                      />
                    </div>
                    {visibleMobileMain && (
                      <>
                        <div className="input">
                          <label className="input__label">Email</label>
                          <div className="input__wrapper">
                            <input
                              value={contactInfo.email}
                              onChange={(e) =>
                                setContactInfo((prev) => ({
                                  ...prev,
                                  email: e.target.value,
                                }))
                              }
                              className={classNames("input__main")}
                              placeholder="Email"
                              type="email"
                            />
                            <img
                              src={resetProfile}
                              alt=""
                              className="input__reset-icon"
                              onClick={() =>
                                setContactInfo((prev) => ({
                                  ...prev,
                                  email: autocompletedFields.email,
                                }))
                              }
                            />
                          </div>
                        </div>
                        <div className="input">
                          <label className="input__label">Телефон</label>
                          <input
                            value={contactInfo.mobile}
                            onChange={(e) =>
                              setContactInfo((prev) => ({
                                ...prev,
                                mobile: e.target.value,
                              }))
                            }
                            className={classNames("input__main")}
                            placeholder="Телефон"
                            type="tel"
                          />
                        </div>
                      </>
                    )}
                    {!visibleMobileMain && (
                      <div
                        onClick={() => setVisibleMobileMain(true)}
                        className="popup-user__add popup-user__add--web"
                      >
                        Добавить контактную информацию
                      </div>
                    )}
                  </div>
                  <div className="web-item__dropfield">
                    {headerBg && <p className="web-item__img-title">Фон</p>}
                    <div className="dropfield">
                      <div className="dropfield__main">
                        {headerBg && (
                          <>
                            <img className="dropfield__image big" src={headerBg} alt="" />
                            <div className="web-item__img-delete">
                              <img
                                onClick={() => setHeaderBg("")}
                                src={CreateWebDelete}
                                alt="delete"
                              />
                            </div>
                          </>
                        )}
                        <input
                          onChange={onHeaderBgChange}
                          className="dropfield__input"
                          type="file"
                          id="file"
                          accept={allowedImageFileType}
                        />
                        {!headerBg && (
                          <div className="dropfield__content">
                            <div className="dropfield__btn">
                              <div className="dropfield__plus">
                                <img src={avatarPlus} alt="Add icon" />
                              </div>
                              <div className="dropfield__text">
                                <span>
                                  Загрузить медиа для фона
                                  <br />
                                </span>
                                Или перетащите файл сюда
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="dropfield__notation">
                        <div>Используйте только .jpg, .png типы файлов</div>
                        <div>Максимальный размер: {MaxImageSizeMb} Мб</div>
                        <div>{TextAboutAllowedResolution}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="create-promotion__item create-promotion__item--colors">
              <PromotionWebHeader
                title={"Основная часть"}
                setVisible={() => setVisibleMain(!visibleMain)}
              />
              {visibleMain && (
                <div className="web-item web-item--main">
                  <div className="web-item__about">
                    <div className="web-item__about-form">
                      <div className="web-item__about-input">
                        <div className="input">
                          <label className="input__label">О компании</label>
                          <div className="input__wrapper">
                            <textarea
                              value={about}
                              onChange={(e) => setAbout(e.target.value)}
                              className={classNames("input__main")}
                              placeholder="Введите описание компании."
                              type="text"
                            />
                            <img
                              src={resetProfile}
                              alt=""
                              className="input__reset-icon input__reset-icon--on-top"
                              onClick={() => setAbout(autocompletedFields.about)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="web-item__dropfield">
                        {logo && <p className="web-item__img-title">Логотип</p>}
                        <div className="dropfield">
                          <div className="dropfield__main">
                            <img
                              src={resetProfile}
                              alt=""
                              className="input__reset-icon input__reset-icon--on-bottom"
                              style={{ zIndex: 100 }}
                              onClick={() => setLogo(autocompletedFields.logo)}
                            />
                            {logo && (
                              <img className="dropfield__image big" src={logo} alt="" />
                            )}
                            <input
                              onChange={onLogoChange}
                              className="dropfield__input"
                              type="file"
                              id="file"
                              accept={allowedImageFileType}
                            />
                            {logo && logo && (
                              <div className="web-item__img-delete">
                                <img
                                  onClick={() => setLogo("")}
                                  src={CreateWebDelete}
                                  alt="delete"
                                />
                              </div>
                            )}
                            {!logo && (
                              <div className="dropfield__content">
                                <div className="dropfield__btn">
                                  <div className="dropfield__plus">
                                    <img src={avatarPlus} alt="Add icon" />
                                  </div>
                                  <div className="dropfield__text">
                                    <span>
                                      Загрузить лого
                                      <br />
                                    </span>
                                    Или перетащите файл сюда
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="dropfield__notation">
                            <div>Используйте только .jpg, .png типы файлов</div>
                            <div>Максимальный размер: {MaxImageSizeMb} Мб</div>
                            <div>{TextAboutAllowedResolution}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames("web-item__product", {
                      clear: !visibleAdvantages,
                    })}
                  >
                    <h5 className="web-item__product-title">
                      Наши преимущества
                      <img
                        onClick={() => {
                          visibleAdvantages
                            ? setVisibleAdvantages(false)
                            : setVisibleAdvantages(true);
                        }}
                        className="web-item__product-delete"
                        src={visibleAdvantages ? CreateWebDelete : addSection}
                        alt="#"
                      />
                    </h5>
                    {visibleAdvantages && (
                      <>
                        {advantages.map((adv, i) => (
                          <div key={i} className="input">
                            <div className="web-item__product-input">
                              <input
                                value={adv}
                                onChange={(e) => onAdvantageChange(i, e.target.value)}
                                className={classNames("input__main")}
                                placeholder="Наши преимущества"
                                type="text"
                              />
                              {i !== 0 && advantages.length === i + 1 && (
                                <img
                                  onClick={() =>
                                    setAdvantages((prev) => prev.slice(0, i))
                                  }
                                  className="web-item__product-delete"
                                  src={CreateWebDelete}
                                  alt="delete"
                                />
                              )}
                            </div>
                          </div>
                        ))}
                        {advantages.length < 6 && (
                          <div
                            onClick={() => setAdvantages((prev) => [...prev, ""])}
                            className="popup-user__add popup-user__add--web popup-user__add--advantages"
                          >
                            Добавить
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className={classNames("web-item__product", {
                      clear: !visibleProduct,
                    })}
                  >
                    <h5 className="web-item__product-title">
                      Продукция{" "}
                      {visibleProduct ? (
                        <img
                          onClick={() => setVisibleProduct(false)}
                          className="web-item__product-delete"
                          src={CreateWebDelete}
                          alt="delete"
                        />
                      ) : (
                        <img
                          onClick={() => setVisibleProduct(true)}
                          className="web-item__product-delete"
                          src={addSection}
                          alt="add"
                        />
                      )}
                    </h5>
                    {visibleProduct &&
                      products.map((product, i) => (
                        <div key={i + 1} className="web-item__product-item">
                          <div className="web-item__dropfield-mini">
                            {product.img && (
                              <p className="web-item__img-title mini">
                                Фотография продукции
                              </p>
                            )}
                            <div className="dropfield-mini">
                              {product.img ? (
                                <>
                                  <img
                                    className="dropfield__image"
                                    src={product.img}
                                    alt="product"
                                  />
                                  <div className="web-item__img-delete product">
                                    <img
                                      onClick={() => onClearProductImg({ index: i })}
                                      src={CreateWebDelete}
                                      alt="delete"
                                    />
                                  </div>
                                </>
                              ) : (
                                <div className="dropfield-mini__content">
                                  <div className="dropfield-mini__plus">
                                    <img src={avatarPlus} alt="Add icon" />
                                  </div>
                                  <div className="dropfield-mini__text">
                                    <span>Загрузить фото</span>
                                  </div>
                                </div>
                              )}
                              <input
                                className="dropfield-mini__input"
                                type="file"
                                onChange={(e) =>
                                  onProductImageChange({ index: i, event: e })
                                }
                                accept={allowedImageFileType}
                              />
                            </div>
                            <div className="dropfield__notation">
                              <div>Используйте только .jpg, .png типы файлов</div>
                              <div>Максимальный размер: {MaxImageSizeMb} Мб</div>
                              <div>{TextAboutAllowedResolution}</div>
                            </div>
                          </div>

                          <div className="web-item__product-inputs">
                            <div className="input">
                              <label className="input__label">Товар {i + 1}</label>
                              <div className="web-item__product-input">
                                <input
                                  value={product.name}
                                  onChange={(e) =>
                                    onProductNameChange({
                                      index: i,
                                      newValue: e.target.value,
                                    })
                                  }
                                  className={classNames("input__main")}
                                  placeholder="Название товара или услуги"
                                  type="text"
                                />
                                {i !== 0 && products.length === i + 1 && (
                                  <img
                                    onClick={() =>
                                      setProducts((prev) => [...prev].slice(0, i))
                                    }
                                    className="web-item__product-delete"
                                    src={CreateWebDelete}
                                    alt="delete"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="input">
                              <label className="input__label">Описание</label>
                              <div className="web-item__product-input">
                                <input
                                  value={product.description}
                                  onChange={(e) =>
                                    onProductDescriptionChange({
                                      index: i,
                                      newValue: e.target.value,
                                    })
                                  }
                                  className={classNames("input__main")}
                                  placeholder="Описание товара"
                                  type="text"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {visibleProduct && products.length < 8 && (
                      <div
                        onClick={() =>
                          setProducts((prev) => [
                            ...prev,
                            { name: "", description: "", img: "" },
                          ])
                        }
                        className="popup-user__add popup-user__add--web popup-user__add--product"
                      >
                        Добавить продукцию
                      </div>
                    )}
                  </div>
                  <div
                    className={classNames("web-item__product", {
                      clear: !visiblePdf,
                    })}
                  >
                    <h5 className="web-item__product-title">
                      PDF-презентация{" "}
                      {visiblePdf ? (
                        <img
                          onClick={() => setVisiblePdf(false)}
                          className="web-item__product-delete"
                          src={CreateWebDelete}
                          alt="delete"
                        />
                      ) : (
                        <img
                          onClick={() => setVisiblePdf(true)}
                          className="web-item__product-delete"
                          src={addSection}
                          alt="add"
                        />
                      )}
                    </h5>
                    {visiblePdf && (
                      <div className="web-item__video-main">
                        <div className="web-item__video-dropfield">
                          {presentationFile?.obj && (
                            <p className="web-item__img-title">Презентация</p>
                          )}
                          <div className="dropfield">
                            <div className="dropfield__main">
                              <input
                                onChange={onPresentationFileChange}
                                className="dropfield__input"
                                type="file"
                                id="file"
                                accept="application/pdf"
                              />
                              {presentationFile && (
                                <div className="web-item__img-delete pdf">
                                  <img
                                    onClick={() => setPresentationFile("")}
                                    src={CreateWebDelete}
                                    alt="delete"
                                  />
                                </div>
                              )}
                              <div className="dropfield__content">
                                <div className="dropfield__btn">
                                  {!presentationFile && (
                                    <div className="dropfield__plus">
                                      <img src={avatarPlus} alt="Add icon" />
                                    </div>
                                  )}
                                  {!presentationFile && (
                                    <div className="dropfield__text">
                                      <span>
                                        Загрузить медиа файл
                                        <br />
                                      </span>
                                      Или перетащите файл сюда
                                    </div>
                                  )}
                                  {presentationFile && (
                                    <div className="dropfield__text">
                                      <span>
                                        Ваша презентация успешно загружена
                                        <br />
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="dropfield__notation">
                              <div>Используйте для презентации только .pdf</div>
                              <div>Максимальный размер: {MaxPdfSizeMb} Мб</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* <div className="create-promotion__item create-promotion__item--colors">
              <PromotionWebHeader
                title={"Подвал"}
                setVisible={() => setVisibleFooter(!visibleFooter)}
              />
              {visibleFooter && (
                <div className="web-item">
                  <div className="web-item__footer">
                    <div className="input">
                      <label className="input__label">Текст</label>
                      <input
                        value={footerText}
                        onChange={(e) => setFooterText(e.target.value)}
                        className={classNames("input__main")}
                        placeholder="Напишите текст"
                        type="text"
                      />
                    </div>
                    {visibleMobileFooter && (
                      <div className="input">
                        <label className="input__label">Контактная информация</label>
                        <input
                          value={mobileFooter}
                          onChange={(e) => setMobileFooter(e.target.value)}
                          className={classNames("input__main")}
                          placeholder="Введите текст"
                          type="mobile"
                        />
                      </div>
                    )}
                    {!visibleMobileFooter && (
                      <div
                        onClick={() => setVisibleMobileFooter(true)}
                        className="popup-user__add popup-user__add--web"
                      >
                        Добавить контактную информацию
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div> */}
            <div className="create-promotion__buttons">
              <button onClick={onSubmit} className="red-btn">
                Создать веб сайт
              </button>
              <button onClick={(e) => onSubmit(e, true)} className="white-btn">
                Сохранить черновик
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateWeb;
