import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setCreateStandInfo } from "../../../redux/appReducer";
import { fetchEvent } from "../../../redux/eventReducer";
import PlanExample from "../PlanExample";

const SecondStep = () => {
  const { id } = useParams();
  const [templates, setTemplates] = useState([]);

  const frameRef = useRef(null);

  const createStandInfo = useSelector((state) => state.app.createStandInfo);
  const activeTemplatePosition = createStandInfo?.standPositionId;
  const currentTariff = useSelector((state) => state.app.createStandInfo.tariff);

  const event = useSelector((state) => state.events.currentEvent);
  const tariff = currentTariff || event?.stand_tariff;
  const dispatch = useDispatch();

  const allPositions = useMemo(() => {
    const currentEventPositions = event?.position;
    if (!currentEventPositions) {
      return [];
    }

    return Object.values(currentEventPositions);
  }, [event?.position]);

  useEffect(() => {
    dispatch(setCreateStandInfo({ templateId: "", standPositionId: "" }));
  }, [dispatch]);

  const onTemplateClick = (t) => {
    const templateId = t.id || t.tmpl_id;
    const position = t.number;

    if (position === activeTemplatePosition) return;

    const selectedStand = templates?.find((s) => s.number === position);
    if (!selectedStand) return;

    frameRef.current?.contentWindow.postMessage(
      {
        type: "select_stand",
        data: position,
      },
      "*"
    );

    dispatch(setCreateStandInfo({ templateId, standPositionId: position }));
  };

  useEffect(() => {
    const getTemplates = () => {
      let tmpls = [];
      allPositions.forEach((pos, i) => {
        if (pos.size === tariff?.type) {
          tmpls.push({
            id: pos.tmpl_id,
            number: pos.number,
            previewImage: pos.image,
            name: pos.name,
            position: pos.number,
            price: 25000,
            isReserved: pos.is_reserved,
            banners: {
              video: 3,
              presentation: 2,
              image: 8,
            },
          });
        }
      });

      setTemplates(tmpls?.filter((t) => !t.isReserved));
    };

    getTemplates();
  }, [allPositions, tariff?.type]);

  useEffect(() => {
    if (!tariff?.id) {
      dispatch(fetchEvent({ eventId: id }));
    }
  }, [dispatch, id, tariff?.id]);

  return (
    <>
      <div className="create-stand-content__posttitle">Выбор стенда</div>
      <div
        className="create-stand-content__side-info create-stand-content__side-info--templates"
        style={{ marginLeft: 0 }}
      >
        <div className="create-stand-content__main-hall">
          <PlanExample
            eventId={id}
            onStandClick={onTemplateClick}
            externalFrameRef={frameRef}
            wrapperClassName="create-stand-example"
            withViewerSidePanel
          />
        </div>
      </div>
    </>
  );
};

export default SecondStep;
