import classNames from "classnames";
import React, { useRef } from "react";
import { CloseSvg } from "../../../assets";
import { Contacts } from "./Contacts";
import { Messages } from "./Messages";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChat, setTab, markReadChat } from "../../../redux/chatReducer";

const Chat = ({ closePopup, isOnVrEvent = false }) => {
  const dispatch = useDispatch();
  const chat = useSelector((state) => state.chats.current);
  const tab = useSelector((state) => state.chats.tab);
  // const contacts = useSelector((state) => state.chats.contacts);

  const onContactClick = (contact) => {
    if (contact?.id) {
      dispatch(markReadChat(contact.id));
    }
    dispatch(setCurrentChat(contact));
    dispatch(setTab("messages"));
  };

  const mainRef = useRef(null);

  return (
    <div
      className={classNames("side-chat", {
        "side-chat--vr-event": isOnVrEvent,
      })}
    >
      <div className="side-chat__header">
        <div className="side-chat__header-wrapper">
          <div className="side-chat__nav">
            <button
              onClick={() => dispatch(setTab("messages"))}
              className={classNames("side-chat__nav-item", {
                "side-chat__nav-item--active": tab === "messages",
              })}
              disabled={!chat}
            >
              Сообщения
            </button>
            <button
              onClick={() => dispatch(setTab("contacts"))}
              className={classNames("side-chat__nav-item", {
                "side-chat__nav-item--active": tab === "contacts",
              })}
            >
              Контакты
            </button>
          </div>
          <button onClick={closePopup} className="side-chat__close-btn">
            <img src={CloseSvg} alt="" />
          </button>
        </div>
        {tab === "messages" && chat && (
          <div className="side-chat__current-user chat-current-user">
            <div className="chat-current-user__image-wrapper">
              <img src={chat.photo} alt="" />
            </div>
            <div className="chat-current-user__text">
              <div className="chat-current-user__name">{chat.name}</div>
            </div>
          </div>
        )}
      </div>
      <div
        className={classNames("side-chat__main", {
          "side-chat__main--chat": tab === "messages",
        })}
        ref={mainRef}
      >
        {tab === "contacts" && <Contacts onContactClick={onContactClick} />}
        {tab === "messages" && <Messages mainRef={mainRef} />}
      </div>
    </div>
  );
};

export default Chat;
