import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import cn from "classnames";

const SidebarItem = ({
  text,
  link,
  icon,
  disabled,
  index,
  additionalActiveLinks,
  isFilling,
}) => {
  const history = useHistory();

  return (
    <NavLink
      className={cn("create-stand-sidebar__nav-item", {
        "create-stand-sidebar__nav-item--filling": isFilling,
        "create-stand-sidebar__nav-item--active": additionalActiveLinks?.some((l) =>
          history.location.pathname.includes(l)
        ),
      })}
      to={link}
      activeClassName="create-stand-sidebar__nav-item--active"
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
    >
      <span>{index + 1}</span>
      <div className="create-stand-sidebar__nav-icon">{icon}</div>
      <h3>{text}</h3>
    </NavLink>
  );
};

export default SidebarItem;
