import React from "react";
import classNames from "classnames";

const Button = ({ children, onClick, disabled, popup, className }) => {
  const isDisabled = disabled || popup === false;
  return (
    <button
      onClick={onClick}
      type="submit"
      className={classNames(
        "button",
        { disabled: isDisabled },
        { "button--popup": popup },
        className
      )}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default Button;
