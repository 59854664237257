import React from "react";
import { WebinarCard } from ".";
import { useSelector } from "react-redux";

const Cards = ({ openCreatePopup }) => {
  const items = useSelector((state) => state.webinars.items);
  const type = useSelector(state => state.webinars.type);

  return (
    <div className="webinar__cards">
      {items.map((w) => (
        <WebinarCard
          key={w.id}
          id={w.id}
          title={w.title}
          startDay={new Date(w.startDay)}
          startTime={new Date(w.startTime)}
          endDay={new Date(w.endDay)}
          endTime={new Date(w.endTime)}
          description={w.description}
          openCreatePopup={openCreatePopup}
          selectedWebinarType={type}
        />
      ))}
    </div>
  );
};

export default Cards;
