import { immediateToast } from "izitoast-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getEventsList } from "../../api";
import { AdBanners, EventItem, EventsNav } from "../../components/events";
import { FavoritePopup } from "../../components/favorite";
import {
  EventConfirmPopup,
  EventNotificationPopup,
  Header,
  HeaderInfo,
  Loader,
  Sidebar,
  SidePopupChat,
} from "../../components/shared";
import { useFavoritePopup } from "../../hooks/useFavoritePopup";
import { setResetStandInfo } from "../../redux/appReducer";
import {
  setCreateRegisterOnEvent,
  setCreateStandOnEvent,
} from "../../redux/eventReducer";
import { convertMonth } from "../../utils/convertMonth";
import { errorHandler } from "../../utils/errorHandler";
import { prepareEventList } from "../../utils/prepareEventList";

const Events = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const role = useSelector((state) => state.user.profile.role);
  const createStandOnEvent = useSelector((state) => state.events.createStandOnEvent);
  if (createStandOnEvent) {
    dispatch(setCreateStandOnEvent(0));
    history.push(`/events/create/${createStandOnEvent}?currentStep=1`);
  }
  const createRegisterOnEvent = useSelector(
    (state) => state.events.createRegisterOnEvent
  );
  if (createRegisterOnEvent) {
    dispatch(setCreateRegisterOnEvent(0));
    history.push(`/events/registration?id=${createRegisterOnEvent}`);
  }
  const [allEvents, setAllEvents] = useState([]);
  // массив объектов {месяц, год}
  const [eventsDates, setEventsDates] = useState([]);
  // const [filteredEventDates, setFilteredEventDates] = useState([]);

  const [inputSearch, setInputSearch] = useState("");

  const [eventNotificationPopup, setEventNotificationPopup] = useState(false);
  const [customNotificationTitle, setCustomNotificationTitle] = useState("");
  const [notificationText, setNotificationText] = useState("");

  const [popupOpened, setPopupOpened] = useState(false);
  const [selectedEventLink, setSelectedEventLink] = useState(null);
  const [popupText, setPopupText] = useState("");

  const onSearch = (e) => {
    let newValue = e.target.value;
    setInputSearch(newValue);
  };

  const { closePopup, popupInfo, setPopupInfo } = useFavoritePopup();

  const onEventClick = ({
    isUserRegistered,
    id,
    isStandCreated,
    visitTypeId,
    availableSeats,
    availableStandsCount,
    isEventCreated,
    type,
  }) => {
    const selectedEvent = allEvents.find((ev) => ev.id === id);

    localStorage.setItem("goToSettings", "/events/list");

    dispatch(setResetStandInfo());
    setCustomNotificationTitle("");

    if (role === "организатор" && isEventCreated) {
      return setPopupInfo({
        isOpened: true,
        selectedEvent: { ...selectedEvent, type: "actual" },
      });
    }

    if (role === "участник" && type === "gallery") {
      setNotificationText(
        "На индивидуальных презентациях недоступна аренда стендов. Для посещения измените роль на Посетитель."
      );
      setCustomNotificationTitle("Создание стенда");
      return setEventNotificationPopup(true);
    }

    if (role === "участник" && isStandCreated) {
      return setPopupInfo({
        isOpened: true,
        selectedEvent: { ...selectedEvent, type: selectedEvent.standStatusType },
      });
    }

    if (role === "участник" && !isStandCreated && availableStandsCount > 0) {
      if (visitTypeId === 20) {
        immediateToast("info", {
          message: "Регистрация участников только по приглашениям.",
          position: "topCenter",
        });
      } else {
        setSelectedEventLink(`/events/create/${id}?currentStep=1`);
        setPopupText("Желаете перейти к созданию заявки?");
        return setPopupOpened(true);
      }
    }

    if (role === "участник" && !isStandCreated && availableStandsCount === 0) {
      setNotificationText("Все стенды уже заняты");
      return setEventNotificationPopup(true);
    }

    if (
      role === "посетитель" &&
      isUserRegistered &&
      new Date() < selectedEvent.startDate
    ) {
      setNotificationText("Вы уже зарегистрированы, мероприятие ещё не началось");
      return setEventNotificationPopup(true);
    }

    if (
      role === "посетитель" &&
      isUserRegistered &&
      new Date() >= selectedEvent.startDate
    ) {
      return setPopupInfo({
        isOpened: true,
        selectedEvent,
      });
    }

    if (role === "посетитель" && !isUserRegistered) {
      setSelectedEventLink(`/events/registration?id=${id}`);
      setPopupText(`Желаете перейти к выставке ${selectedEvent.title}?`);
      return setPopupOpened(true);
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const filterEvents = (allEvents) => {
      const evs = allEvents;
      setAllEvents(prepareEventList(evs, { withoutDebug: true }));

      const dates = evs.map((eve) => {
        let startDate = moment(eve.start_date, "YYYY-MM-DD HH:mm:ss");
        return {
          date: parseInt(startDate.format("YYYYMM")),
          month: startDate.month(),
          year: startDate.year(),
        };
      });

      const filteredDates = [];
      dates.map((d) => {
        if (filteredDates.some((date) => date.date === d.date)) {
          return d;
        }

        filteredDates.push(d);
        return d;
      });

      // сортировка месяцев по убыванию
      setEventsDates(filteredDates.sort((a, b) => b.date - a.date));
    };

    const getEvents = async () => {
      setIsLoading(true);
      try {
        const { data } = await getEventsList({
          dateType: "actual",
          name: inputSearch,
        });

        if (!isMounted) {
          return;
        }
        data.data.data && filterEvents(data.data.data);
      } catch (error) {
        errorHandler(error);
      }
      setIsLoading(false);
    };

    getEvents();

    return () => {
      isMounted = false;
    };
  }, [role, inputSearch]);

  return (
    <div className="page-wrapper">
      <div className="app app-event">
        {popupInfo.isOpened && (
          <FavoritePopup popupInfo={popupInfo} closePopup={closePopup} />
        )}
        {popupOpened && (
          <EventConfirmPopup
            eventLink={selectedEventLink}
            closePopup={() => setPopupOpened(false)}
            eventText={popupText}
            eventTitle={role === "посетитель" ? "Посещение мероприятия" : "Мероприятие"}
          />
        )}
        {eventNotificationPopup && (
          <EventNotificationPopup
            popupText={notificationText}
            closePopup={() => setEventNotificationPopup(false)}
            customTitle={customNotificationTitle}
          />
        )}
        <Sidebar />
        <SidePopupChat />
        <Header />
        <div className="page__main page__main--events">
          <div className="event-calendar">
            <main className="event-calendar__main">
              <div className="event-calendar__header">
                <h3 className="event-calendar__title">Event Календарь</h3>
                <div className="event-calendar__search">
                  <form
                    className="event-calendar__form"
                    onSubmit={(e) => e.preventDefault()}
                    action="#"
                  >
                    <input
                      value={inputSearch}
                      onChange={onSearch}
                      className="event-calendar__input"
                      type="text"
                      placeholder="Поиск мероприятия"
                    />
                    <div className="event-calendar__icon"></div>
                  </form>
                </div>
                <HeaderInfo />
                <EventsNav />
              </div>
              {isLoading ? (
                <div className="event-calendar__loader">
                  <Loader />
                </div>
              ) : (
                <div className="event-calendar__content">
                  {eventsDates.length > 0 ? (
                    eventsDates.map((item, index) => (
                      <React.Fragment key={index}>
                        <h3 className="calendar__title">
                          {convertMonth(item.month) + " "}
                          {item.year}
                        </h3>
                        {allEvents.map((evt) => {
                          let date = parseInt(moment(evt.startDate).format("YYYYMM"));
                          if (date === item.date) {
                            return (
                              <EventItem
                                key={evt.id}
                                onEventClick={onEventClick}
                                eventInfo={evt}
                              />
                            );
                          }
                          return null;
                        })}
                      </React.Fragment>
                    ))
                  ) : (
                    <h5>К сожалению, по данному запросу ничего не найдено</h5>
                  )}
                </div>
              )}
            </main>
            <AdBanners />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
