import React from "react";
import { Link } from "react-router-dom";
import { miniLogoSvg } from "../../assets";
import { Button } from "../shared";
import cn from "classnames";

const Error = ({ title, text, isExtraBg, withoutLogo }) => {
  return (
    <div className="error-page">
      <div
        className={cn("error-page-bg", {
          "error-page-bg--extra": isExtraBg,
        })}
      ></div>
      {!withoutLogo && (
        <div className="registration__logo">
          <img src={miniLogoSvg} alt="JustEvents" />
        </div>
      )}
      <div className="error-page__content">
        <div className="error-page__main error-page__main--extra">
          <h3 className="error-page__title">{title}</h3>
          <p className="error-page__text">{text}</p>
          <Link to="/events/list">
            <Button>На главную</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
