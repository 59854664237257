import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Cell, Pie, PieChart } from "recharts";
import { bannerSettings } from "../../../assets";
import { DefaultPieChartColors } from "../../../pages/stand/Analytics";

// const dataOrigin = [
//   {
//     "dimensions": [ "Russia" ],
//     "metrics": [ 16.0, 13.0 ],
//   },
//   {
//     "dimensions": [ "Russia" ],
//     "metrics": [ 2.0, 1.0 ],
//   }
// ];

const Geography = () => {
  const visitorsCount = useSelector((state) => state.events.analytics?.visitors_count);
  const geo = useSelector((state) => state.events.analytics.geo);
  const [data, setData] = useState([]);

  useEffect(() => {
    const dataGeo = geo?.map((e) => {
      return {
        name: e?.dimensions?.length > 0 && e?.dimensions[0],
        value:
          e?.metrics?.length > 0 &&
          Math.round(e?.metrics?.reduce((prev, current) => prev + current, 0)),
      };
    });
    setData(dataGeo);
  }, [geo]);

  // sort and get groups to match visitors count (groups <= users)
  const dataF = data
    .sort((a, b) => (a.value < b.value ? 1 : a.value > b.value ? -1 : 0))
    .slice(0, visitorsCount);
  const totalUsersVisits = dataF?.reduce((prev, current) => prev + current.value, 0) || 0;

  return (
    <div className="stand-analytics__geography analytics-stand-geography">
      <div className="analytics-stand-geography__header">
        <h2 className="analytics-stand-geography__title">География</h2>
        <button className="analytics-stand-geography__more-btn">
          <img src={bannerSettings} alt="" />
        </button>
      </div>
      <div className="analytics-stand-geography__main">
        <div className="analytics-stand-geography__chart-wrapper">
          <div className="analytics-stand-geography__participants">
            <h3>{visitorsCount}</h3>
            <span>посетителей</span>
          </div>
          <PieChart width={169} height={169}>
            <Pie
              data={dataF}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={84}
              innerRadius={70}
              strokeWidth={dataF.length > 1 ? "3px" : 0}
            >
              {dataF.map((entry, i) => (
                <Cell
                  key={i}
                  fill={DefaultPieChartColors[i % DefaultPieChartColors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </div>
        <div className="analytics-stand-geography__footer">
          {dataF.map((c, i) => (
            <div key={i} className="analytics-stand-geography__country">
              <span>
                {totalUsersVisits &&
                  ((100.0 * c.value) / totalUsersVisits).toFixed(2) + " %"}
              </span>
              <h3>
                <span
                  style={{
                    backgroundColor:
                      DefaultPieChartColors[i % DefaultPieChartColors.length],
                  }}
                ></span>
                {c.name}
              </h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Geography;
