import classNames from "classnames";
import { immediateToast } from "izitoast-react";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  getMyBanners,
  getOrderBanners,
  setBanners,
  updateBanner,
} from "../../api/banners";
import { promotionDate } from "../../assets";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { Dropfield, Header, Sidebar, SidePopupChat } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import { PromotionExample } from "../../components/stand/promotion";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import { fetchEvent } from "../../redux/eventReducer";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { errorHandler } from "../../utils/errorHandler";
import { errorMessage } from "../../utils/errorMessage";
import { getFileFromUrl } from "../../utils/getFileFromUrl";
import { isValidHttpUrl } from "../../utils/isValidHttpUrl";
import { minDateForCalendar } from "../../utils/minDateForCalendar";

const CreateAd = () => {
  const { type, id, bannerId } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.user.profile);

  const stand = useSelector((state) => state.events.currentStand);
  const currentEvent = useSelector((state) => state.events.currentEvent);
  const currentStand = useSelector((state) => state.events.currentStand);

  const roomViewerUrl = useSelector((state) => state.app.settings?.roomViewerUrl);

  const [allBannerTypes, setAllBannerTypes] = useState([]);

  useEffect(() => {
    const fetchBannerTypes = async () => {
      try {
        const eventId = currentEvent?.id || stand?.event_id;
        if (!eventId) return;

        const { data } = await getOrderBanners({ eventId });

        setAllBannerTypes(data?.order_banners);
      } catch (error) {}
    };

    fetchBannerTypes();
  }, [currentEvent?.id, stand?.event_id]);

  const [bannerType, setType] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [site, setSite] = useState("");
  const [price, setPrice] = useState(0);

  const [image, setImage] = useState("");
  const [imageBase64, setBase64Image] = useState("");

  const [editionBannerType, setEditionBannerType] = useState({});

  const bannerTypes = useMemo(() => {
    if (!startDate) {
      return [...allBannerTypes];
    }

    return [...allBannerTypes].filter((b) => {
      return !b.busy_times.some((busy_time) => {
        const newBannerStartDate = moment(startDate).startOf("day");

        const busyBannerStartDate = moment(busy_time[0]).startOf("day");
        const busyBannerEndDate = moment(busy_time[1]).endOf("day");

        return (
          (newBannerStartDate.isBetween(busyBannerStartDate, busyBannerEndDate) ||
            newBannerStartDate.isSame(busyBannerStartDate)) &&
          editionBannerType?.id !== b.id
        );
      });
    });
  }, [allBannerTypes, editionBannerType?.id, startDate]);

  useEffect(() => {
    const getEditionBanner = async () => {
      try {
        const { data } = await getMyBanners({ [`${type}Id`]: id });

        if (!data?.data || !allBannerTypes) return;

        const editionBanner = data?.data?.find((b) => b.id === parseInt(bannerId));

        if (!editionBanner) return;

        setStartDate(new Date(editionBanner.started_at));
        setEndDate(new Date(editionBanner.ended_at));
        setSite(editionBanner.url);

        if (editionBanner.image) {
          const imageFile = await getFileFromUrl({
            url: editionBanner.image,
          });

          setImage(imageFile);
          setBase64Image(editionBanner.image);
        }

        const selectedBannerType = allBannerTypes.find(
          (b) => b.name === editionBanner.name
        );

        if (selectedBannerType) {
          setType(selectedBannerType);
          setEditionBannerType(selectedBannerType);
        }
      } catch (error) {}
    };

    if (type === "event") {
      getEditionBanner();
    }
  }, [allBannerTypes, bannerId, id, type]);

  let eventId = stand?.event_id;
  if (type === "event") {
    eventId = id;
  }

  useEffect(() => {
    if (type === "stand" && stand) {
      dispatch(fetchEvent({ eventId: eventId }));
    }
  }, [dispatch, type, stand, eventId]);

  const encodeImageFileAsURL = (element) => {
    return new Promise((cb) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        cb(reader.result);
      };
      reader.readAsDataURL(element);
    });
  };

  const onImageChange = async (e) => {
    try {
      const newImage = e.target.files[0];

      encodeImageFileAsURL(newImage).then((data) => {
        setBase64Image(data);
      });
      setImage(newImage);
    } catch (error) {}
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      let params = {};

      if (!isValidHttpUrl(site)) {
        return errorMessage("Некорректная ссылка на сайт");
      }

      if (type === "event") {
        params.eventId = id;
      } else {
        params.standId = id;
      }

      params.id = bannerType.id;
      params.startDate = moment(startDate, "DD.MM.YYYY").format("YYYY-MM-DD");
      params.endDate = moment(endDate, "DD.MM.YYYY").format("YYYY-MM-DD");
      params.url = site;
      params.image = image;

      let successMessage = "";
      if (bannerId) {
        params.id = bannerId;
        await updateBanner(params);
        successMessage = "Баннер успешно обновлен";
      } else {
        await setBanners(params);
        successMessage = "Баннер успешно добавлен";
      }

      immediateToast("success", {
        message: successMessage,
        position: "topCenter",
      });

      history.push(`/${type}/${id}/settings/promotion`);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    const start = moment(startDate);
    const end = moment(endDate);
    const diff = end.diff(start, "days") + 1;
    const amount = diff * (bannerType?.price || 0);
    setPrice(amount || 0);
  }, [startDate, endDate, bannerType?.price]);

  let minDate = new Date();

  if (type === "stand") {
    minDate = minDateForCalendar(currentStand?.event?.start_date);
  }

  const isPageDisabled = useCreateEventPageDisabled(pageNames.PROMOTION);

  const receptionBanners = bannerTypes.filter((b) => b.type === "reception");
  const hallBanners = bannerTypes.filter((b) => b.type === "exhibition");

  const withReceptionBanners = receptionBanners.length > 0;
  const withHallBanners = hallBanners.length > 0;

  return (
    <div className="app">
      <Sidebar />
      <Header />
      <SidePopupChat />
      <div className="page__main">
        <NavStand />
        <div className="stand-settings">
          <StandHeader
            title="Разместить рекламу"
            subtitle={
              profile.role === "участник"
                ? "Купить и разместить фото или видео баннер в Лобби или основном зале"
                : "Купить и разместить фото или видео баннер в разделе Event календарь"
            }
            back
            withHint={false}
          />
          <main className="create-promotion__main">
            <form className="create-promotion__form">
              <div className="create-promotion__date">
                <div className="input input--promotion">
                  <label className="input__label">Дата начала показов</label>
                  <DatePicker
                    selected={startDate ? new Date(startDate) : null}
                    onChange={(date) => {
                      setStartDate(date);
                      setEndDate("");
                    }}
                    dateFormat="yyyy.MM.dd"
                    className={classNames("input__main", "input__date")}
                    minDate={minDate}
                    maxDate={currentEvent && new Date(currentEvent.end_date)}
                    placeholderText="дд.мм.гг"
                  />
                  <img
                    className="create-promotion__date-icon"
                    src={promotionDate}
                    alt=""
                  />
                </div>
                <div className="input input--promotion">
                  <label className="input__label">Дата окончания</label>
                  <DatePicker
                    selected={endDate ? new Date(endDate) : null}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="yyyy.MM.dd"
                    className={classNames("input__main", "input__date")}
                    minDate={startDate ? startDate : minDate}
                    maxDate={currentEvent && new Date(currentEvent.end_date)}
                    placeholderText="дд.мм.гг"
                  />
                  <img
                    className="create-promotion__date-icon"
                    src={promotionDate}
                    alt=""
                  />
                </div>
              </div>
              <div className="create-promotion__radio">
                <span>Выберите вариант размещения баннерной рекламы</span>
                {withReceptionBanners && (
                  <>
                    <div className="create-promotion__posttitle">Баннеры в холле</div>
                    <div className="create-promotion__banner-radios">
                      <div className="create-promotion__grid-radio">
                        {receptionBanners.map((item, k) => {
                          return (
                            <label key={k} className="create-promotion__grid-radio-item">
                              <input
                                onChange={() => setType(item)}
                                checked={bannerType.id === item.id}
                                type="radio"
                                disabled={bannerId}
                              />
                              {capitalizeFirstLetter(item.name)}
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
                {withHallBanners && (
                  <>
                    <div className="create-promotion__posttitle">
                      Баннеры в основном зале
                    </div>
                    <div className="create-promotion__banner-radios">
                      <div className="create-promotion__grid-radio">
                        {hallBanners.map((item, k) => {
                          return (
                            <label key={k} className="create-promotion__grid-radio-item">
                              <input
                                onChange={() => setType(item)}
                                checked={bannerType.id === item.id}
                                type="radio"
                                disabled={bannerId}
                              />
                              {capitalizeFirstLetter(item.name)}
                            </label>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Dropfield
                text={"Загрузить изображение"}
                notation={["Используйте только .jpg, .png, .jpeg типы файлов"]}
                value={imageBase64}
                onChange={onImageChange}
                allowedFileTypes="image/png, image/jpg, image/jpeg"
                maxFileSize={25}
                maxFileResolution={{
                  width: 1920,
                  height: 1080,
                }}
                minFileResolution={{
                  width: 1136,
                  height: 640,
                }}
              />
              <div className="input input--create-ad">
                <label className="input__label">Введите ссылку на сайт</label>
                <input
                  value={site}
                  onChange={(e) => setSite(e.target.value)}
                  className={classNames("input__main")}
                  placeholder="http://www.example.com"
                  type="text"
                />
                {site?.trim().length > 0 && !isValidHttpUrl(site) && (
                  <span className="input-error">
                    Ссылка должна начинаться с http:// или https://
                  </span>
                )}
              </div>
              {type !== "event" && bannerType?.type !== "reception" && (
                <div className="create-promotion__footer">
                  <div className="create-promotion__footer-block">
                    <div className="create-promotion__total">Стоимость 1 дня:</div>
                    <div className="create-promotion__price">
                      {bannerType?.price || 0} ₽
                    </div>
                  </div>
                  <div className="create-promotion__footer-block">
                    <div className="create-promotion__total">Итоговый бюджет:</div>
                    <div className="create-promotion__price">{price} ₽</div>
                  </div>
                </div>
              )}
              <button
                onClick={onSubmit}
                disabled={
                  !(
                    image &&
                    startDate &&
                    endDate &&
                    site &&
                    bannerType &&
                    isValidHttpUrl(site)
                  ) || isPageDisabled
                }
                className="red-btn"
              >
                Разместить рекламу
              </button>
            </form>
            <div className="create-promotion__widget-wrapper">
              <PromotionExample
                eventId={eventId}
                roomViewerUrl={roomViewerUrl}
                withHall={withHallBanners}
                withReception={withReceptionBanners}
              />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default CreateAd;
