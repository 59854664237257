import React from "react";
import { Link } from "react-router-dom";
import { miniLogoSvg } from "../../assets";

const Logo = () => {
  return (
    <Link to="/" className="registration__logo">
      <img src={miniLogoSvg} alt="JustEvents" />
    </Link>
  );
};

export default Logo;
