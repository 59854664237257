import React from "react";
import { closePopupSvg } from "../../../assets";

const PromotionPopup = ({ fullImg, onOpen, onClose }) => {
  return (
    <div className="promotion-popup">
      <div className="promotion-popup__main">
        <div onClick={onOpen} className="promotion-popup__banner">
          <img src={fullImg} alt="Full Banner" />
        </div>
        <div onClick={onClose} className="promotion-popup__close">
          <img src={closePopupSvg} alt="Close" />
        </div>
      </div>
      <div className="promotion-popup__overlay"></div>
    </div>
  );
};

export default PromotionPopup;
