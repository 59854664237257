import React, { useState, useEffect } from "react";
import { Dropfield } from "../../shared";
import cn from "classnames";

const LogoDropfield = ({ doUpload, actualImage }) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    if (actualImage) {
      setImage(actualImage);
    }
  }, [actualImage]);

  const onImageChange = async (e) => {
    try {
      const newImage = e.target.files[0];
      doUpload(newImage).then((res) => {
        if (res?.data?.url) {
          setImage(res?.data?.url);
        }
      });
    } catch (error) {}
  };

  return (
    <div
      className={cn("model-logo-dropfield", {
        "model-logo-dropfield--hidden-input": image,
      })}
    >
      <div className="model-logo-dropfield__title">Логотип</div>
      <Dropfield
        text={"Загрузить изображение"}
        notation={["Используйте только .jpg, .png типы файлов"]}
        value={image}
        onChange={onImageChange}
        allowedFileTypes="image/png, image/jpg, image/jpeg"
        maxFileSize={25}
        maxFileResolution={{
          width: 1920,
          height: 1080,
        }}
        minFileResolution={{
          width: 1136,
          height: 640,
        }}
      />
    </div>
  );
};

export default LogoDropfield;
