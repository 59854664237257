import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getProgramEvents } from "../../../api/event";
import queryString from "query-string";
import { Loader, ProgramCard } from "../../shared";
import { NoProgramSvg } from "../../../assets";

export const Program = () => {
  const { id } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const standId = useSelector((state) => state.events.currentStandInPlayer?.id);
  const location = queryString.parse(history.location.search).location;

  const place = queryString.parse(history.location.search).memberId;

  const [programEvents, setProgramEvents] = useState([]);

  useEffect(() => {
    const fetchProgramEvents = async () => {
      try {
        let programEventsData = [];

        let webinars = [];
        let standEvents = [];

        setIsLoading(true);

        if (standId) {
          const { data } = await getProgramEvents({ standId, type: "stand" });

          webinars = data.data.webinars.map((ev) => ({
            ...ev,
            isWebinar: true,
          }));

          standEvents = data.data.main.map((ev) => ({
            ...ev,
            isWebinar: false,
          }));
        }

        if (!standId || location !== "member") {
          const { data } = await getProgramEvents({ standId: id, type: "event" });

          webinars = data.data.webinars.map((ev) => ({
            ...ev,
            isWebinar: true,
          }));

          standEvents = data.data.main.map((ev) => ({
            ...ev,
            isWebinar: false,
          }));
        }

        programEventsData = [...webinars, ...standEvents];

        let isLobby;

        if (place && +place.slice(5) !== 0) {
          isLobby = 0;
        } else {
          isLobby = 1;
        }

        setProgramEvents(
          programEventsData.map((ev) => ({
            isLobby,
            position: place ? place.slice(5) : 0,
            standId,
            timeStart: ev.date_start,
            timeEnd: ev.date_end,
            title: ev.name,
            isWebinar: ev.isWebinar,
            eventId: id,
          }))
        );

        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchProgramEvents();
  }, [id, location, place, standId]);

  return (
    <div className="program-popup">
      {isLoading ? (
        <div className="program-popup__loader">
          <Loader />
        </div>
      ) : programEvents && programEvents.length > 0 ? (
        <ul className="program-popup__programs">
          {programEvents
            .sort((a, b) => new Date(a.timeStart) - new Date(b.timeStart))
            .map((prog, ind) => (
              <ProgramCard key={ind} program={prog} />
            ))}
        </ul>
      ) : (
        <div className="program-popup__no-program">
          <img src={NoProgramSvg} alt="" />
          <span>Здесь пока нет программы</span>
        </div>
      )}
    </div>
  );
};

export default Program;
