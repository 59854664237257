import classNames from "classnames";
import { immediateToast } from "izitoast-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { createEventLegalInfo } from "../../api/organizer";
import { confirmStandMedia } from "../../api/stand";
import { saveJuridicalInfo } from "../../api/user";
import { setAccount, setProfileInfo } from "../../redux/appReducer";
import { errorHandler } from "../../utils/errorHandler";
import { successMessage } from "../../utils/successMessage";
import { ButtonLoader } from "../shared/ButtonLoader";
import { generateFullItemLink, pageNames } from "./CreateEvent/EventSidebar";

export const StepButtons = ({ navItems, currentNavItemIndex, isNextStepActive }) => {
  const history = useHistory();
  const { pageContent, id: eventId } = useParams();

  const fillingPageName = useSelector((state) => state.events.fillingPageName);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);

  const [isLoading, setIsLoading] = useState(false);

  const currentEvent = useSelector((state) => state.events.currentEvent);
  const [eventStatusType] = currentEvent?.status_type || [];

  const isButtonsHidden = eventStatusType === "actual";

  const createEventInfo = useSelector((state) => state.app.createEventInfo);

  const {
    organization,
    inn,
    registrationNumber,
    bic,
    bankName,
    billNumber,
    correspondingNumber,
    registrationCode,
    directorName,
    actionBasis,
    juridicalAdress,
    contactPhone,
  } = createEventInfo;

  const onPreviousStepClick = () => {
    if (currentNavItemIndex === 0) {
      return;
    }

    const navItem = navItems[currentNavItemIndex - 1];

    if (!navItem) {
      return;
    }

    const link = generateFullItemLink({ link: navItem, eventId });

    history.push(link);
  };

  const onNextStep = async () => {
    const navItem = navItems[currentNavItemIndex + 1];

    if (!navItem) {
      return;
    }

    const link = generateFullItemLink({ link: navItem, eventId });

    history.push(link);
  };

  const isSendEventReuqestDataActive =
    eventId &&
    organization.length >= 5 &&
    inn.length >= 10 &&
    registrationNumber.length === 13 &&
    bic.length === 9 &&
    bankName.length >= 5 &&
    bankName.length > 0 &&
    billNumber.length >= 20 &&
    correspondingNumber.length === 20 &&
    registrationCode.length > 8 &&
    directorName.length > 2 &&
    actionBasis.length > 2 &&
    juridicalAdress.length > 3 &&
    contactPhone.length > 10 &&
    currentEvent?.short_description &&
    (fillingPageName === pageNames.JURIDICAL_INFO || isNextStepActive);

  const onCreateEventRequest = async () => {
    if (!isSendEventReuqestDataActive) return;

    try {
      setIsLoading(true);
      await createEventLegalInfo({ ...createEventInfo, eventId });

      immediateToast("success", {
        message: "Заявка на мероприятие успешна создана.",
        position: "topCenter",
      });

      const updatedUser = await saveJuridicalInfo({
        bankName,
        bic,
        billNumber,
        inn,
        correspondingNumber,
        organizationName: organization,
        registrationNumber,
        contactPhone,
        user,
        kpp: registrationCode,
        justification: actionBasis,
        address: juridicalAdress,
      });

      await confirmStandMedia({ eventId });

      dispatch(setProfileInfo(updatedUser));
      dispatch(setAccount(updatedUser));

      setIsLoading(false);
      return history.push("/favorite/requests");
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  const stepIsFinalBeforePublish =
    navItems[currentNavItemIndex]?.name === pageNames.DOCUMENTS;
  const publishIsAllowed = fillingPageName === pageNames.DOCUMENTS;
  const onPublishClick = async () => {
    try {
      setIsLoading(true);

      await confirmStandMedia({ eventId });

      setIsLoading(false);
      successMessage("Лобби успешно сохранено.");

      history.push("/favorite/filling");
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  if (isButtonsHidden) {
    return null;
  }

  const isPrevStepActive = currentNavItemIndex > 0;

  return (
    <div className="create-stand-sidebar__buttons">
      <div className="create-stand-sidebar__step-btns">
        <button
          disabled={!isPrevStepActive}
          onClick={onPreviousStepClick}
          className={classNames("create-stand-sidebar__step-btn", {
            "create-stand-sidebar__step-btn--disabled": !isPrevStepActive,
          })}
        >
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill={isPrevStepActive ? "#c90113" : "#727E8E"}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.9999 6H3.4099L6.7099 2.71C6.8982 2.5217 7.00399 2.2663 7.00399 2C7.00399 1.7337 6.8982 1.47831 6.7099 1.29C6.52159 1.1017 6.2662 0.995911 5.9999 0.995911C5.73359 0.995911 5.4782 1.1017 5.2899 1.29L0.289896 6.29C0.198856 6.38511 0.127491 6.49725 0.0798963 6.62C-0.0201217 6.86346 -0.0201217 7.13654 0.0798963 7.38C0.127491 7.50275 0.198856 7.6149 0.289896 7.71L5.2899 12.71C5.38286 12.8037 5.49346 12.8781 5.61532 12.9289C5.73718 12.9797 5.86788 13.0058 5.9999 13.0058C6.13191 13.0058 6.26261 12.9797 6.38447 12.9289C6.50633 12.8781 6.61693 12.8037 6.7099 12.71C6.80363 12.617 6.87802 12.5064 6.92879 12.3846C6.97956 12.2627 7.00569 12.132 7.00569 12C7.00569 11.868 6.97956 11.7373 6.92879 11.6154C6.87802 11.4936 6.80363 11.383 6.7099 11.29L3.4099 8H10.9999C11.2651 8 11.5195 7.89465 11.707 7.70711C11.8945 7.51957 11.9999 7.26522 11.9999 7C11.9999 6.73479 11.8945 6.48043 11.707 6.2929C11.5195 6.10536 11.2651 6 10.9999 6Z"
              fill={isPrevStepActive ? "#c90113" : "#727E8E"}
            />
          </svg>
          <span>Назад</span>
        </button>
        <button
          onClick={onNextStep}
          className={classNames("create-stand-sidebar__step-btn", {
            "create-stand-sidebar__step-btn--disabled": !isNextStepActive,
          })}
          disabled={!isNextStepActive}
        >
          <span>Вперед</span>
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            fill={isNextStepActive ? "#c90113" : "#727E8E"}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.0001 8L8.5901 8L5.2901 11.29C5.1018 11.4783 4.99601 11.7337 4.99601 12C4.99601 12.2663 5.1018 12.5217 5.2901 12.71C5.47841 12.8983 5.7338 13.0041 6.0001 13.0041C6.26641 13.0041 6.5218 12.8983 6.7101 12.71L11.7101 7.71C11.8011 7.61489 11.8725 7.50275 11.9201 7.38C12.0201 7.13654 12.0201 6.86346 11.9201 6.62C11.8725 6.49725 11.8011 6.3851 11.7101 6.29L6.7101 1.29C6.61714 1.19627 6.50654 1.12187 6.38468 1.07111C6.26282 1.02034 6.13212 0.9942 6.0001 0.9942C5.86809 0.9942 5.73739 1.02034 5.61553 1.07111C5.49367 1.12187 5.38307 1.19627 5.2901 1.29C5.19637 1.38296 5.12198 1.49356 5.07121 1.61542C5.02044 1.73728 4.99431 1.86799 4.99431 2C4.99431 2.13201 5.02044 2.26272 5.07121 2.38457C5.12198 2.50643 5.19637 2.61703 5.2901 2.71L8.5901 6L1.0001 6C0.734887 6 0.480533 6.10535 0.292996 6.29289C0.10546 6.48043 0.000103951 6.73478 0.000103951 7C0.000103951 7.26521 0.10546 7.51957 0.292996 7.7071C0.480533 7.89464 0.734887 8 1.0001 8Z"
              fill={isNextStepActive ? "#c90113" : "#727E8E"}
            />
          </svg>
        </button>
      </div>
      {pageContent === "juridical-info" && (
        <button
          disabled={!isSendEventReuqestDataActive}
          className={classNames("create-stand-sidebar__next-btn", "red-btn", {
            disabled: !isSendEventReuqestDataActive || isLoading,
          })}
          onClick={onCreateEventRequest}
        >
          {isLoading ? <ButtonLoader /> : "Отправить на модерацию"}
        </button>
      )}
      {stepIsFinalBeforePublish && (
        <button
          className={classNames("create-stand-sidebar__next-btn", "red-btn", {})}
          onClick={onPublishClick}
          disabled={!publishIsAllowed}
        >
          {isLoading ? <ButtonLoader /> : "Отправить"}
        </button>
      )}
    </div>
  );
};
