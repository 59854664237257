import { immediateToast } from "izitoast-react";
import moment from "moment";
import React from "react";
import { getWebinarLink } from "../../api/webinar";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { errorMessage } from "../../utils/errorMessage";

// {
//   isLobby: 1 || 0,
//   position: 0,
//   standId: 1,
//   timeStart: "2021-10-13 13:00",
//   timeEnd: "2021-10-14 13:45",
//   title: "Card title",
//   isWebinar: false,
//   eventId: 1
// }

const ProgramCard = ({ program, onEditClick = null }) => {
  const formatedTimeStart = moment(program.timeStart);
  const formatedTimeEnd = moment(program.timeEnd);
  const formatedStartDate = formatedTimeStart.format("DD MMMM");
  const formatedEndDate = formatedTimeEnd.format("DD MMMM");

  const history = useHistory();
  const event = useSelector((state) => state.events.currentEvent);
  const [eventStatusType] =
    useSelector((state) => state.events.currentEvent?.status_type) || [];

  const onMoveClick = () => {
    if (program.isWebinar) {
      if (new Date() < new Date(program.timeStart)) {
        return errorMessage("Вебинар не запущен");
      }

      let type = "event";
      let id = event?.id;
      if (program.standId) {
        type = "stand";
        id = program.standId;
      }
      getWebinarLink({
        [`${type}_id`]: id,
      }).then((data) => {
        if (!(data?.success && data?.data?.link)) {
          data?.message &&
            immediateToast("warning", {
              message: data?.message,
              position: "topCenter",
            });
          return;
        }
        // open webinar
        window.open(data?.data?.link);
      });
    } else if (program.eventId) {
      history.push(
        `/events/${program.eventId}?location=member&memberId=place${program.position}`
      );
    }
  };

  return (
    <li className="program-popup__program">
      <div className="program-popup__time" style={{ textAlign: "center" }}>
        <p style={{ fontSize: "12px" }}>
          {formatedStartDate === formatedEndDate
            ? formatedEndDate
            : formatedTimeStart.format("DD.MM.YY") +
              " - " +
              formatedTimeEnd.format("DD.MM.YY")}
        </p>
        <p>
          {formatedTimeStart.format("HH:mm")}-{formatedTimeEnd.format("HH:mm")}
        </p>
      </div>
      <div className="program-popup__text">
        <p>
          {program.title}
          {program.isWebinar && ", Вебинар"}
        </p>
        <small>({program.isLobby ? "Ресепшн стенд" : `Стенд: #${program.position}`})</small>
      </div>
      <div className="program-popup__buttons">
        {onEditClick && <button onClick={onEditClick}>Редактировать</button>}
        <button
          onClick={onMoveClick}
          className="program-popup__go-btn"
          disabled={eventStatusType !== "actual"}
        >
          Перейти
        </button>
      </div>
    </li>
  );
};

export default ProgramCard;
