import cn from "classnames";
import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import { vimeoPreview, youtubePreview } from "../../api";
import { avatarPlus, CloseSvg, fileLogo } from "../../assets";
import {
  cloneObject,
  extractVideoPath,
  validateVimeoLink,
  validateYoutubeLink,
} from "../../utils";
import { getCropedFile } from "../../utils/crop/getCropedFile";
import { errorHandler } from "../../utils/errorHandler";
import { errorMessage } from "../../utils/errorMessage";
import { ModelDropField } from "../stand/3dmodel";

const MAX_DESC_LENGTH = 140;

const DEFAULT_SETTINGS = {
  type: "",
  link: "",
  path: "",
  desc: "",
  image: "",
  thumb: "",
  crop: { crop: null, canvas: null },
  fileInfo: {},
};

const PopupWithTabs = ({
  title,
  validFileExtensions,
  typeWarnMsg,
  file,
  image,
  desc,
  onSave,
  onClose,
  banner = null,
  hasPreview = false,
  hasDesc = false,
  hideTabs = false,
}) => {
  let initialTab = "file";
  let bannerSettings = {};
  const mediaType = banner?.mediatype;

  if (banner) {
    if (mediaType === "youtube" || mediaType === "vimeo") {
      initialTab = mediaType;
    }

    if (mediaType === "youtube" || mediaType === "vimeo" || mediaType === "video") {
      bannerSettings = {
        type: mediaType,
        link: banner.path,
        path: "",
        desc: "",
        image: banner.src,
        thumb: "",
        crop: { crop: null, canvas: null },
        fileInfo: {},
      };
    }
  }

  const [isLoading, setIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [tab, setTab] = useState(initialTab);
  const [tabSettings, setTabSettings] = useState({
    file: cloneObject(mediaType === "video" ? bannerSettings : DEFAULT_SETTINGS),
    youtube: cloneObject(mediaType === "youtube" ? bannerSettings : DEFAULT_SETTINGS),
    vimeo: cloneObject(mediaType === "vimeo" ? bannerSettings : DEFAULT_SETTINGS),
  });

  const setTabSetting = (k, val, t = null) => {
    if (!t) {
      t = tab;
    }
    setTabSettings((prevState) => {
      return {
        ...prevState,
        [t]: {
          ...prevState[t],
          [k]: val,
        },
      };
    });
  };

  const getTabSetting = (k, t = null) => {
    if (!t) {
      t = tab;
    }
    return tabSettings[t][k];
  };

  const onClickTab = (t) => {
    setTab(t);
  };

  useEffect(() => {
    if (file) {
      let block = "file";

      if (!hideTabs) {
        if (validateYoutubeLink(file)) {
          block = "youtube";
          onClickTab(block);
          setTabSetting("type", "youtube", block);
        } else if (validateVimeoLink(file)) {
          block = "vimeo";
          onClickTab(block);
          setTabSetting("type", "vimeo", block);
        }
        let param = extractVideoPath(file);
        if (param) {
          setTabSetting("path", param, block);
        }
      }

      setTabSetting("desc", desc, block);
      setTabSetting("link", file, block);
      setTabSetting("image", image, block);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, desc, image, hideTabs]);

  const onValidateInput = (oInput) => {
    if (oInput.type === "file") {
      var sFileName = oInput.value;
      if (sFileName.length > 0) {
        var blnValid = false;
        for (var j = 0; j < validFileExtensions.length; j++) {
          var sCurExtension = validFileExtensions[j];
          if (
            sFileName
              .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() === sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }

        if (!blnValid) {
          immediateToast("error", {
            message:
              "Формат файла некорректный, доступные форматы: " +
              validFileExtensions.join(", "),
            position: "topCenter",
          });
          oInput.value = "";
          return false;
        }
      }
    }
    return true;
  };

  const onFileChange = async (e) => {
    try {
      if (onValidateInput(e.target)) {
        const file = e.target.files[0];
        setCurrentFile(file);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const onLoad = async () => {
    setIsLoading(true);

    const cropInfo = getTabSetting("crop");
    const crop = cropInfo.crop;
    const canvas = cropInfo.canvas;

    let cropFile;

    try {
      if (crop && canvas) {
        cropFile = await getCropedFile(cropInfo);
      }

      const params = {
        file: getTabSetting("fileInfo")?.file || "",
        thumb: cropFile || "",
        link: getTabSetting("link") || "",
        name: getTabSetting("path") || "",
        desc: getTabSetting("desc") || "",
        fileType: getTabSetting("type") || "",
      };

      await onSave(params);

      setIsLoading(false);

      onClose();
    } catch (error) {
      errorHandler(error);
      setTabSetting("image", "");
      setTabSetting("fileInfo", {});
    }
  };

  const inProcessVideo = (type) => {
    if (!link) {
      errorMessage(`Ссылка на ${type} ресурс не найдена`);
      return "";
    }
    let param = extractVideoPath(getTabSetting("link"));
    setTabSetting("path", param);
    (async () => {
      try {
        if (type === "youtube") {
          const { data } = await youtubePreview(param);
          if (data.success) {
            setTabSetting("thumb", data.data.thumb);
            setTabSetting("type", "youtube");
            setTabSetting("image", "");
          }
        } else if (type === "vimeo") {
          const { data } = await vimeoPreview(param);
          if (data.success) {
            setTabSetting("thumb", data.data.thumb);
            setTabSetting("type", "vimeo");
            setTabSetting("image", "");
          }
        }
      } catch (e) {
        errorHandler(e);
      }
    })();
    return "";
  };

  const cropInfo = getTabSetting("crop");
  const loadedDesc = getTabSetting("desc");
  const loadedImage = getTabSetting("image");
  const loadedThumb = getTabSetting("thumb");
  const link = getTabSetting("link");
  const hasCrop =
    cropInfo.crop &&
    cropInfo.canvas &&
    parseInt(cropInfo.crop?.width) > 0 &&
    parseInt(cropInfo.crop?.height) > 0;
  const hasImage = !!loadedImage;

  const isLoadButtonEnabled = hasCrop || hasImage;

  const dropField = () => {
    const fieldComponent = (
      <>
        <ModelDropField
          imageFromServer={loadedImage}
          loadedThumb={loadedThumb}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setCropInfo={(e) => {
            setTabSetting("crop", e);
          }}
          setFileType={(e) => {
            setTabSetting("type", e);
          }}
          banner={banner || {}}
          setLoadedFileInfo={(e) => {
            setTabSetting("fileInfo", e);
          }}
          allowedFilesType={validFileExtensions}
          withInsertFullCheckbox
        />
      </>
    );

    return (
      <div>
        {!!loadedImage ? (
          tab === "youtube" || tab === "vimeo" ? (
            <div
              style={{
                backgroundColor: "#ddd",
                display: "flex",
                marginBottom: 20,
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                <img height="200" src={image} alt="" />
              </div>
            </div>
          ) : (
            fieldComponent
          )
        ) : hasPreview ? (
          fieldComponent
        ) : (
          <div className="model-popup__file">
            <input
              onChange={onFileChange}
              type="file"
              className="model-popup__file-input"
            />

            {currentFile && (
              <div className="model-popup__wrap-file-img">
                <img src={fileLogo} alt="" className="model-popup__file-img" />
              </div>
            )}

            <div className="model-popup__file-main">
              <img src={avatarPlus} alt="" className="model-popup__file-add-img" />
              <div className="model-popup__file-text">
                <h3>Загрузить файл</h3>
                <span>Или перетащите файл сюда</span>
              </div>
            </div>
          </div>
        )}
        <div className="model-popup__posttitle">
          <h3>Используйте только {typeWarnMsg}</h3>
          <h3>Максимальный размер: 300 Мб</h3>
        </div>
      </div>
    );
  };

  return (
    <div className="model-popup">
      <div className="model-popup__bg"></div>
      <div className="model-popup__main">
        <div className="model-popup__header">
          <h3>{title}</h3>
          <button onClick={onClose}>
            <img src={CloseSvg} alt="#" />
          </button>
        </div>

        <div className="popup-tabs">
          {!hideTabs && (
            <div className="popup-tabs__headers">
              <button
                data-tab="file"
                onClick={() => {
                  onClickTab("file");
                }}
                className={
                  "popup-tabs__header " +
                  (tab === "file" ? "popup-tabs__header--active" : "")
                }
              >
                Выбрать файл
              </button>
              <button
                data-tab="youtube"
                onClick={() => {
                  onClickTab("youtube");
                }}
                className={
                  "popup-tabs__header " +
                  (tab === "youtube" ? "popup-tabs__header--active" : "")
                }
              >
                Видео с YouTube
              </button>
              <button
                data-tab="vimeo"
                onClick={() => {
                  onClickTab("vimeo");
                }}
                className={
                  "popup-tabs__header " +
                  (tab === "vimeo" ? "popup-tabs__header--active" : "")
                }
              >
                Видео с Vimeo
              </button>
            </div>
          )}
          <div
            className={cn("popup-tabs__tabs", {
              "popup-tabs__tabs--no-border": hideTabs,
            })}
          >
            <div
              data-tab="file"
              className={
                "popup-tabs__tab " + (tab === "file" ? "popup-tabs__tab--active" : "")
              }
            >
              {dropField()}
            </div>
            <div
              data-tab="youtube"
              className={
                "popup-tabs__tab " + (tab === "youtube" ? "popup-tabs__tab--active" : "")
              }
            >
              {(!!loadedImage || !!loadedThumb) && dropField()}
              <div className="video-frame">
                <input
                  style={{ width: "100%" }}
                  placeholder={`Вставьте ссылку с youtube`}
                  type="text"
                  className={cn(
                    "default-input video-frame__input model-popup__description-input",
                    {
                      "default-input--error": !validateYoutubeLink(link),
                    }
                  )}
                  value={link || ""}
                  onChange={(e) => setTabSetting("link", e.target.value)}
                />
                {validateYoutubeLink(link) && link?.length > 0 && (
                  <button
                    onClick={() => {
                      inProcessVideo("youtube");
                    }}
                    className="btn video-frame__btn btn-sm"
                  >
                    Обработать
                  </button>
                )}
                {!validateYoutubeLink(link) && (
                  <span className="input-error">
                    Некорректная ссылка. Пример:
                    https://www.youtube.com/watch?v=QpBDWK-BMuc
                  </span>
                )}
              </div>
            </div>
            <div
              data-tab="vimeo"
              className={
                "popup-tabs__tab " + (tab === "vimeo" ? "popup-tabs__tab--active" : "")
              }
            >
              {(!!loadedImage || !!loadedThumb) && dropField()}
              <div className="video-frame">
                <input
                  style={{ width: "100%" }}
                  placeholder={`Вставьте ссылку с vimeo`}
                  type="text"
                  className={cn(
                    "default-input video-frame__input model-popup__description-input",
                    {
                      "default-input--error": !validateVimeoLink(link),
                    }
                  )}
                  value={link || ""}
                  onChange={(e) => setTabSetting("link", e.target.value)}
                />
                {validateVimeoLink(link) && link?.length > 0 && (
                  <button
                    onClick={() => {
                      inProcessVideo("vimeo");
                    }}
                    className="btn video-frame__btn btn-sm"
                  >
                    Обработать
                  </button>
                )}
                {!validateVimeoLink(link) && (
                  <span className="input-error">
                    Некорректная ссылка. Пример: https://vimeo.com/259858577
                  </span>
                )}
              </div>
            </div>
          </div>

          {hasDesc ? (
            <div>
              <input
                style={{ width: "100%" }}
                placeholder={`Описание: ${MAX_DESC_LENGTH} символов`}
                type="text"
                className={cn("default-input model-popup__description-input", {
                  "default-input--error": loadedDesc?.length >= MAX_DESC_LENGTH,
                })}
                value={loadedDesc || ""}
                onChange={(e) => setTabSetting("desc", e.target.value)}
              />
              {loadedDesc?.length >= MAX_DESC_LENGTH && (
                <span className="input-error">
                  Максимальная длина описания {MAX_DESC_LENGTH} символов
                </span>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="model-popup__buttons">
          <button className="white-btn" onClick={onClose}>
            Отмена
          </button>
          <button disabled={!isLoadButtonEnabled} onClick={onLoad} className="red-btn">
            Загрузить
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupWithTabs;
