import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getDocuments, uploadDocumentScan } from "../../api/documents";
import { pdfIcon, searchIcon } from "../../assets";
import { Layout } from "../../components/shared";
import { NavStand } from "../../components/stand";
import { DocumentsPopup } from "../../components/stand/documents";
import { downloadPdf } from "../../utils/downloadPdf";
import { successMessage } from "../../utils/successMessage";
import { Hint } from "../../components/organizer-panel";

const convertServiceType = (type) => {
  if (type === "bill") {
    return "Счет";
  }

  if (type === "act") {
    return "Акт";
  }

  if (type === "contract") {
    return "Договор";
  }

  return "Договор";
};

const DocumentFlow = () => {
  const [uploadPopupOpened, setUploadPopupOpened] = useState(false);

  const { type, id } = useParams();

  const [allDocuments, setAllDocuments] = useState([]);

  const [filteredDocuments, setFilteredDocuments] = useState(allDocuments);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const { data } = await getDocuments({ type, id });
        const documentsData = data?.data?.data;

        if (!documentsData) {
          return;
        }

        setAllDocuments(
          documentsData.map((doc) => {
            const scans = [doc.file];

            if (doc.scans) {
              scans.push(...doc.scans);
            }

            return {
              id: doc.id,
              date: doc.created_at,
              number: String(doc.number),
              serviceType: convertServiceType(doc.type),
              partner: "-",
              service: doc.service,
              price: doc.price,
              files: scans,
            };
          })
        );
      } catch (error) {}
    };

    fetchDocuments();
  }, [id, type]);

  useEffect(() => {
    setFilteredDocuments(allDocuments);
  }, [allDocuments]);

  const [filterValue, setFilterValue] = useState("");

  const onFilterChange = (newFilterValue) => {
    if (newFilterValue.trim() === "") {
      setFilteredDocuments(allDocuments);
    } else {
      setFilteredDocuments(
        allDocuments.filter(
          (doc) => doc.number.slice(0, newFilterValue.length) === newFilterValue
        )
      );
    }

    setFilterValue(newFilterValue);
  };

  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  const onCloseClick = () => {
    setUploadPopupOpened(false);
    setSelectedDocumentId(null);
  };

  const [isFileSending, setIsFileSending] = useState(false);

  const onLoadClick = async ({ file }) => {
    try {
      setIsFileSending(true);
      const { data } = await uploadDocumentScan({ file, documentId: selectedDocumentId });

      setAllDocuments((prev) =>
        [...prev].map((doc) =>
          doc.id === selectedDocumentId
            ? {
                ...doc,
                files: [doc.files[0], data.data],
              }
            : doc
        )
      );

      successMessage("Файл успешно загружен");
      setIsFileSending(false);

      onCloseClick();
    } catch (error) {
      setIsFileSending(false);
    } finally {
      setIsFileSending(false);
    }
  };

  const onFileClick = ({ fileUrl }) => {
    downloadPdf(fileUrl, "document.pdf");
  };

  const isPageDisabled = false;

  return (
    <Layout>
      <div className="organizer-panel__main">
        {uploadPopupOpened && (
          <DocumentsPopup
            onClose={onCloseClick}
            onLoadClick={onLoadClick}
            isFileSending={isFileSending}
          />
        )}
        <NavStand />
        <div className="stand-settings">
          <div className="stand-documents__header">
            <div className="stand-settings__about-info">
              <h2 className="stand-documents__title">Документооборот</h2>
            </div>
            <div className="stand-documents__search-input">
              <input
                value={filterValue}
                onChange={(e) => onFilterChange(e.target.value)}
                placeholder="Введите номер документа"
                type="text"
              />
              <img src={searchIcon} alt="" />
            </div>
          </div>
          {isPageDisabled && (
            <Hint hintText="Для редактирования заполните обязательные предыдущие разделы" />
          )}
          <main className="documents table-responsive">
            <table className="table table-bordered">
              <thead className="table__head">
                <tr>
                  <th scope="col">Дата</th>
                  <th scope="col">Номер документа</th>
                  <th scope="col">Тип документа</th>
                  <th scope="col">Услуга</th>
                  <th scope="col">Стоимость, руб</th>
                  <th scope="col">Файлы</th>
                </tr>
              </thead>
              <tbody className="table__body">
                {filteredDocuments?.map((item) => (
                  <tr key={item.id}>
                    <td>{item.date && moment(item.date).format("DD MMM YYYY")}</td>
                    <td>{item.number}</td>
                    <td>{item.serviceType}</td>
                    <td>{item.service}</td>
                    <td>{item.price}</td>
                    <td className="documents__cell documents__cell--files">
                      <div className="documents__files-wrapper">
                        {item.files?.map((file, ind) => (
                          <img
                            onClick={() => onFileClick({ fileUrl: file })}
                            key={ind}
                            src={pdfIcon}
                            alt=""
                          />
                        ))}
                      </div>
                      <button
                        onClick={() => {
                          setUploadPopupOpened(true);
                          setSelectedDocumentId(item.id);
                        }}
                        className="red-btn documents__upload-btn"
                      >
                        Загрузить скан
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </main>
        </div>
      </div>
    </Layout>
  );
};

export default DocumentFlow;
