import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchEvent } from "../../../redux/eventReducer";
import { ButtonLoader } from "../../shared";

const SaveButton = ({ ...props }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const mounted = useRef(false);
  const isMounted = mounted.current;

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <button
      type="button"
      {...props}
      style={{ width: 150 }}
      onClick={async () => {
        try {
          if (props.onClick) {
            setIsLoading(true);
            await props.onClick();

            if (isMounted) setIsLoading(false);

            dispatch(fetchEvent({ eventId: id }));
          }
        } catch (error) {
          if (isMounted) setIsLoading(false);
          console.log(error);
        }
      }}
      className="red-btn"
    >
      {isLoading ? <ButtonLoader /> : "Сохранить"}
    </button>
  );
};

export default SaveButton;
