export const rgbToCmyk = (r, g, b, normalized) => {
  let c = 1 - r / 255;
  let m = 1 - g / 255;
  let y = 1 - b / 255;
  let k = Math.min(c, Math.min(m, y));

  c = (c - k) / (1 - k);
  m = (m - k) / (1 - k);
  y = (y - k) / (1 - k);

  if (!normalized) {
    c = Math.round(c * 10000) / 100;
    m = Math.round(m * 10000) / 100;
    y = Math.round(y * 10000) / 100;
    k = Math.round(k * 10000) / 100;
  }

  c = isNaN(c) ? 0 : Math.round(c);
  m = isNaN(m) ? 0 : Math.round(m);
  y = isNaN(y) ? 0 : Math.round(y);
  k = isNaN(k) ? 0 : Math.round(k);

  return {
    c,
    m,
    y,
    k,
  };
};

export const cmykToRgb = (c, m, y, k, normalized) => {
  c = c / 100;
  m = m / 100;
  y = y / 100;
  k = k / 100;

  c = c * (1 - k) + k;
  m = m * (1 - k) + k;
  y = y * (1 - k) + k;

  let r = 1 - c;
  let g = 1 - m;
  let b = 1 - y;

  if (!normalized) {
    r = Math.round(255 * r);
    g = Math.round(255 * g);
    b = Math.round(255 * b);
  }

  return {
    r: r,
    g: g,
    b: b,
  };
};
