import { getToken, instance } from ".";

export const uploadFile = ({ standId, eventId, file }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append('file', file);

  let id = eventId;
  let modelType = "event";
  if(standId){
    id = standId;
    modelType = "stand";
  }

  const url = `/api/v1/${modelType}/${id}/landing/upload`;

  return instance.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getLanding = ({ standId, eventId, slug }) => {
  const token = getToken();

  let urlParams = new URLSearchParams(window.location.search);

  let url;

  if(slug){
    url = `/api/v1/landing/${slug}`;
  }else{
    let id = eventId;
    let modelType = "event";
    if(standId){
      id = standId;
      modelType = "stand";
    }

    url = `/api/v1/${modelType}/${id}/landing`;
  }

  if(urlParams.get('inv')){
    url += `?inv=${urlParams.get('inv')}`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const storeLanding = ({ standId, eventId, data, title, isDraft }) => {
  const token = getToken();

  let id = eventId;
  let modelType = "event";
  if(standId){
    id = standId;
    modelType = "stand";
  }

  const url = `/api/v1/${modelType}/${id}/landing`;

  return instance.post(url, { data, title, is_draft: isDraft }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const fixCount = ({ id, type }) => {
  const token = getToken();

  return instance.get(`/api/v1/landing/${id}/count/${type}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
