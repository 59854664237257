import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";
import { createWebNotCreated, promotionArrow, siteIsCreated } from "../../../assets";

const CreatedWebInfo = ({ url }) => {
  const [isBlockVisible, setIsBlockVisible] = useState(true);

  const role = useSelector((state) => state.user.profile.role);

  const [linkCopied, setLinkCopied] = useState(false);

  const onCopyLink = () => {
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 3000);
  };

  let type;
  if (role === "участник") {
    type = "stand";
  }

  if (role === "организатор") {
    type = "event";
  }

  return (
    <div className="create-promotion__item created-web-info">
      <div onClick={() => setIsBlockVisible((prev) => !prev)} className="web-header">
        <h5 className="web-header__title">Доступ</h5>
        <div className="web-header__button">
          <img src={promotionArrow} alt="Button icon" />
        </div>
      </div>
      {isBlockVisible && (
        <div className="web-item">
          <div className="promotion__buttons">
            <div className="promotion-create">
              <div className="promotion-create__img">
                <img src={url ? siteIsCreated : createWebNotCreated} alt="#" />
              </div>
              <div className="promotion-create__main">
                <div>
                  <h5 className="promotion-create__title">
                    {url ? "Ваш веб сайт уже создан" : "Создайте веб сайт"}
                  </h5>
                  <p className="promotion-create__text">
                    {url
                      ? `Адрес - ${url}`
                      : `Генерация landing page на основе информации о ${
                          type === "stand" ? "стенде" : "мероприятии"
                        }`}
                  </p>
                </div>
                {url && (
                  <>
                    {!linkCopied && (
                      <CopyToClipboard text={url} onCopy={onCopyLink}>
                        <span className="promotion-create__text isCreated promotion-create__copy">
                          Скопировать ссылку
                        </span>
                      </CopyToClipboard>
                    )}
                    {linkCopied && (
                      <span className="promotion-create__text isCreated isCopied">
                        <img src={siteIsCreated} alt="Copied" /> Ссылка скопирована в
                        буфер
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreatedWebInfo;
