import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedCard: {
    id: "",
    description: "",
    startDate: "",
    startTime: "",
    endTime: "",
  },
  items: [],
  // actual, moderation, draft, denied
  type: "actual",
  stats: {
    actual: 0,
    moderation: 0,
    draft: 0,
    denied: 0,
  },
};

export const programSlice = createSlice({
  name: "eventSettings",
  initialState,
  reducers: {
    setProgramEventsType(state, { payload }) {
      state.type = payload;
    },

    addProgramEventOnModeration(state, { payload }) {
      state.stats.moderation += 1;

      if (state.type === "moderation") {
        state.items.push(payload);
      }
    },

    addProgramEventInDrafts(state, { payload }) {
      state.stats.draft += 1;

      if (state.type === "draft") {
        state.items.push(payload);
      }
    },

    selectCard(state, { payload }) {
      state.selectedCard = payload;
    },

    clearSelectedCard(state) {
      state.selectedCard = initialState.selectedCard;
    },

    editCardById(state, { payload }) {
      state.items = state.items.map((c) =>
        c.id === payload.id ? { ...c, ...payload.card } : { ...c }
      );
    },

    moveCardFromDraftsToModeration(state, { payload }) {
      state.items = state.items.filter((c) => c.id !== payload);

      state.stats.draft -= 1;
      state.stats.moderation += 1;
    },
  },
});

export const {
  setProgramEventsType,
  addProgramEventOnModeration,
  addProgramEventInDrafts,
  selectCard,
  clearSelectedCard,
  editCardById,
  moveCardFromDraftsToModeration,
} = programSlice.actions;
export default programSlice.reducer;
