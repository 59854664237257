import React, { useEffect, useRef, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Button } from "../../shared";
import {
  createMailingTemplate,
  deleteMailingTemplate,
  getDefaultTemplate,
  updateMailingTemplate,
} from "../../../api/mailing";
import EmailEditor from "react-email-editor";
import { editIcon, copyIcon, deleteIcon, addTemplateIcon } from "../../../assets";
import ReactTooltip from "react-tooltip";
import PopupMailing from "../../shared/PopupMailing";
import { useDispatch, useSelector } from "react-redux";
import {
  pushNewMailingTemplate,
  fetchMailingTemplates,
  fetchMailings,
} from "../../../redux/mailingReducer";
import { immediateToast } from "izitoast-react";

const defaultMailingTemplate = {
  id: "new",
  name: "Новый шаблон",
  template_json: getDefaultTemplate(),
  template_html: "",
};

const createLink = (type, id) => {
  return `/${type}/${id}/settings/mailing/templates`;
};

const MailingTemplates = () => {
  const { type, id, mailingTemplateId } = useParams();
  const mailingTemplates = useSelector((state) => state.mailing.mailingTemplates);
  const history = useHistory();

  const openCreateMailingTemplate = () => {
    history.push(`${createLink(type, id)}/new`);
  };

  return (
    <div className="mailing-templates">
      <nav className="mailing-templates-sidebar">
        {mailingTemplates.map((template) => (
          <NavLink
            to={`${createLink(type, id)}/${template.id}`}
            activeClassName="active"
            key={template.id}
          >
            <div className="mailing-templates-sidebar__item">
              <div className="mailing-templates-sidebar__name">{template.name}</div>
            </div>
          </NavLink>
        ))}
        <Button className="newTemplate" onClick={openCreateMailingTemplate}>
          <img src={addTemplateIcon} alt="" />
          Добавить шаблон
        </Button>
      </nav>
      {mailingTemplateId && <MailingTemplateView />}
    </div>
  );
};

const MailingTemplateView = () => {
  const { type, id, mailingTemplateId } = useParams();
  const [isModalShowed, setIsModalShowed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const mailingTemplate = useSelector((state) =>
    // eslint-disable-next-line eqeqeq
    state.mailing.mailingTemplates.find((template) => template.id == mailingTemplateId)
  );
  const [mailingTemplateName, setMailingTemplateName] = useState(mailingTemplate?.name);

  useEffect(() => {
    setMailingTemplateName(mailingTemplate?.name);
  }, [mailingTemplate]);

  const emailEditorRef = useRef(null);

  const enableEditing = () => {
    setIsEditing(true);
  };

  const saveTemplate = () => {
    let template_json = "";
    let template_html = "";
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      template_html = html;
      template_json = design;

      let promise;

      if (mailingTemplateId === "new") {
        promise = createMailingTemplate({
          type,
          id,
          mailing_template: { name: mailingTemplateName, template_json, template_html },
        });
      } else {
        promise = updateMailingTemplate({
          type,
          id,
          mailingTemplateId,
          mailing_template: { name: mailingTemplateName, template_json, template_html },
        });
      }

      promise.then(({ data }) => {
        setIsEditing(false);
        dispatch(fetchMailingTemplates({ type, id }));

        const shouldRedirect = mailingTemplateId === "new";

        if (shouldRedirect) {
          history.push(`${createLink(type, id)}/${data.data.id}`);
        }
      });
    });
  };

  const copyTemplate = () => {
    dispatch(pushNewMailingTemplate({ ...mailingTemplate, id: "new" }));
    history.push(`${createLink(type, id)}/new`);
  };

  const deleteTemplate = () => {
    deleteMailingTemplate({ type, id, templateId: mailingTemplateId }).then(
      ({ data }) => {
        console.log(data);
        dispatch(fetchMailingTemplates({ type, id }));
        history.push(createLink(type, id));
      }
    );
  };

  const onReady = () => {
    // editor is ready
    console.log("onReady");
    setTimeout(() => {
      if (emailEditorRef?.current) {
        emailEditorRef.current.editor.loadDesign(mailingTemplate.template_json);
      }
    }, 1000);
  };

  const openModal = () => {
    setIsModalShowed(true);
  };

  useEffect(() => {
    if (mailingTemplateId === "new" && mailingTemplate === undefined) {
      dispatch(pushNewMailingTemplate(defaultMailingTemplate));
    }

    if (mailingTemplateId === "new") {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [setIsEditing, dispatch, mailingTemplateId, mailingTemplate]);

  useEffect(() => {
    if (emailEditorRef?.current?.editor !== undefined && mailingTemplate !== undefined) {
      emailEditorRef.current.editor.loadDesign(mailingTemplate.template_json);
    }
  }, [emailEditorRef, mailingTemplate]);

  const renderCopyButton = () => (
    <>
      <ReactTooltip place="bottom" type="dark" id="copy-tooltip" effect="solid">
        Копировать шаблон
      </ReactTooltip>
      <span data-tip className="mailingTooltip" data-for="copy-tooltip">
        <Button className="mailing-edit-btn" onClick={copyTemplate}>
          <img src={copyIcon} alt="" />{" "}
        </Button>
      </span>
    </>
  );

  const renderEditButton = () => (
    <>
      <ReactTooltip place="bottom" type="dark" id="edit-tooltip" effect="solid">
        Редактировать шаблон
      </ReactTooltip>
      <span data-tip className="mailingTooltip" data-for="edit-tooltip">
        <Button className="mailing-edit-btn" onClick={enableEditing}>
          <img src={editIcon} alt="" />{" "}
        </Button>
      </span>
    </>
  );

  const renderDeleteButton = () => (
    <>
      <ReactTooltip place="bottom" type="dark" id="delete-tooltip" effect="solid">
        Удалить шаблон
      </ReactTooltip>
      <span data-tip className="mailingTooltip" data-for="delete-tooltip">
        <Button className="mailing-edit-btn" onClick={deleteTemplate}>
          <img src={deleteIcon} alt="" />{" "}
        </Button>
      </span>
    </>
  );

  const renderSaveButton = () => (
    <Button className="mailing-template-action" onClick={saveTemplate}>
      Сохранить
    </Button>
  );

  const renderSendButton = () => (
    <Button className="mailing-template-action--send" onClick={openModal}>
      Отправить
    </Button>
  );
  const renderTemplateName = () => {
    if (!isEditing) {
      return <>{mailingTemplate.name}</>;
    } else {
      return (
        <input
          className="input__main"
          type="text"
          value={mailingTemplateName || ""}
          onChange={(e) => setMailingTemplateName(e.target.value)}
        />
      );
    }
  };
  const renderButtons = () => {
    if (isEditing) {
      return <>{renderSaveButton()}</>;
    } else {
      return (
        <>
          {renderCopyButton()}
          {mailingTemplate?.is_system ? null : renderEditButton()}
          {mailingTemplate?.is_system ? null : renderDeleteButton()}
          {renderSendButton()}
        </>
      );
    }
  };

  const onMailingSent = () => {
    setIsModalShowed(false);
    immediateToast("success", {
      message: "Рассылка успешно отправлена.",
      position: "topCenter",
    });
    dispatch(fetchMailings({ type, id }));
  };

  return (
    <>
      {isModalShowed && (
        <PopupMailing
          title="Отправить рассылку"
          onClose={() => setIsModalShowed(false)}
          type={type}
          id={id}
          templateId={mailingTemplateId}
          onSent={onMailingSent}
          typeWarnMsg='txt или xls файл, где адреса разделены знаком ";"'
        />
      )}

      {mailingTemplate === undefined ? null : (
        <div className="mailing-templates-redactor">
          <div className="mailing-templates-redactor-head">
            <div className="mailing-templates-redactor-head__title">
              {renderTemplateName()}
            </div>
            <div className="mailing-templates-redactor-head__actions">
              {renderButtons()}
            </div>
          </div>
          <div className="mailing-templates-redactor-working-area">
            <div
              className="mailing-templates-redactor-working-area__iframe"
              style={{ pointerEvents: isEditing ? "auto" : "none" }}
            >
              <EmailEditor
                options={{ displayMode: "email", locale: "ru-RU" }}
                ref={emailEditorRef}
                onReady={onReady}
              />
            </div>
            {/*<div className="mailing-templates-redactor-working-area__iframe" style={{display: !isEditing ? 'flex' : 'none'}}>*/}
            {/*  <iframe srcDoc={mailingTemplate.template_html}></iframe>*/}
            {/*</div>*/}
          </div>
        </div>
      )}
    </>
  );
};

export default MailingTemplates;
