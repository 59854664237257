export const Validator = (params) => {
  return {
    form() {
      let valid = true;
      let msgs = {};
      for (let k in params) {
        let v = params[k];
        if (this[k]) {
          let resp = this[k](v);
          if (resp) {
            valid = false;
            msgs[k] = resp;
          }
        }
      }
      return { msgs, valid };
    },

    description(value) {
      if (value && value.length > 10 && value.length <= 255) {
        return null;
      }

      if (!value) {
        return "Это обязательное поле";
      }

      if (value.length < 10) {
        return "Минимальная длина 10 символов";
      }

      if (value.length > 254) {
        return "Максимальная длина 255 символов";
      }

      return null;
    },

    title(value) {
      if (!value) {
        return "Это обязательное поле";
      }

      if (value.length > 140) {
        return "Максимальная длина 140 символов";
      }

      return null;
    },

    startDay(value) {
      if (!value) {
        return "Это обязательное поле";
      }
    },

    startTime(value) {
      if (!value) {
        return "Это обязательное поле";
      }
    },

    endDay(value) {
      if (!value) {
        return "Это обязательное поле";
      }
    },

    endTime(value) {
      if (!value) {
        return "Это обязательное поле";
      }
    },
  };
};
