import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { getOccupations } from "../../../api/member";
import { AlertCircle, avatarPlus, popupClose } from "../../../assets";
import { setCreateStandInfo } from "../../../redux/appReducer";
import { Select } from "../../shared";
import { MAX_STAND_NAME_LENGTH } from "./Sidebar";
import { errorMessage } from "../../../utils/errorMessage";
import { getImageSize } from "../../../utils/getImageSize";
import {
  generateTextErrorMinResolution,
  generateTextErrorMaxResolution,
  generateTextErrorMaxSize,
  generateTextAboutAvailableResolution,
} from "../../../utils/generateTextMessage";

const ThirdStep = () => {
  const createStandInfo = useSelector((state) => state.app.createStandInfo);

  const errors = useSelector((state) => state.app.createStandInfo.errors);

  const [branches, setBranches] = useState([]);
  const companyName = createStandInfo.companyName;
  const branch = createStandInfo.branch;
  const shortDescription = createStandInfo.shortDescription;
  const logo = createStandInfo.logo;

  const MaxImageSizeMb = 25;
  const MinImageResolution = { width: 1136, height: 640 };
  const MaxImageResolution = { width: 1920, height: 1080 };
  const TextAboutAllowedResolution = `Разрешение: ${generateTextAboutAvailableResolution(
    MinImageResolution,
    MaxImageResolution
  )}`;

  const dispatch = useDispatch();

  const onCompanyChange = (e) => {
    dispatch(setCreateStandInfo({ companyName: e.target.value }));
  };

  const onBranchChange = (branch) => {
    dispatch(setCreateStandInfo({ branch }));
  };

  const onShortDescChange = (e) => {
    dispatch(setCreateStandInfo({ shortDescription: e.target.value }));
  };

  const onLogoChange = async (e) => {
    const logo = e.target.files[0];

    if (!logo) {
      return;
    }

    // check size
    if (logo.size > MaxImageSizeMb * 1024 * 1024) {
      return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
    }

    // check resolution
    const { width, height } = await getImageSize(logo);
    if (!width && !height) {
      return;
    }
    if (width < MinImageResolution.width || height < MinImageResolution.height) {
      return errorMessage(
        generateTextErrorMinResolution(
          MinImageResolution.width,
          MinImageResolution.height
        )
      );
    }
    if (width > MaxImageResolution.width || height > MaxImageResolution.height) {
      return errorMessage(
        generateTextErrorMaxResolution(
          MaxImageResolution.width,
          MaxImageResolution.height
        )
      );
    }

    window.targetStandLogo = logo;
    const localImageUrl = window.URL.createObjectURL(logo);
    dispatch(setCreateStandInfo({ logo: localImageUrl }));
  };

  const onLogoDelete = () => {
    window.targetStandLogo = null;
    dispatch(setCreateStandInfo({ logo: "" }));
  };

  useEffect(() => {
    const fetchOccupations = async () => {
      try {
        const { data } = await getOccupations();
        setBranches(data.data.map((b) => ({ value: b.id, label: b.name })));
      } catch (error) {}
    };
    fetchOccupations();
  }, []);

  return (
    <>
      <div className="create-stand-content__posttitle">Общее описание</div>
      <div className="create-stand-content__main">
        <div className="create-stand-content__form">
          <div className="input input--create-stand">
            <label>Название стенда*</label>
            <input
              className={classNames(
                "input__main",
                {
                  error:
                    companyName.length > 0 &&
                    (companyName.length < 5 ||
                      companyName.length > MAX_STAND_NAME_LENGTH),
                },
                { filled: companyName.length >= 5 }
              )}
              value={companyName}
              onChange={onCompanyChange}
              type="text"
              placeholder="Введите название стенда"
            />
            {companyName.length > 0 && companyName.length < 5 && (
              <p className="input__text">
                Название стенда не может содержать меньше 5 символов
              </p>
            )}
            {companyName.length > MAX_STAND_NAME_LENGTH && (
              <p className="input__text">
                Название стенда не может содержать больше {MAX_STAND_NAME_LENGTH} символов
              </p>
            )}
          </div>
          <div className="input input--create-stand">
            <div className="create-stand-content__input-alert">
              <label>Отрасль*</label>
              <div className="create-stand-content__input-icon">
                <img src={AlertCircle} alt="#" />
                <div className="create-stand-content__input-promt branch">
                  Выберите отрасль предприятия
                </div>
              </div>
            </div>
            <div className="create-stand-content__input-wrapper">
              <Select
                options={branches}
                value={branch}
                onChange={onBranchChange}
                placeholder="Выберите вид деятельности"
              />
            </div>
          </div>
          <div className="input input--create-stand">
            <div className="create-stand-content__input-alert">
              <label>Краткое описание*</label>
              <div className="create-stand-content__input-icon">
                <img src={AlertCircle} alt="#" />
                <div className="create-stand-content__input-promt description">
                  Краткое описание
                </div>
              </div>
            </div>
            <textarea
              className={classNames(
                "input__main",
                {
                  error: shortDescription.length > 0 && shortDescription.length < 10,
                },
                { filled: shortDescription.length >= 10 }
              )}
              value={shortDescription}
              onChange={onShortDescChange}
              placeholder="Краткое описание стенда"
            />
            {shortDescription.length > 0 && shortDescription.length < 10 ? (
              <p className="input__text">
                Краткое описание не может содержать меньше 10 символов
              </p>
            ) : null}
          </div>
        </div>
        <div className="create-stand-content__sidebar">
          <div className="create-stand-content__avatar">
            <div
              className={classNames("create-stand-content__avatar-input", {
                "create-stand-content__avatar-input--red": !logo,
              })}
            >
              {logo && (
                <button
                  onClick={onLogoDelete}
                  className="create-stand-content__avatar-delete"
                >
                  <img src={popupClose} alt="Del" />
                </button>
              )}
              <input
                id="standInputLogo"
                onChange={onLogoChange}
                type="file"
                className="create-stand-content__avatar-file"
                accept="image/png, image/jpeg, image/jpg, image/svg+xml"
              />
              {logo && (
                <img src={logo} className="create-stand-content__avatar-image" alt="" />
              )}
              {!logo && (
                <div className="create-stand-content__add-avatar-text">
                  <img
                    src={avatarPlus}
                    alt=""
                    className="create-stand-content__add-avatar-add-img"
                  />
                  <h3>Загрузить логотип</h3>
                  <span>Или перетащите файл сюда</span>
                </div>
              )}
            </div>
            <div className="create-stand-content__avatar-posttitle">
              <div>Используйте только .jpg, .png типы файлов</div>
              <div>Максимальный размер: {MaxImageSizeMb} Мб</div>
              <div>{TextAboutAllowedResolution}</div>
            </div>
            {errors && <span className="create-stand-content__error">{errors.logo}</span>}
          </div>
        </div>
      </div>
    </>
  );
};

export default ThirdStep;
