import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { JuridicalForm } from "../../../components/events";
import { setCreateEventInfo } from "../../../redux/appReducer";
import { EventHeader } from "./";

const SixthStep = ({ disabled }) => {
  const dispatch = useDispatch();
  const createEventInfo = useSelector((state) => state.app.createEventInfo);

  const withVAT = createEventInfo.withVAT;

  const onVATChange = () => {
    dispatch(setCreateEventInfo({ withVAT: !withVAT }));
  };

  return (
    <div className="create-stand__content create-stand-content">
      <EventHeader
        text={"Юридическая информация"}
        warningText="В случае не отправления заявки, мероприятие будет удалено через 10 дней."
      />
      <div className="create-stand-content__main">
        <JuridicalForm
          getFieldValue={(fieldName) => createEventInfo[fieldName]}
          onChange={({ fieldName, value }) => {
            dispatch(setCreateEventInfo({ [fieldName]: value }));
          }}
          withVAT={withVAT}
          onVATChange={onVATChange}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SixthStep;
