import React from "react";
import { Error } from "../../components/errors";

const Error404 = () => {
  return (
    <Error
      title="Похоже, такой страницы нет"
      text="Возможно, вы воспользовались недействительной ссылкой или эта страница была удалена"
    />
  );
};

export default Error404;
