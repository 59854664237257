export const getFileFromUrl = async ({ url, defaultType = "image/jpeg" }) => {
  try {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], url, {
      type: data.type || defaultType,
    });
  } catch (error) {
    console.log(error);
  }
};
