import ru from "date-fns/locale/ru";
import moment from "moment";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { getGlobal, getGlobalStats, getToken, getUser } from "./api";
import { getActualEvents } from "./api/events";
import { createNotification, getNotifications } from "./api/notifications";
import {
  Login,
  RegistrationChooseRole,
  RegistrationConfirm,
  RegistrationFinal,
  RegistrationStart,
  ResetPassword,
  ResetPasswordConfirm,
  ResetPasswordFinallly,
  ResetPasswordForm,
} from "./pages/entry";
import { Error404 } from "./pages/errors";
import {
  CreateStand,
  EventCalendar,
  EventList,
  Moderation,
  ModerationList,
  RegistrationEvent,
} from "./pages/events";
import {
  FavoriteActual,
  FavoriteFilling,
  FavoritePast,
  FavoriteRequests,
} from "./pages/favorite";
import { LandingPage } from "./pages/landing";
import { OrganizerPanel } from "./pages/organizer-panel";

import {
  Analytics,
  Contacts,
  CreateAd,
  CreateWeb,
  CreateWebOrg,
  DemoSite,
  DemoSiteEvent,
  DocumentFlow,
  GalleryPage,
  Mailing,
  Model,
  ParticipantCard,
  Participants,
  Program,
  Promotion,
  ResumePage,
  TariffsAds,
  TariffsStands,
  Webinar,
} from "./pages/stand";
import {
  BankMember,
  Documents,
  OrganizationMember,
  Password,
  Personal,
} from "./pages/profile";
import { VREventPage } from "./pages/vr-event";
import { setSettings } from "./redux/appReducer";
import { fetchContacts, setMessages } from "./redux/chatReducer";
import {
  setActiveEventIn3D,
  setActiveStandIn3D,
  setCreateRegisterOnEvent,
  setCreateStandOnEvent,
} from "./redux/eventReducer";
import { setNotifications } from "./redux/notificationsReducer";
import { setAccounts, setProfileInfo, updateRole } from "./redux/userReducer";
import { alertHandler } from "./utils/alertHandler";
import { errorHandler } from "./utils/errorHandler";
import { ScopeRoute } from "./utils/ScopeRoute";
import useOnceEffect from "./utils/useOnceEffect";

const routeRole = "/entry/registration/continue";
const routeCreating = "/entry/registration/create";

const redirectOnLanding = (history) => {
  const path = window.location.pathname;

  if (path.match(/^\/event-.*/)) {
    history.push("/event-site");
  } else if (path.match(/^\/stand-.*/)) {
    history.push("/stand-site");
  }
};

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const path = history.location.pathname;
  const dispatch = useDispatch();
  // статус загрузки данных юзера с сервера
  const [isLoading, setIsLoading] = useState(true);
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user.profile);
  const activeStandIn3D = useSelector((state) => state.events.activeStandIn3D);
  const activeEventIn3D = useSelector((state) => state.events.activeEventIn3D);
  const globalParams = useRef({});
  const parsedQuery = queryString.parse(location.search);

  useEffect(() => {
    if (!parsedQuery.location) {
      dispatch(setActiveEventIn3D(0));
      dispatch(setActiveStandIn3D(0));
    }
  }, [dispatch, parsedQuery]);

  useEffect(() => {
    if (activeStandIn3D) {
      globalParams.current.stat = `stand_visit|${activeEventIn3D}-${activeStandIn3D}`;
    } else if (activeEventIn3D) {
      globalParams.current.stat = `event_visit|${activeEventIn3D}`;
    } else {
      delete globalParams.current.stat;
    }
  }, [activeStandIn3D, activeEventIn3D]);

  useEffect(() => {
    redirectOnLanding(history);
  }, [history]);

  // redirect user if registration not completed
  useEffect(() => {
    if (path === routeRole || path === routeCreating) return;

    if (user.step === "choose-role" && path !== routeRole) {
      history.push(routeRole);
    }

    if (user.step === "create-account" && path !== routeCreating) {
      history.push(routeCreating);
    }
  }, [history, path, user.step]);

  useEffect(() => {
    alertHandler(location);
  }, [location, location.search]);

  useEffect(() => {
    if (user.id) {
      dispatch(fetchContacts());
    }
  }, [dispatch, user.id]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    let eventId;
    if (params.get("createStandOnEvent")) {
      eventId = params.get("createStandOnEvent");
      dispatch(updateRole(20));
      dispatch(setCreateStandOnEvent(eventId));
      if (getToken() && !token) {
        return history.push(`/events/create/${eventId}?currentStep=1`);
      }
    }

    if (params.get("createRegisterOnEvent")) {
      eventId = params.get("createRegisterOnEvent");
      dispatch(updateRole(10));
      dispatch(setCreateRegisterOnEvent(eventId));
      if (getToken() && !token) {
        return history.push(`/events/registration?id=${eventId}`);
      }
    }
    dispatch(setAccounts());
    (async () => {
      try {
        const response = await getUser(token);
        if (!response) {
          return setIsLoading(false);
        }

        const data = response.data;
        const userInfo = data.data.user;

        if (userInfo) {
          if (token) {
            userInfo.token = token;
          }
          dispatch(setProfileInfo(userInfo));
        }
      } catch (error) {
        errorHandler(error);
      }
      setIsLoading(false);
    })().then();

    if (eventId && !token && !getToken()) {
      history.push("/entry/registration");
    }
  }, [dispatch, history, app.profile, location.search]);

  useOnceEffect(() => {
    const apiGlobal = async (isOpener = false) => {
      let _settings;
      try {
        const { data } = await getGlobal(null);
        // const userInfo = data.data.user;
        const { chats, settings } = data.data;
        _settings = settings;
        if (chats) {
          for (let chatId in chats) {
            dispatch(
              setMessages({
                id: chatId,
                messages: chats[chatId],
              })
            );
          }
        }
        if (settings) {
          if (isOpener) {
            window.ym(settings.ym_counter, "init", {
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
              trackHash: true,
              ecommerce: "dataLayer",
            });
            dispatch(
              setSettings({
                ymCounter: settings.ym_counter,
                playerUrl: process.env.REACT_APP_PLAYER_URL || settings.player_url,
                roomViewerUrl:
                  process.env.REACT_APP_ROOMVIEWER_URL || settings.roomviewer_url,
              })
            );
          }
        }
      } catch (error) {
        console.error(error);
      }

      setTimeout(() => apiGlobal(!_settings), 3000);
    };

    const apiGlobalStats = async () => {
      const params = globalParams.current;
      try {
        await getGlobalStats(null, params);
      } catch (error) {
        console.error(error);
      }

      setTimeout(apiGlobalStats, 3000);
    };

    apiGlobal(true);
    apiGlobalStats();
  }, true);

  setDefaultLocale(ru);

  // dont call in useEffectOnce (there is no actual user.id)
  useEffect(() => {
    const getNotificationsItems = async () => {
      try {
        const { data } = await getNotifications({ userId: user.id });

        if (!data || data.length === 0) return;

        dispatch(
          setNotifications(
            data
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((n) => ({
                text: n.text,
                date: n.created_at,
                id: n.id,
                url: n.action ? n.action.replace("/#", "") : "",
              }))
          )
        );
      } catch (error) {}
    };

    const getStartedEvents = async () => {
      try {
        const { data } = await getActualEvents();
        const events = data.data;

        events.forEach(async (ev) => {
          if (new Date(ev.start_date) <= moment(new Date()).add(10, "minutes").toDate()) {
            await createNotification({
              userId: user.id,
              templateId: 9,
              data: [ev.name],
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    };

    const notificationsStats = async () => {
      if (!user.id) return;

      await getStartedEvents();
      await getNotificationsItems();
    };

    notificationsStats();

    const iId = setInterval(notificationsStats, 1 * 60 * 1000);

    return () => {
      clearInterval(iId);
    };
  }, [dispatch, user.id]);

  const BeforeAuth = () => {
    const routes = [
      { path: "/", component: LandingPage },
      { path: "/entry/registration", component: RegistrationStart },
      { path: "/entry/registration/confirm", component: RegistrationConfirm },
      { path: "/entry/login", component: Login },
      { path: "/entry/reset", component: ResetPassword },
      { path: "/entry/reset/confirm", component: ResetPasswordConfirm },
      { path: "/entry/reset/continue", component: ResetPasswordForm },
      { path: "/entry/reset/finally", component: ResetPasswordFinallly },
    ];

    return [
      ...routes.map((route) => {
        if (!route.params) {
          route.params = {};
        }
        return (
          <ScopeRoute
            key={route.path}
            {...route.params}
            isActive={!user.isActive}
            redirect="/events/list"
            exact
            path={route.path}
            component={route.component}
          />
        );
      }),
    ];
  };

  const AfterAuth = () => {
    const routes = [
      { path: routeRole, component: RegistrationChooseRole },
      { path: routeCreating, component: RegistrationFinal },

      { path: "/profile/personal", component: Personal },
      { path: "/profile/documents", component: Documents },
      { path: "/profile/password", component: Password },

      { path: "/events/list", component: EventList },
      { path: "/events/calendar", component: EventCalendar },
      { path: "/events/registration", component: RegistrationEvent },
      { path: "/events/create/:id", component: CreateStand },
      { path: "/events/:id", component: VREventPage },

      { path: "/favorite/actual", component: FavoriteActual },
      { path: "/favorite/past", component: FavoritePast },
      { path: "/favorite/requests", component: FavoriteRequests },
      { path: "/favorite/filling", component: FavoriteFilling },
      { path: "/profile/organization", component: OrganizationMember },
      { path: "/profile/bank", component: BankMember },

      { path: "/:type/:id/settings/contacts/:viewType", component: Contacts },
      { path: "/stand/:id/settings/create-web", component: CreateWeb },
      { path: "/stand/:id/settings/create-web/demo", component: DemoSite },

      { path: "/stand/:id/site", component: DemoSite },

      {
        path: "/:type/:id/settings/participant/:memberId",
        component: ParticipantCard,
      },

      { path: "/:type/:id/settings/participants/:link", component: Participants },

      { path: "/:type/:id/settings/mailing", component: Mailing },
      { path: "/:type/:id/settings/mailing/:tabType", component: Mailing },
      {
        path: "/:type/:id/settings/mailing/:tabType/:mailingTemplateId",
        component: Mailing,
      },

      { path: "/:type/:id/settings/promotion", component: Promotion },
      { path: "/:type/:id/settings/promotion/create-ad", component: CreateAd },
      { path: "/:type/:id/settings/promotion/create-ad/:bannerId", component: CreateAd },
      { path: "/event/:id/settings/create-web", component: CreateWebOrg },
      { path: "/event/:id/settings/resume", component: ResumePage },

      { path: "/event/:id/settings/create-web/demo", component: DemoSiteEvent },

      { path: "/:type/:id/settings/document-flow", component: DocumentFlow },

      { path: "/:type/:id/settings/analytics", component: Analytics },

      // {
      //   path: "/event/:id/settings/notification/participants",
      //   component: NotificationOrgParticipants,
      // },
      { path: "/:type/:id/settings/webinar", component: Webinar },
      { path: "/:type/:id/settings/model", component: Model },
      { path: "/event/:id/settings/moderation/:mType/list", component: ModerationList },
      { path: "/event/:id/settings/moderation/:mType/:mId", component: Moderation },
      { path: "/event/:id/site", component: DemoSiteEvent },
      { path: "/event/:id/settings/gallery", component: GalleryPage },
      {
        path: "/event/:id/settings/tariffs/stands",
        component: TariffsStands,
      },
      {
        path: "/event/:id/settings/tariffs/ads",
        component: TariffsAds,
      },
      {
        path: "/:type/:id/settings/program/:viewType",
        component: Program,
      },
      {
        path: "/organizer-panel/:pageContent/",
        component: OrganizerPanel,
      },
      {
        path: "/organizer-panel/:pageContent/:id",
        component: OrganizerPanel,
      },
      // {
      //   path: "/stand/:id/settings/program",
      //   component: ProgramParticipant,
      // },
    ];

    // const allRoutePaths = routes.map(route => route.path);

    return [
      ...routes.map((route) => {
        if (!route.params) {
          route.params = {};
        }
        // if(!("exact" in route.params)){
        //   route.params.exact = true;
        // }
        return (
          <ScopeRoute
            key={route.path}
            {...route.params}
            isActive={user.isActive}
            redirect="/entry/login"
            exact
            path={route.path}
            component={route.component}
          />
        );
      }),
    ];
  };

  return isLoading ? (
    <div>{""}</div>
  ) : (
    <Switch>
      {/* type = event or stand */}
      <ScopeRoute
        key="/stand-site"
        isActive={true}
        redirect="/events/list"
        exact
        path="/stand-site"
        component={DemoSite}
      />
      <ScopeRoute
        key="/event-site"
        isActive={true}
        redirect="/events/list"
        exact
        path="/event-site"
        component={DemoSiteEvent}
      />
      {BeforeAuth()}

      {AfterAuth()}

      <Route component={Error404} />
    </Switch>
  );
};

export default App;
