import React from "react";
import { Element } from "react-scroll";
import { defaultSpeakerPhoto } from "../../../../assets";
import cn from "classnames";
import { clearString } from "../../../../utils";

const DemoEventMeeting = ({ speakers, colorTitle, colorText }) => {
  return (
    speakers?.length > 0 && (
      <Element name="Спикеры" className="demo-event__meeting">
        <div className="demo__container">
          <h5 className="demo-event__meeting-title" style={{ color: colorTitle }}>
            Знакомьтесь и общайтесь с реальными людьми
          </h5>
          {speakers && (
            <div className="demo-event__meeting-main">
              {speakers.map((speaker, index) =>
                speaker.image || speaker.fullName ? (
                  <div key={index} className="demo-event__meeting-item">
                    <div
                      className={cn("demo-event__meeting-avatar", {
                        "demo-event__meeting-avatar--no-image": !speaker.image,
                      })}
                    >
                      <img src={speaker.image || defaultSpeakerPhoto} alt="#" />
                    </div>
                    <div className="demo-event__meeting-name">
                      {clearString(speaker.fullName)}
                    </div>
                    <div className="demo-event__meeting-pos" style={{ color: colorText }}>
                      {clearString(speaker.pos)}
                    </div>
                  </div>
                ) : null
              )}
            </div>
          )}
        </div>
      </Element>
    )
  );
};

export default DemoEventMeeting;
