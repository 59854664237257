import React from "react";
import { Element } from "react-scroll";
import {
  landingAbilitiesSideImage,
  panelItemAds,
  panelItemAnalytics,
  panelItemGeography,
  panelItemKpi,
  panelItemPlan,
  panelItemReporting,
  panelItemSafety,
  panelItemStatistics,
  panelItemVariat,
  panelItemVisual,
} from "../../assets";
import { LandingRegistrationLink } from "./";

const items = [
  {
    title: "Безопасность",
    paragraphs: [
      "На платформе JEP все ваши операции и данные защищены и недоступны третьим лицам.",
      "Безопасность - один из главных принципов JEP.",
    ],
    icon: panelItemSafety,
  },
  {
    title: "Визуализация",
    paragraphs: [
      "3D графика и интерактивное лобби создадут ощущение реального присутствия на выставке. Каждый стенд ваших экспонентов выглядит уникально благодаря возможностям брендинга, таким же, как на мероприятии офлайн. Яркое и разнообразное позиционирование экспонентов повышает вовлеченность посетителе и обеспечивает возможность получения максимальной конверсии.",
    ],
    icon: panelItemVisual,
  },
  {
    title: "Вариативность",
    paragraphs: [
      "Разнообразие шаблонов открывает массу возможностей персонализации выставки и стендов. Вы создаете свое мероприятие таким, каким Вы его запланировали, мы лишь обеспечиваем Вас необходимыми инструментами для получения наилучших результатов.",
    ],
    icon: panelItemVariat,
  },
  {
    title: "Планирование",
    paragraphs: [
      "С легкостью планируйте общедоступные и закрытые презентации, показы и вебинары вашего мероприятия, добавляя данные в календарь. Настраивайте доступ к календарю для участников выставки и посетителей. Удобный календарь также позволяет участникам настроить и запланировать свою индивидуальную программу, запомнить и запланировать мероприятия, которые хочет посетить, а также создать календарь событий, которые организует сам.",
    ],
    icon: panelItemPlan,
  },
  {
    title: "Продвижение и реклама",
    paragraphs: [
      "С момента положительной модерации, мероприятие продвигается платформой путём размещения в общем календаре событий. Интуитивная панель выбора и настройки рекламных площадей позволяет организатору эффективно продвигать выставку и запланированные мероприятия. Участникам доступен широкий выбор форматов продвижения, рассчитанный на разные категории экспонентов.",
    ],
    icon: panelItemAds,
  },
  {
    title: "Отчетность",
    paragraphs: [
      "Формирование итоговой отчётности теперь задача JEP. Все действия внутри платформы автоматически группируются в отчётные документы. В один клик вы сможете выгрузить закрывающие документы по итогу проведения выставки, конвертировать файлы в удобный формат, объединить счета в единый отчёт.",
    ],
    icon: panelItemReporting,
  },
  {
    title: "Статистика",
    paragraphs: [
      "Ключевое отличие JEP — комплексный учет данных и их доступность в реальном времени. Кол-во посещений, профиль посетителя, детальная история действий гостей, логика взаимодействия с участниками B2B и B2C, включая покупательскую активность позволят вам повысить эффективность управления мероприятием и достичь намеченных целей. ",
    ],
    icon: panelItemStatistics,
  },

  {
    title: "KPI",
    paragraphs: [
      "Онлайн формат мероприятия дает непревзойденные возможности оценки ключевых показателей эффективности (KPI). Благодаря данным, собранным во время проведения выставки, Вы сможете настроить персонализированный отчет и автоматически вычислить такие показатели, как конверсия (CR), индекс лояльности (NPS), окупаемость инвестиций (ROI), кликабельность (CTR) и другие.",
    ],
    icon: panelItemKpi,
  },
  {
    title: "Аналитика",
    paragraphs: [
      "По завершении мероприятия вы получаете детальный анализ действий участников и посетителей. Обработанные нами данные позволят Вам лучше понимать действия и потребности клиентов, выявить своего потребителя и сфокусироваться на действиях, которые дадут наибольшую конверсию в будущем. Совершенствуйтесь вместе с JEP!",
    ],
    icon: panelItemAnalytics,
  },
  {
    title: "География и тайминг",
    paragraphs: [
      "Выход бизнеса в онлайн стирает географические границы. Теперь вы не ограничены местом проведения выставки. ",
      "Приглашайте участников из разных стран и расширяйте свой бизнес. Проводите выставку 24 часа в сутки, значительно повышая конверсию и итоговый результат.",
    ],
    icon: panelItemGeography,
  },
];

const PlatformAbilities = () => {
  return (
    <Element name="landing-platform-abiities" className="landing-platform-abiities">
      <div className="landing__block-title">КЛЮЧЕВЫЕ ЦИФРОВЫЕ ВОЗМОЖНОСТИ</div>
      <div className="landing__block-post-title">Онлайн открывает новые перспективы</div>
      <div className="landing-platform-abiities__main">
        <div className="landing-platform-abiities__cards">
          {items.map((card, ind) => (
            <div
              key={ind}
              className="landing-platform-abiities__card platform-abiities-card"
            >
              <div className="platform-abiities-card__header">
                <h2>{card.title}</h2>
                <img src={card.icon} alt="" />
              </div>
              <div className="platform-abiities-card__text">
                {card.paragraphs.map((p, index) => (
                  <span key={index}>{p}</span>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="landing-platform-abiities__side-image">
        <img src={landingAbilitiesSideImage} alt="" />
        <div className="landing-platform-abiities__image-bg"></div>
      </div>
      <LandingRegistrationLink className="landing-platform-abiities__registration" />
    </Element>
  );
};

export default PlatformAbilities;
