import cn from "classnames";
import { immediateToast } from "izitoast-react";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { changeStandField, setStandMedia } from "../../../api/member";
import { setEventMedia } from "../../../api/organizer";
import { CloseSvg } from "../../../assets";
import {
  fetchStandMedia,
  setCurrentStandLogo,
  setCurrentStandLogoLink,
  setFileToMedia,
} from "../../../redux/eventReducer";
import { validateURLMatch } from "../../../utils";
import { getCropedFile } from "../../../utils/crop/getCropedFile";
import { errorHandler } from "../../../utils/errorHandler";
import PopupWithTabs from "../../shared/PopupWithTabs";
import { ModelDropField } from "./";

const Popup = ({ banner, onClose }) => {
  const { id, type } = useParams();
  const dispatch = useDispatch();

  const [loadedImage, setLoadedImage] = useState("");
  const [loadedFileInfo, setLoadedFileInfo] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [cropInfo, setCropInfo] = useState({ crop: null, canvas: null, imageExt: "png" });

  const [title, setTitle] = useState("");

  const [allowedFilesType, setAllowedFilesType] = useState("*");

  const [standLogoLink, setStandLogoLink] = useState("");

  const MinImageResolution = useMemo(() => {
    if (banner.styles?.width < banner.styles?.height) {
      return { width: 758, height: 1080 };
    }

    return { width: 1136, height: 640 };
  }, [banner.styles?.height, banner.styles?.width]);

  const MaxImageResolution = useMemo(() => {
    if (banner.styles?.width < banner.styles?.height) {
      return { width: 1515, height: 2160 };
    }

    return { width: 1920, height: 1080 };
  }, [banner.styles?.height, banner.styles?.width]);

  useEffect(() => {
    switch (banner.type) {
      case "banner": {
        setLoadedImage(banner.src || "");
        setTitle("Добавить рекламное изображение");
        setAllowedFilesType("image/png, image/jpeg, image/jpg");
        break;
      }

      case "logo": {
        setLoadedImage(banner.src || "");
        setTitle("Добавить логотип");
        setAllowedFilesType("image/png, image/jpeg, image/jpg");
        break;
      }

      case "image": {
        setLoadedImage(banner.src || "");
        setTitle("Добавить изображение");
        setAllowedFilesType("image/png, image/jpeg, image/jpg");
        break;
      }

      case "pdf":
      case "presentation": {
        setLoadedImage(banner.src || "");
        setTitle("Добавить pdf");
        setAllowedFilesType("application/pdf");
        break;
      }

      case "video": {
        setLoadedImage(banner.src || "");
        setTitle("Добавить видео");
        setAllowedFilesType("video/mp4, video/mov, video/avi");
        break;
      }

      default: {
        setTitle("Добавить файл");
        break;
      }
    }
  }, [banner]);

  // set logo link from server
  useEffect(() => {
    setStandLogoLink(banner.logoLink);
  }, [banner.logoLink]);

  const fileType = banner.type;
  const rect = banner.rect;

  const onLoad = async () => {
    setIsLoading(true);

    const crop = cropInfo.crop;
    const canvas = cropInfo.canvas;

    // upload stand logo link
    if (
      type === "stand" &&
      banner.additionalType === "logo" &&
      standLogoLink !== banner.logoLink
    ) {
      const { data } = await changeStandField({
        standId: id,
        fieldName: "logo_link",
        value: standLogoLink,
      });
      // set new value on current stand
      if (data?.logo_link) {
        dispatch(setCurrentStandLogoLink(data?.logo_link));
      }
      // stop if no file
      if (!(crop && canvas)) {
        setIsLoading(false);
        onClose();
      }
    }

    try {
      let cropFile;
      if (crop && canvas) {
        cropFile = await getCropedFile(cropInfo);
      } else {
        setIsLoading(false);
        return;
      }

      const params = {
        file: loadedFileInfo.file,
        mediaType: fileType,
        thumb: cropFile,
        rect,
      };

      let url;
      const bannerIsLogo = banner.additionalType === "logo";
      if (type === "stand" && bannerIsLogo) {
        const { data } = await changeStandField({
          standId: id,
          fieldName: "logo",
          value: cropFile,
        });

        url = data.logo;
      } else if (type === "stand") {
        params.standId = id;
        const { data } = await setStandMedia(params);
        url = data.data.preview;
      } else if (type === "event") {
        params.eventId = id;
        const { data } = await setEventMedia(params);
        url = data.data.preview;
      }

      if (bannerIsLogo) {
        dispatch(setCurrentStandLogo(url));
      } else {
        dispatch(
          setFileToMedia({
            rect: banner.rect,
            img: url,
            mediatype: banner.mediatype,
          })
        );
      }

      dispatch(fetchStandMedia({ [`${type}Id`]: id }));

      immediateToast("success", {
        message: "Настройки успешно обновлены",
        position: "topCenter",
      });

      setIsLoading(false);

      onClose();
    } catch (error) {
      errorHandler(error);
      setLoadedImage(null);
      setLoadedFileInfo({});
      setIsLoading(false);
    }
  };

  const isLoadButtonDisabled =
    !cropInfo.crop ||
    !cropInfo.canvas ||
    cropInfo.crop?.width === 0 ||
    cropInfo.crop?.height === 0;
  const isLoadButtonEnabledOnLogoLinkChange =
    banner.additionalType === "logo" &&
    type === "stand" &&
    standLogoLink !== banner.logoLink;
  const isLogoLinkValidated =
    banner.additionalType === "logo" && type === "stand"
      ? validateURLMatch(standLogoLink)
      : true;

  if (fileType === "video") {
    const onVideoLoad = async (videoInfo) => {
      try {
        const params = {
          file: videoInfo.file,
          mediaType: videoInfo.fileType,
          thumb: videoInfo.thumb,
          rect,
        };

        if (videoInfo.link) {
          params.link = videoInfo.link;
          params.name = videoInfo.name;
        }

        let url;
        if (type === "stand") {
          params.standId = id;
          const { data } = await setStandMedia(params);
          url = data.data.preview;
        } else if (type === "event") {
          params.eventId = id;
          const { data } = await setEventMedia(params);
          url = data.data.preview;
        }

        dispatch(
          setFileToMedia({
            rect: banner.rect,
            img: url,
            mediatype: banner.mediatype,
          })
        );

        dispatch(fetchStandMedia({ [`${type}Id`]: id }));

        immediateToast("success", {
          message: "Настройки успешно обновлены",
          position: "topCenter",
        });
      } catch (error) {}
    };

    return (
      <PopupWithTabs
        image={banner.src}
        file={""}
        desc={""}
        setDesc={() => {}}
        hasDesc={false}
        title="Загрузить файл"
        hasPreview={true}
        validFileExtensions=".mp4, .mov, .avi"
        typeWarnMsg="файлы форматов: mp4, mov, avi"
        onClose={onClose}
        onSave={onVideoLoad}
        banner={banner}
      />
    );
  }

  const minSideSize = Math.min(...Object.values(MinImageResolution));
  const maxSideSize = Math.max(...Object.values(MaxImageResolution));

  return (
    <div className="model-popup">
      <div className="model-popup__bg"></div>
      <div className="model-popup__main">
        <div className="model-popup__header">
          <h3>{title}</h3>
          <button onClick={onClose}>
            <img src={CloseSvg} alt="#" />
          </button>
        </div>
        <ModelDropField
          imageFromServer={loadedImage}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setCropInfo={setCropInfo}
          banner={banner}
          setLoadedFileInfo={setLoadedFileInfo}
          allowedFilesType={allowedFilesType}
          minImageResolution={{ width: minSideSize, height: minSideSize }}
          maxImageResolution={{ width: maxSideSize, height: maxSideSize }}
          withInsertFullCheckbox
          withFileAnotation
        />

        {/* stand logo link input */}
        {banner.additionalType === "logo" && type === "stand" && (
          <div>
            <div style={{ marginTop: "10px" }}>Ссылка на сайт</div>
            <input
              style={{ width: "100%" }}
              placeholder={`Вставьте ссылку на сайт`}
              type="text"
              className={cn(
                "default-input video-frame__input model-popup__description-input",
                {
                  "default-input--error":
                    standLogoLink && !validateURLMatch(standLogoLink),
                }
              )}
              value={standLogoLink || ""}
              onChange={(e) => setStandLogoLink(e.target.value)}
            />
            {standLogoLink && !validateURLMatch(standLogoLink) && (
              <span className="input-error">
                Некорректная ссылка. Пример: https://justevents.online
              </span>
            )}
          </div>
        )}
        <div className="model-popup__buttons">
          <button className="white-btn" onClick={onClose}>
            Отмена
          </button>
          <button
            disabled={
              (isLoadButtonDisabled && !isLoadButtonEnabledOnLogoLinkChange) ||
              (isLoadButtonEnabledOnLogoLinkChange && !isLogoLinkValidated)
            }
            onClick={onLoad}
            className="red-btn"
          >
            Загрузить
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
