import React from "react";
import { Prompt } from "react-router";

const LeaveCreateWebHandler = ({ message }) => {
  return (
    <div style={{ width: 0, height: 0, position: "absolute" }}>
      <Prompt when={true} message={message} />
    </div>
  );
};

export default LeaveCreateWebHandler;
