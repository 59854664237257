import React from "react";
import { useSelector } from "react-redux";
import { EntryLogo } from "../../components/entry";
import { Form } from "../../components/entry/registration-final";

const RegistrationFinal = () => {
  const user = useSelector((state) => state.user?.profile) || {};

  return (
    <div className="registration-final">
      <EntryLogo />
      <div className="registration-final__main">
        <h3 className="registration-final__title">
          Создание аккаунта {user.role && `(${user.role})`}
        </h3>
        <Form />
      </div>
    </div>
  );
};

export default RegistrationFinal;
