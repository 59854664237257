import classNames from "classnames";
import React from "react";
import { DoneSvg } from "../../../assets";

const SidebarNavItem = ({ number, text, isActive, isDone }) => {
  return (
    <div
      className={classNames("create-stand-sidebar__nav-item", {
        "create-stand-sidebar__nav-item--active": isActive,
        "create-stand-sidebar__nav-item--done": isDone,
      })}
    >
      <span>{isDone ? <img src={DoneSvg} alt="#" /> : number}</span>
      <h3>{text}</h3>
    </div>
  );
};

export default SidebarNavItem;
