import { getToken, instance } from ".";

export const setGalleryFile = ({ eventId, file, thumb, link, name, logo, order, fileType }) => {
  const token = getToken();

  const formData = new FormData();
  if (order) formData.append("order", order);
  if (file) formData.append("file", file);
  if (link) formData.append("link", link);
  if (name) formData.append("name", name);
  if (thumb) formData.append("thumb", thumb);
  if (logo) formData.append("logo", logo);

  return instance.post(`/api/v1/event/${eventId}/gallery/${fileType}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setGalleryDesc = ({ eventId, desc, order }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("desc", desc);
  formData.append("order", order);

  return instance.post(`/api/v1/event/${eventId}/gallery/desc`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
