import Fuse from "fuse.js";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { exportContacts } from "../../api/chat";
import { getPreparedContacts } from "../../api/contacts";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { Layout } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import {
  ContactsCardsList,
  ContactsNav,
  ContactsPagination,
  ContactsPopup,
  ContactsSearch,
  ContactsTable,
} from "../../components/stand/contacts";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import { setStandContacts } from "../../redux/contactsReducer";

const ContactsList = () => {
  const { id, type, viewType } = useParams();
  const dispatch = useDispatch();
  const standContacts = useSelector((state) => state.contacts.standContacts);

  const profile = useSelector((state) => state.user.profile);

  const [filteredContacts, setFilteredContacts] = useState(standContacts);

  const [inputSearch, setInputSearch] = useState("");

  const options = {
    minMatchCharLength: 1,
    keys: ["name", "email", "mobile"],
  };

  const fuse = new Fuse(filteredContacts, options);
  const onSearch = (e) => {
    setInputSearch(e.target.value);
    setCurrentPage(1);

    e.target.value
      ? setFilteredContacts(fuse.search(e.target.value).map((event) => event.item))
      : setFilteredContacts(standContacts);
  };

  const [popup, setPopup] = useState(false);
  const [exportLink, setExportLink] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [countContacts, setCountContacts] = useState(0);
  const contactsPerPage = viewType === "list" ? 16 : 8;

  const indexOfLastTable = currentPage * contactsPerPage;
  const indexOfFirstTable = indexOfLastTable - contactsPerPage;

  const paginatedContacts = filteredContacts.slice(indexOfFirstTable, indexOfLastTable);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredContacts.length / contactsPerPage); i++) {
    pageNumbers.push(i);
  }

  useEffect(() => {
    setCountContacts(paginatedContacts?.length);
  }, [paginatedContacts?.length]);

  useEffect(() => {
    const fetchExportContacts = async () => {
      try {
        const { data } = await exportContacts({ [`${type}Id`]: id });
        const pdfUrl = data.data?.url;
        setExportLink(pdfUrl);
      } catch (error) {}
    };

    if (countContacts) {
      fetchExportContacts().then();
    }
  }, [countContacts, dispatch, setExportLink, type, id]);

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const fetchedContacts = await getPreparedContacts({
          id,
          type,
          selfId: profile?.id,
        });
        dispatch(setStandContacts(fetchedContacts));
      } catch (error) {
        console.log(error);
      }
    };

    fetchContacts();
  }, [dispatch, id, type, profile]);

  useEffect(() => {
    setFilteredContacts(standContacts);
  }, [standContacts]);

  useEffect(() => {
    setCurrentPage(1);
  }, [viewType]);

  const isPageDisabled = useCreateEventPageDisabled(pageNames.CONTACTS);

  return (
    <Layout>
      <div className="organizer-panel__main">
        {popup && <ContactsPopup setPopupFalse={() => setPopup(false)} />}
        <NavStand />
        <div className="stand-settings">
          <StandHeader
            title="Контакты посетителей"
            withHint={isPageDisabled}
            buttons={[
              exportLink ? (
                <a href={exportLink} target="_blank" rel="noreferrer" className="red-btn">
                  Экспорт посетителей
                </a>
              ) : null,
            ]}
          />

          <div className="stand-settings__contacts-header">
            <ContactsSearch inputSearch={inputSearch} onSearch={onSearch} />
            <ContactsNav />
          </div>
          {viewType === "list" && <ContactsTable contacts={paginatedContacts} />}
          {viewType === "cards" && <ContactsCardsList cards={paginatedContacts} />}
          <ContactsPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageNumbers={pageNumbers}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ContactsList;
