import { immediateToast } from "izitoast-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setEventPlan } from "../../../api/event";
import { Loader } from "../../../components/shared";
import {
  fetchEvent,
  fetchEventConstructor,
  setFillingPageName,
} from "../../../redux/eventReducer";
import { cloneObject } from "../../../utils";
import { errorHandler } from "../../../utils/errorHandler";
import { EventHeader } from "./";
import { pageNames } from "./EventSidebar";

const FourthStep = ({ disabled }) => {
  const { id: eventId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [frameIsLoaded, setFrameIsLoaded] = useState(false);
  // dont reload on eventId changes
  const [planLoadingStarted, setPlanLoadingStarted] = useState(false);

  const dispatch = useDispatch();

  const event = useSelector((state) => state.events.currentEvent);

  let constructorUrl =
    process.env.REACT_APP_CONSTRUCTOR_URL || `/constructor/constructor.html`;

  const iframeRef = useRef(null);

  const [, setIsPlanCreated] = useState(false);

  useEffect(() => {
    if (eventId && !planLoadingStarted) {
      setPlanLoadingStarted(true);
      // load event data for constructor
      dispatch(fetchEventConstructor({ eventId: eventId }));
    }
  }, [dispatch, eventId, planLoadingStarted]);

  useEffect(() => {
    const messageHandler = async ({ data }) => {
      try {
        if (data.type === "saveScheme") {
          setIsLoading(true);

          await setEventPlan({
            eventId,
            data: {
              stands: data.stands,
              plan: data.plan,
            },
          });

          dispatch(fetchEvent({ eventId }));

          dispatch(
            setFillingPageName({
              eventId,
              name: pageNames.DESCRIPTION,
            })
          );

          immediateToast("success", {
            message: "План успешно сохранен",
            position: "topCenter",
          });

          setIsPlanCreated(true);
          setIsLoading(false);
        } else if (data.type === "getScheme") {
          setFrameIsLoaded(true);
        }
      } catch (e) {
        errorHandler(e);
        setIsLoading(false);
      }
    };

    window.addEventListener("message", messageHandler);

    if (event?.position && event?.position?.length > 1) {
      let position = cloneObject(event.position);
      position.unshift(event.plan);
      iframeRef.current.contentWindow.postMessage(
        {
          type: "loadPlan",
          stands: position,
        },
        "*"
      );
    }

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [dispatch, event, eventId]);

  useEffect(() => {
    if (frameIsLoaded && event?.stands) {
      iframeRef.current.contentWindow.postMessage(
        {
          type: "loadScheme",
          containLobby: !!event.contain_lobby,
          tmpl: event.tmpl,
          plan: event.plan,
          stands: event.stands,
          rooms: event.rooms,
        },
        "*"
      );
    }
  }, [frameIsLoaded, iframeRef, event]);

  const onCreatePlan = () => {
    iframeRef.current.contentWindow.postMessage(
      {
        type: "saveScheme",
      },
      "*"
    );
  };

  const hidenIframeStyles = {
    position: "absolute",
    height: 1,
    width: 1,
    top: 0,
    left: 0,
  };

  return (
    <div className="create-stand__content create-stand-content create-stand-content--consctuctor-page">
      <EventHeader
        text="Конструктор плана павильона"
        onCreatePlan={onCreatePlan}
        btnsDisabled={disabled}
      />
      {isLoading && (
        <div className="create-event__constructor-loader-wrapper">
          <Loader className="create-event__constructor-loader" />
        </div>
      )}
      <iframe
        src={constructorUrl}
        className="create-event__constructor"
        frameBorder="0"
        title="event-constructor"
        ref={iframeRef}
        target="_parent"
        style={isLoading ? hidenIframeStyles : {}}
      ></iframe>
    </div>
  );
};

export default FourthStep;
