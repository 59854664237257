import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createEventChooseTemplate, getRoomTemplates } from "../../../api/organizer";
import { setCreateEventInfo } from "../../../redux/appReducer";
import { setFillingPageName } from "../../../redux/eventReducer";
import { errorHandler } from "../../../utils/errorHandler";
import { successMessage } from "../../../utils/successMessage";
import { EventHeader } from "./";
import { pageNames } from "./EventSidebar";
import SaveButton from "./SaveButton";

const ThirdStep = ({ disabled }) => {
  const { id: eventId } = useParams();

  const dispatch = useDispatch();
  const activeTemplateId = useSelector((state) => state.app.createEventInfo.templateId);
  const tarif = useSelector((state) => state.app.createEventInfo.tarif);
  const event = useSelector((state) => state.events.currentEvent);

  const onTemplateClick = (templateId) => {
    dispatch(setCreateEventInfo({ templateId }));
  };

  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        let type = tarif?.type || "default";
        // tarif может быть сброшен при переходе между страницами или сохранении
        // тарифа, тогда tarif.type будет undefined и type станет default
        if (!tarif?.type) type = event?.room_type;

        const { data } = await getRoomTemplates({ type });

        setTemplates(
          data.data.map((t, i) => ({
            id: t.id,
            preview_image: t.preview,
            name: `Шаблон зала ${i + 1}`,
            maximumStands: 210,
          }))
        );
      } catch (error) {
        console.log(error);
      }
    };
    fetchTemplates();
  }, [event?.room_type, tarif?.type]);

  const onSave = async () => {
    try {
      await createEventChooseTemplate({
        eventId,
        tmplId: activeTemplateId,
      });

      dispatch(
        setFillingPageName({
          eventId,
          name: pageNames.HALL_PLAN,
        })
      );

      successMessage("Зал успешно выбран.");
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllow = activeTemplateId && !disabled;

  return (
    <div className="create-stand__content create-stand-content">
      <EventHeader
        text={"Выбор зала"}
        SaveButton={<SaveButton disabled={!isAllow} onClick={onSave} />}
      />
      <div className="create-stand-content__posttitle create-stand-content__posttitle--second">
        Выберите шаблон для создания виртуального мероприятия
      </div>
      <div className="create-stand-content__main">
        <div className="create-stand-content__templates">
          {templates.map((t, i) => (
            <div
              key={i}
              onClick={() => {
                if (disabled) {
                  return;
                }

                onTemplateClick(t.id);
              }}
              className={classNames("create-stand-content__template", {
                "create-stand-content__template--selected": t.id === activeTemplateId,
              })}
            >
              <img src={t.preview_image} alt="#" />
              <span>
                {t.name}
                {t.maximumStands && (
                  <div className="create-stand-content__template-count">
                    1 - {t.maximumStands} стендов
                  </div>
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThirdStep;
