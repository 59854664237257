import React, { useState } from "react";
import { SidePopupChat as SidePopupChatSvg } from "../../assets/";
import Chat from "./Chat";
import classNames from "classnames";
import { useSelector } from "react-redux";

const SidePopupChat = () => {
  const [isChatOpened, setIsChatOpened] = useState(false);
  const unread = useSelector(state => state.chats.unread);

  const onOpenBtnClick = () => {
    setIsChatOpened(!isChatOpened);
  };

  return (
    <div 
      className={classNames("side-popup-chat", {
        "side-popup-chat--opened": isChatOpened,
      })}
    >
      <button onClick={onOpenBtnClick} className="side-popup-chat__button">
        { unread > 0 && <span className="side-popup-chat__unread">{ unread }</span> }
        <img src={SidePopupChatSvg} alt="#" />
      </button>
      <div className="side-popup-chat__chat">
        {isChatOpened && <Chat closePopup={() => setIsChatOpened(false)} />}
      </div>
    </div>
  );
};

export default SidePopupChat;
