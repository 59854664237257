import axios from "axios";
import { errorHandler } from "./errorHandler";

export const downloadPdf = async (url, fileName = "presentation.pdf") => {
  try {
    const response = await axios({
      url,
      method: "GET",
      responseType: "blob",
    });

    const dataFileUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = dataFileUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    errorHandler(error);
  }
};
