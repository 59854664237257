import React, { useState } from "react";
import ColorPopupFooter from "./ColorPopupFooter";
import cn from "classnames";
import { immediateToast } from "izitoast-react";
import { editStandColor } from "../../../api/stand";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateMaskColor } from "../../../redux/eventReducer";

export const getMaskImage = ({ templateId, mask }) => {
  return (
    (process.env.REACT_APP_BASE_URL || "") +
    `/3dplayer/stands/${templateId}/objects/` +
    mask +
    ".cropped.png"
  );
};

const getMaskPreviewPath = ({ templateId, preview }) => {
  return (
    (process.env.REACT_APP_BASE_URL || "") +
    `/3dplayer/stands/${templateId}/objects/` +
    preview
  );
};

const TexturePicker = ({ closePopup, textures = [], colorMask }) => {
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const templateId = useSelector((state) => state.events.currentStand)?.tmpl?.id;
  const [selectedTexture, setSelectedTexture] = useState({});

  const onConfirmClick = async () => {
    try {
      await editStandColor({
        objectName: colorMask.name,
        color: selectedTexture.mask,
        [`${type}Id`]: id,
      });

      dispatch(updateMaskColor({ id: colorMask.id, color: selectedTexture.mask }));

      immediateToast("success", {
        message: "Текстура успешно обновлена.",
        position: "topCenter",
      });

      closePopup();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="color-popup__main">
        <div className="color-popup__textures">
          {textures.map((t) => (
            <div
              key={t.mask}
              className={cn("color-popup__texture", {
                "color-popup__texture--selected": selectedTexture.mask === t.mask,
              })}
              onClick={() => setSelectedTexture(t)}
            >
              <img src={getMaskPreviewPath({ templateId, preview: t.preview })} alt="" />
            </div>
          ))}
        </div>
      </div>
      <ColorPopupFooter onCancelClick={closePopup} onConfirmClick={onConfirmClick} />
    </>
  );
};

export default TexturePicker;
