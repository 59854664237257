import React from "react";
import { Link } from "react-scroll";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { clearString } from "../../../../utils";

const DemoEventMain = ({
  title,
  subtitle,
  urls,
  bg,
  startDate,
  endDate,
  contactInfo,
  colorBackground,
  colorTitle,
  buttonColor,
  logo,
  participants,
  program,
  speakers,
  colorText,
  roomType,
}) => {
  const role = useSelector((state) => state.user.profile.role);
  let { id } = useParams();

  const buttonStyle = {
    background: colorTitle,
    color: colorTitle === "#ffffff" ? buttonColor : "#ffffff",
    border: `1px solid ${colorTitle === "#ffffff" ? buttonColor : "#ffffff"}`,
  };

  const reversedButtonStyle = {
    background: colorTitle === "#ffffff" ? buttonColor : "#ffffff",
    color: colorTitle,
    border: `1px solid ${colorTitle}`,
  };

  const history = useHistory();

  const onVisitClick = () => {
    if (urls?.visitLink) {
      window.location.replace(urls.visitLink);
    } else {
      history.push(`/events/registration?id=${id}`);
    }
  };

  const onParticipateClick = () => {
    if (urls?.participateLink) {
      window.location.replace(urls.participateLink);
    } else {
      if (role === "участник") {
        return history.push(`/events/create/${id}?currentStep=1`);
      }

      history.push(`/events/registration?id=${id}`);
    }
  };

  const showNavigation =
    participants?.length > 0 && program?.length > 0 && speakers?.length > 0;

  const isDatesYearSame = moment(startDate).year() === moment(endDate).year();
  const startDateFormat = isDatesYearSame ? "DD MMMM" : "DD MMMM YYYY";

  return (
    <div className="demo-event-main">
      <div className="demo-event-bg">{bg && <img src={bg} alt="Stand Poster" />}</div>
      <div className="demo__container">
        <div className="demo-event-content">
          <div className="demo-main__nav">
            {showNavigation && (
              <div className="demo-main__nav-main">
                {participants && participants.length > 0 && (
                  <Link
                    to={"Участники"}
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="demo-main__nav__item"
                    style={{ color: colorTitle }}
                  >
                    Участники
                  </Link>
                )}
                {program && program.length > 0 && (
                  <Link
                    to={"Программа"}
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="demo-main__nav__item"
                    style={{ color: colorTitle }}
                  >
                    Программа
                  </Link>
                )}
                {speakers && speakers.length > 0 && (
                  <Link
                    to={"Спикеры"}
                    spy={true}
                    smooth={true}
                    duration={500}
                    className="demo-main__nav__item"
                    style={{ color: colorTitle }}
                  >
                    Спикеры
                  </Link>
                )}
              </div>
            )}
            <div className="demo-main__nav-contact">
              <div
                style={{ color: colorTitle }}
                className="demo-main__nav__item contacts"
              >
                {clearString(contactInfo?.mobile)}
              </div>
            </div>
          </div>
          <div className="demo-event-content__main">
            <div className="demo-event-content__info">
              {startDate && endDate && (
                <div
                  style={{ color: colorText, border: `1px solid ${colorText}` }}
                  className="demo-event-content__date"
                >
                  {moment(startDate).format(startDateFormat)} –{" "}
                  {moment(endDate).format("DD MMMM YYYY")}
                </div>
              )}
              <div style={{ color: colorText }} className="demo-event-content__title">
                {clearString(title)}
              </div>
              <div style={{ color: colorText }} className="demo-event-content__subtitle">
                {clearString(subtitle)}
              </div>
              <div className="demo-event-content__buttons">
                {roomType !== "gallery" && (
                  <button
                    onClick={onParticipateClick}
                    className="btn demo-event-content__btn"
                    style={buttonStyle}
                  >
                    Участвовать
                  </button>
                )}
                <button
                  onClick={onVisitClick}
                  className="btn demo-event-content__btn"
                  style={reversedButtonStyle}
                >
                  Посетить
                </button>
              </div>
            </div>
            {logo && (
              <div className="demo-event-content__logo">
                <img src={logo} alt="#" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="demo-event-overlay" style={{ background: colorBackground }}></div>
    </div>
  );
};

export default DemoEventMain;
