import React from "react";

const ContactsSearch = ({ inputSearch, onSearch }) => {
  return (
    <div className="stand-settings__contacts-search">
      <form onSubmit={(e) => e.preventDefault()} action="#">
        <input
          value={inputSearch}
          onChange={onSearch}
          className="event-calendar__input"
          type="text"
          placeholder="Поиск по имени, email, телефону"
        />
      </form>
    </div>
  );
};

export default ContactsSearch;
