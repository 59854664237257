import { getToken, instance } from ".";

export const createStandFirstStep = ({
  occupation_id,
  company,
  event_id,
  short_description,
  logo,
}) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("occupation_id", occupation_id);
  formData.append("company", company);
  formData.append("event_id", event_id);
  formData.append("short_description", short_description);
  formData.append("logo", logo);

  return instance.post("/api/v1/stand/create", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandTemplates = ({ tmplId = 0, eventId = 0 }) => {
  const token = getToken();
  let url = "/api/v1/stand-templates";
  if (tmplId) {
    url += `?room_tmpl_id=${tmplId}`;
  } else if (eventId) {
    url += `?event_id=${eventId}`;
  }
  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createStandSecondStep = ({ tmplId, standId, standPositionId }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("tmpl_id", tmplId);
  formData.append("position", standPositionId);

  return instance.post(`/api/v1/stand/${standId}/choose-tmpl`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createStandThirdStep = ({
  organization_name,
  inn,
  registration_number,
  bank_name,
  bill_number,
  corresponding_number,
  stand_id,
  bic,
}) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("organization_name", organization_name);
  formData.append("inn", inn);
  formData.append("registration_number", registration_number);
  formData.append("bank_name", bank_name);
  formData.append("bill_number", bill_number);
  formData.append("corresponding_number", corresponding_number);
  formData.append("bic", bic);

  return instance.post(`/api/v1/stand/${stand_id}/legal-info`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createStandFourthStep = ({ tariff_id, stand_id }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("tariff_id", tariff_id);

  return instance.post(`/api/v1/stand/${stand_id}/choose-tariff`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandStats = ({ type }) => {
  const token = getToken();

  let url = "/api/v1/stands-stats";
  if (type) {
    url = `/api/v1/stands-stats?type=${type}`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setStandMedia = ({ standId, mediaType, file, rect }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("file", file);
  formData.append("rect", rect);

  return instance.post(`/api/v1/stand/${standId}/media/${mediaType}`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandInfo = ({ eventId, standId }) => {
  const token = getToken();

  let url;

  if (eventId) {
    url = `/api/v1/stand?event_id=${eventId}`;
  } else if (standId) {
    url = `/api/v1/stand?id=${standId}`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getModerationStand = ({ id }) => {
  const token = getToken();

  let url = `/api/v1/stand/${id}/moderation`;

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getModerationStands = ({ eventId }) => {
  const token = getToken();

  let url = `/api/v1/event/${eventId}/moderation/stands`;

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandMedia = ({ eventId, standId }) => {
  const token = getToken();

  let url;

  if (eventId) {
    url = `/api/v1/event/${eventId}/media`;
  } else if (standId) {
    url = `/api/v1/stand/${standId}/media`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const confirmStandMedia = ({ standId, eventId }) => {
  const token = getToken();
  let url = "";

  if (eventId) {
    url = `/api/v1/event/${eventId}/media/confirm`;
  } else if (standId) {
    url = `/api/v1/stand/${standId}/media/confirm`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createWebinar = ({
  name,
  description,
  timeStart,
  timeEnd,
  standId,
  eventId,
}) => {
  const token = getToken();

  let url = "";

  if (standId) {
    url = `/api/v1/stand/${standId}/webinar/create`;
  }

  if (eventId) {
    url = `/api/v1/event/${eventId}/webinar/create`;
  }

  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("time_start", timeStart);
  formData.append("time_end", timeEnd);

  return instance.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const editWebinar = ({ name, description, timeStart, timeEnd, webinarId }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("time_start", timeStart);
  formData.append("time_end", timeEnd);

  return instance.post(`/api/v1/webinar/${webinarId}`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getWebinarList = ({ standId, eventId, webinarsType }) => {
  const token = getToken();
  let url = "";

  if (standId) {
    url = `/api/v1/stand/${standId}/webinars`;
  }

  if (eventId) {
    url = `/api/v1/event/${eventId}/webinars`;
  }

  if (webinarsType) {
    url = url + `?date_type=${webinarsType}`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const deleteWebinar = ({ webinarId }) => {
  const token = getToken();

  return instance.get(`/api/v1/webinar/${webinarId}/delete`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const postWebinarOnModeration = ({ webinarId }) => {
  const token = getToken();

  return instance.post(`/api/v1/webinar/${webinarId}/moderation`, null, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandColors = ({ standId, eventId }) => {
  const token = getToken();

  let url = "";

  if (standId) {
    url = `/api/v1/stand/${standId}/colors`;
  }

  if (eventId) {
    url = `/api/v1/event/${eventId}/colors`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const editStandColor = ({ standId, eventId, objectName, color }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("type", objectName);
  formData.append("color", color);

  let url = "";

  if (standId) {
    url = `/api/v1/stand/${standId}/colors`;
  }

  if (eventId) {
    url = `/api/v1/event/${eventId}/colors`;
  }

  return instance.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const postStandAudio = ({ file, standType, id }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("file", file);
  formData.append("rect", -1);

  const url = `/api/v1/${standType}/${id}/media/audio_greeting`;

  return instance.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const changeStandStatus = ({ id, comment, status }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append('status', status);
  formData.append('comment', comment);

  const url = `/api/v1/stand/${id}/moderation`;

  return instance.post(url, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandVoiceAvatar = ({ standId, type }) => {
  const token = getToken();

  return instance.get(`/api/v1/${type}/${standId}/humanimage`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const postStandVoiceAvatar = ({ standId, gender, type }) => {
  const token = getToken();

  return instance.get(`/api/v1/${type}/${standId}/humanimage/${gender}`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
