import { immediateToast } from "izitoast-react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { setGalleryDesc, setGalleryFile } from "../../api/gallery";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";
import { Button, Layout, Loader } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import { UploadMaterialPopup } from "../../components/stand/gallery/UploadMaterialPopup";
import { useCreateEventPageDisabled } from "../../hooks/create-event/usePageDisabled";
import { fetchEvent, setFillingPageName } from "../../redux/eventReducer";
import { errorHandler } from "../../utils/errorHandler";

const Gallery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const dispatch = useDispatch();

  const event = useSelector((state) => state.events.currentEvent);
  const isPageDisabled = useCreateEventPageDisabled(pageNames.GALLERY);

  const planViewerUrl = process.env.REACT_APP_PLANVIEWER_URL || `/planviewer/index.html`;

  const iframeRef = useRef(null);

  const [uploadingFileModal, setUploadingFileModal] = useState(false);
  const [standId, setStandId] = useState(0);
  const [loadedImage, setLoadedImage] = useState("");
  const [loadedFile, setLoadedFile] = useState("");
  const [loadedLogo, setLoadedLogo] = useState("");
  const [loadedDesc, setLoadedDesc] = useState("");

  useEffect(() => {
    let stands = [];

    if (event?.position) {
      stands = [event.plan];
      event.position.forEach((item) => {
        stands.push({
          number: item.number,
          tmpl_id: item.tmpl_id,
          is_lobby: item.is_lobby,
          loc: item.px_position,
          desc: item.desc,
          logo: item.logo,
          size: item.px_size,
          angle: item.rotation,
          name: item.name,
          file: item.file,
          preview: item.preview,
          preview_image_top: item.preview_image_top,
          image: item.image,
          type: item.size,
        });
      });

      iframeRef.current.contentWindow.postMessage(
        {
          type: "loadScheme",
          stands,
          roomType: event.room_type,
          room_template_bundle: event.tmpl.bundle,
        },
        "*"
      );
    }

    const messageHandler = async ({ data }) => {
      try {
        if (data.type === "uploadFile" && !isPageDisabled) {
          setStandId(data.id);
          setLoadedImage(data.preview || "");
          setLoadedFile(data.file || "");
          setLoadedLogo(data.logo || "");
          setLoadedDesc(data.desc || "");
          setUploadingFileModal(true);
        } else if (event && data.type === "getScheme" && stands && stands.length > 0) {
          iframeRef.current.contentWindow.postMessage(
            {
              type: "loadScheme",
              stands,
              roomType: event.room_type,
              room_template_bundle: event.tmpl?.bundle,
            },
            "*"
          );
        }
      } catch (e) {
        errorHandler(e);
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [event, id, isPageDisabled]);

  const onUploadingPopupClose = () => {
    setUploadingFileModal(false);
  };

  const onUploadFile = async ({ desc, mainContentParams, logoParams }) => {
    setIsLoading(true);

    const eventId = id;
    const order = standId;

    try {
      if (logoParams.file) {
        await setGalleryFile({
          eventId,
          order,
          thumb: logoParams.thumb,
          fileType: "logo",
        });
      }

      if (mainContentParams.thumb) {
        let { fileType } = mainContentParams;

        if (!fileType) {
          fileType = "image";
        }

        await setGalleryFile({
          eventId,
          order,
          ...mainContentParams,
          fileType,
        });
      }

      const { data } = await setGalleryDesc({
        desc,
        eventId,
        order,
      });

      immediateToast("success", {
        message: "Материал успешно загружен",
        position: "topCenter",
      });

      dispatch(fetchEvent({ eventId }));

      iframeRef.current.contentWindow.postMessage(
        {
          type: "attachFile",
          id: standId,
          preview: data.data.preview,
          file: data.data.url,
          desc,
        },
        "*"
      );
    } catch (e) {
      errorHandler(e);
    }
    setIsLoading(false);
  };

  const onSavePlan = async () => {
    try {
      immediateToast("success", {
        message: "Галерея успешно сохранена",
        position: "topCenter",
      });

      dispatch(
        setFillingPageName({
          eventId: id,
          name: pageNames.JURIDICAL_INFO,
        })
      );

      dispatch(fetchEvent({ eventId: id }));
    } catch (error) {
      errorHandler(error);
      return [];
    }
  };

  const hidenIframeStyles = {
    position: "absolute",
    height: 1,
    width: 1,
    top: 0,
    left: 0,
  };

  return (
    <Layout>
      <div className="organizer-panel__main">
        {uploadingFileModal && (
          <UploadMaterialPopup
            onClose={onUploadingPopupClose}
            onSave={onUploadFile}
            loadedDesc={loadedDesc}
            loadedImage={loadedImage}
            loadedFile={loadedFile}
            loadedLogo={loadedLogo}
          />
        )}
        <NavStand />
        <div className="stand-settings">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <StandHeader title="Галерея" />
            <div>
              <Button disabled={isPageDisabled} onClick={onSavePlan}>
                Сохранить галерею
              </Button>
            </div>
          </div>

          {isLoading && (
            <div className="create-event__constructor-loader-wrapper">
              <Loader className="create-event__constructor-loader" />
            </div>
          )}
          <iframe
            src={planViewerUrl}
            className="create-event__constructor"
            frameBorder="0"
            title="event-constructor"
            ref={iframeRef}
            target="_parent"
            style={isLoading ? hidenIframeStyles : {}}
          ></iframe>
        </div>
      </div>
    </Layout>
  );
};

export default Gallery;
