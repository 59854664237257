import React, { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Element } from "react-scroll";
import { closeMomileBurger, landingLogo, openMobileBurger } from "../../assets";
import { Link } from "react-router-dom";

const headerLinks = [
  {
    text: "О нас",
    to: "landing-about-us",
  },
  {
    text: "Календарь",
    to: "landing-closest-events",
  },
  {
    text: "Как это работает",
    to: "landing-how-it-works",
  },
  {
    text: "Возможности",
    to: "landing-platform-abiities",
  },
  // { text: "Справка", to: "/wiki", isExternalSite: true },
];

const Intro = () => {
  const [isBurgerOpened, setIsBurgerOpened] = useState(false);

  const [isMobileLayout, setIsMobileLayout] = useState(
    document.documentElement.clientWidth <= 800
  );

  useEffect(() => {
    const resizeHandler = () => {
      if (document.documentElement.clientWidth <= 800 && !isMobileLayout) {
        return setIsMobileLayout(true);
      }

      if (document.documentElement.clientWidth > 800) {
        return setIsMobileLayout(false);
      }
    };

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [isMobileLayout]);

  return (
    <Element name="landing-intro" className="landing__intro landing-intro">
      {!isMobileLayout && (
        <div className="landing-intro__video">
          <video className="" controls={false} autoPlay={true} loop={true} muted={true}>
            <source src="/video/landing-preview-video.mp4" type="video/mp4" />
          </video>
          <div className="landing-intro__video-bg"></div>
        </div>
      )}
      <header className="landing-intro__header">
        <div className="landing-intro__header-bg"></div>
        <div className="landing-intro__header-wrapper">
          <div className="landing-intro__nav">
            {headerLinks.map((l, ind) => (
              <span key={ind}>
                {l.isExternalSite ? (
                  <a href={l.to} rel="noreferrer" target="_blank">
                    {l.text}
                  </a>
                ) : (
                  <ScrollLink to={l.to}>{l.text}</ScrollLink>
                )}
              </span>
            ))}
          </div>
          <Link to="/entry/registration" className="landing-intro__join">
            Войти / Регистрация
          </Link>
        </div>
      </header>
      <div className="landing-intro__container">
        <button
          onClick={() => setIsBurgerOpened(true)}
          className="landing-intro__open-burger"
        >
          <img src={openMobileBurger} alt="" />
        </button>
        {isBurgerOpened && (
          <div className="landing-intro__burger">
            <button
              onClick={() => setIsBurgerOpened(false)}
              className="landing-intro__close-burger"
            >
              <img src={closeMomileBurger} alt="" />
            </button>
            <div className="landing-intro__burger-menu">
              {headerLinks.map((l, ind) => (
                <span key={ind}>
                  <ScrollLink onClick={() => setIsBurgerOpened(false)} to={l.to}>
                    {l.text}
                  </ScrollLink>
                </span>
              ))}
              <Link to="/entry/registration" className="landing-intro__join">
                Войти / Регистрация
              </Link>
            </div>
          </div>
        )}
        <div className="landing-intro__main">
          <div className="landing-intro__circle">
            <div className="landing-intro__circle-bg"></div>
            <div className="landing-intro__circle-main">
              <div className="landing-intro__circle-header">
                <img src={landingLogo} alt="" />
                <span>Justevents</span>
              </div>
              <div className="landing-intro__title">простое решение сложных задач</div>
              <div className="landing-intro__post-title">
                платформа онлайн-мероприятий
              </div>
              <div className="landing-intro__registration-btn">
                <Link to="/entry/registration" className="red-btn">
                  Зарегистрируйся сейчас
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default Intro;
