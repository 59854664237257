import React from "react";
import {
  landingCommasIcon,
  landingPerspectiveIcon,
  landingPerspectiveBg,
} from "../../assets";

const Perspective = () => {
  return (
    <div className="landing-perspective">
      <img
        src={landingPerspectiveBg}
        alt=""
        draggable={false}
        className="landing-perspective__bg"
      />
      <div className="landing__block-title">ПРЕВРАТИТЕ КРИЗИС В ПЕРСПЕКТИВУ</div>
      <div className="landing__block-post-title">
        Трансформирует запланированное мероприятие в цифровой формат
      </div>
      <div className="landing-perspective__main">
        <div className="landing-perspective__image">
          <img src={landingPerspectiveIcon} alt="" />
        </div>
        <div className="landing-perspective__text">
          <img
            src={landingCommasIcon}
            alt=""
            className="landing-perspective__commas landing-perspective__commas--first"
          />
          <img
            src={landingCommasIcon}
            alt=""
            className="landing-perspective__commas landing-perspective__commas--second"
          />
          <h3>Вся жизнь - управление рисками, а не исключение рисков</h3>
          <span>Уолтер Ристон</span>
        </div>
      </div>
    </div>
  );
};

export default Perspective;
