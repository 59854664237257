import classNames from "classnames";
import { immediateToast } from "izitoast-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";
import { getToken, updateUser, uploadAvatar } from "../../api";
import { resetProfile } from "../../assets";
import { DropField, NavProfile } from "../../components/profile";
import {
  Button,
  EventConfirmPopup,
  Header,
  Select,
  Sidebar,
} from "../../components/shared";
import { setAccount, setNewAvatar, setProfileInfo } from "../../redux/userReducer";
import { useCountries } from "../../hooks/useCountries";

const Personal = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const [popupOpened, setPopupOpened] = useState(false);

  const [fullName, setFullName] = useState(profile.fullName || "");
  const [birth, setBirth] = useState(profile.birth || "");
  const [countryId, setCountryId] = useState(profile.countryId || "");
  const [role, setRole] = useState(profile.role || "");
  const [mobile, setMobile] = useState(profile.mobile || "");
  const [profession, setProfession] = useState(profile.position || "");

  const countries = useCountries();

  const getBirthError = () => {
    let minDate = new Date().getFullYear() - 100;
    if (new Date(birth).getFullYear() < minDate) {
      return "Минимальный год рождения - " + minDate + ".";
    } else if (new Date().getFullYear() - new Date(birth).getFullYear() < 14) {
      return "Возраст пользователя должен быть больше 14-ти лет.";
    }
    return false;
  };

  useEffect(() => {
    const setProfileInfo = () => {
      setFullName(profile.fullName);
      setBirth(profile.birth);
      setCountryId(profile.countryId);
      setFullName(profile.fullName);
      setRole(profile.role);
    };
    setProfileInfo();
  }, [profile.birth, profile.countryId, profile.fullName, profile.role]);

  function isAllowSend() {
    if (fullName && fullName.length >= 4 && mobile.length >= 10 && !getBirthError()) {
      return true;
    } else {
      return false;
    }
  }

  const onSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const validatedBirth = birth && moment(birth).format("DD.MM.YYYY");

      const crop = window.personalAvatarCrop;
      const canvas = window.personalAvatarCanvas;

      let avatar;

      if (crop && canvas) {
        const blob = await new Promise((resolve) => canvas.toBlob(resolve));

        const file = new File([blob], "avatar", {
          lastModified: new Date().getTime(),
          type: blob.type,
        });

        const { data } = await uploadAvatar(file);

        avatar = data && data.data && data.data.url;
      }

      const { data } = await updateUser({
        birth: validatedBirth,
        company: "",
        country_id: countryId,
        email: profile.email,
        full_name: fullName,
        phone: mobile,
        position: profession,
      });

      const user = data.data.user;
      if (!user.token) {
        user.token = getToken();
      }

      dispatch(setProfileInfo(user));
      dispatch(setAccount(user));

      if (avatar) {
        onNewAvatar(avatar);
      }

      immediateToast("success", {
        message: "Данные успешно сохранены.",
        position: "topCenter",
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onNewAvatar = (avatar) => {
    dispatch(setNewAvatar(avatar));
  };

  return (
    <div className="app">
      {popupOpened && <EventConfirmPopup closePopup={() => setPopupOpened(false)} />}
      <Sidebar />
      <Header userName={profile.fullName} />
      <div className="page__main">
        <NavProfile />
        <div className="personal">
          <div className="main">
            <h3 className="main__title">Профиль ({role})</h3>
            <h5 className="main__subtitle">Персональная информация</h5>
            <form className="main__form">
              <div className="input input--profile">
                <label className="input__label" htmlFor="fullName">
                  Ваш ID
                </label>
                <input
                  value={profile.id || ""}
                  className={classNames("default-input")}
                  placeholder="Ваш ID"
                  type="text"
                  disabled
                />
              </div>
              <div className="input input--profile">
                <label className="input__label" htmlFor="fullName">
                  Ваш email
                </label>
                <input
                  value={profile.email || ""}
                  className={classNames("default-input")}
                  placeholder="Ваш email"
                  type="text"
                  disabled
                />
              </div>
              <div className="input input--profile">
                <label className="input__label" htmlFor="fullName">
                  Ваше ФИО*
                </label>
                <input
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className={classNames(
                    "default-input",

                    { "default-input--filled": fullName && fullName.length >= 4 },
                    {
                      "default-input--error":
                        (!fullName && profile.fullName) ||
                        (fullName && fullName.length < 4),
                    }
                  )}
                  placeholder="Введите ваше ФИО"
                  type="text"
                  id="fullName"
                />
                {!fullName && profile.fullName && (
                  <p className="input__text">Поле не может быть пустым</p>
                )}
                {fullName && fullName.length < 4 && (
                  <p className="input__text">Минимальная длина поля 4 символа</p>
                )}
              </div>
              <div className="input input--profile">
                <label className="input__label" htmlFor="birth">
                  Дата рождения
                </label>
                <DatePicker
                  selected={birth ? new Date(birth) : null}
                  onChange={(date) => setBirth(date)}
                  maxDate={new Date(`12-31-${new Date().getFullYear() - 14}`)}
                  dateFormat="dd-MM-yyyy"
                  className={classNames(
                    "default-input",
                    "input__date",
                    {
                      "default-input--filled": birth,
                    },
                    { "default-input--error": !!getBirthError() }
                  )}
                  placeholderText="Выберите дату рождения"
                />
                <img
                  onClick={() => setBirth("")}
                  className="main__reset-icon"
                  src={resetProfile}
                  alt="Reset Icon"
                />
                {getBirthError() ? (
                  <p className="input__text">{getBirthError()}</p>
                ) : null}
              </div>
              <div className="input input--profile">
                <label className="input__label" htmlFor="mobile">
                  Ваш контактный телефон*
                </label>
                <input
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  className={classNames(
                    "default-input",
                    {
                      "default-input--error": !mobile || mobile.length < 10,
                    },
                    {
                      "default-input--filled": mobile.length > 0 && mobile.length >= 10,
                    }
                  )}
                  placeholder="+375 29 667 43 23"
                  type="tel"
                  minLength="10"
                  maxLength="12"
                  required
                  id="mobile"
                />
                {!mobile && <p className="input__text">Поле не может быть пустым</p>}
                {mobile && mobile.length < 10 && (
                  <p className="input__text">
                    Номер телефона не может содержать меньше 10 символов
                  </p>
                )}
              </div>
              <div className="input input--profile">
                <label className="input__label">Страна*</label>
                <Select
                  options={countries}
                  onChange={(c) => setCountryId(c.value)}
                  placeholder="Выберите страну"
                  value={countries.filter((c) => c.value === countryId)}
                />
              </div>
              <div className="input input--profile">
                <label className="input__label" htmlFor="pos">
                  Должность/профессия
                </label>
                <input
                  value={profession}
                  onChange={(e) => setProfession(e.target.value)}
                  className={classNames("input__main", {
                    filled: profession,
                  })}
                  placeholder="Введите вашу должность или профессию"
                  type="text"
                  id="pos"
                />
              </div>
              <Button
                disabled={!isAllowSend()}
                onClick={onSubmit}
                className="button__save"
              >
                Сохранить
              </Button>
            </form>
          </div>
          <DropField />
        </div>
      </div>
    </div>
  );
};

export default Personal;
