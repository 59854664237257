import React from "react";
import { clearString } from "../../../../utils";

const DemoEventAbout = ({ text, colorTitle, colorText }) => {
  if (text) {
    return (
      <div className="demo-event__about">
        <div className="demo__container">
          <div className="demo-event__about-main">
            <h5 className="demo-event__about-title" style={{ color: colorTitle }}>
              О мероприятии
            </h5>
            <p className="demo-event__about-text" style={{ color: colorText }}>
              {clearString(text)}
            </p>
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default DemoEventAbout;
