export const userManager = () => {
  return {
    prepare(userInfo) {
      if (!userInfo) {
        return null;
      }

      switch (userInfo.role) {
        case "Viewer":
          userInfo.role = "посетитель";
          break;

        case "Participant":
          userInfo.role = "участник";
          break;

        case "Organizer":
          userInfo.role = "организатор";
          break;

        case "Support":
          userInfo.role = "тех. поддержка";
          break;

        case "Administrator":
          userInfo.role = "администратор";
          break;

        default:
          break;
      }
      return {
        country:
          typeof userInfo.country === "object"
            ? userInfo.country?.name
            : userInfo.country,
        countryId: userInfo.country?.id || 0,
        email: userInfo.email,
        fullName: userInfo.fullName || userInfo.full_name,
        avatar: userInfo.avatar,
        role: userInfo.role,
        roleId: userInfo.role_id,
        id: userInfo.id,
        token: userInfo.token,
        birth: userInfo.birth || "",
        mobile: userInfo.phone || "",
        position: userInfo.position || "",
        byInvitation: userInfo.byInvitation || userInfo.by_invitation,
        step: userInfo.step,
        company: userInfo.company,
      };
    },
  };
};

export const authManager = () => {
  const ls = localStorage;

  const getToken = () => {
    return ls.getItem("_oAuth");
  };

  const setToken = (token) => {
    return ls.setItem("_oAuth", token);
  };

  const deleteAuthToken = () => {
    return ls.removeItem("_oAuth");
  };

  const getUser = () => {
    try {
      if (existUsers()) {
        let users = getUsers();
        for (let k in users) {
          let user = users[k];
          if (user.isActive) {
            return user;
          }
        }
      }
    } catch (e) {}
    return null;
  };

  const getUserById = (id) => {
    const users = getUsers();
    if (users && Object.keys(users).length > 0) {
      return users[id];
    }
  };

  const logout = () => {
    const users = getUsers();
    if (users && Object.keys(users).length > 0) {
      for (let k in users) {
        if (users[k].isActive) {
          delete users[k].isActive;
        }
      }
      ls.setItem("authUsers", JSON.stringify(users));
    }
  };

  const setUser = ({ id, token, fullName, avatar, role }) => {
    if (token) {
      setToken(token);
    }
    if (id) {
      logout();
      let users = getUsers();
      if (!users[id]) {
        if (!token) {
          // alert('Нет токена');
          console.log("Нет токена");
          return;
        }
        users[id] = {
          id,
          token,
        };
      }
      users[id].fullName = fullName;
      users[id].avatar = avatar;
      users[id].role = role;
      users[id].isActive = true;
      ls.setItem("authUsers", JSON.stringify(users));
    }
  };

  const getUsers = () => {
    try {
      let users = JSON.parse(ls.getItem("authUsers"));
      if (users && Object.keys(users).length > 0) {
        return users;
      }
    } catch (e) {
      console.error(e);
    }
    return {};
  };

  const existUsers = () => {
    const users = getUsers();
    return users && Object.keys(users).length > 0;
  };

  const fullLogout = () => {
    ls.removeItem("authUsers");
    ls.removeItem("_oAuth");
  };

  const removeToken = (token) => {
    let users = getUsers();
    if (users && Object.keys(users).length > 0) {
      for (let k in users) {
        let user = users[k];
        if (user.token === token) {
          delete users[k];
        }
      }
      ls.setItem("authUsers", JSON.stringify(users));
    }
  };

  const login = () => {};

  return {
    getToken: getToken,
    setToken: setToken,
    getUserById: getUserById,
    setUser: setUser,

    existUsers: existUsers,
    getUsers: getUsers,
    getUser: getUser,

    login: login,
    logout: logout,
    deleteAuthToken: deleteAuthToken,
    removeToken: removeToken,
    fullLogout: fullLogout,
  };
};
