import moment from "moment";
import React from "react";
import { ProgramCard } from "../../shared";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Review = ({ programEvents }) => {
  const eventsItems = [];
  const { id, type } = useParams();

  const currentStand = useSelector((state) => state.events.currentStand);

  [...programEvents]
    .filter((day) => day && day.date)
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map((day) => {
      [...day.events]
        .sort((a, b) => new Date(a.timeStart) - new Date(b.timeStart))
        .map((event) => {
          if (event.timeStart && event.timeEnd) {
            eventsItems.push({
              isLobby: type === "event" ? 1 : 0,
              position: currentStand?.position || 0,
              standId: id,
              timeStart:
                moment(day.date).format("YYYY-MM-DD") +
                " " +
                moment(event.timeStart).format("HH:mm"),
              timeEnd:
                moment(day.date).format("YYYY-MM-DD") +
                " " +
                moment(event.timeEnd).format("HH:mm"),
              title: event.description,
              isWebinar: event.isWebinar || false,
              eventId: type === "event" ? id : currentStand?.event_id,
            });
          }

          return event;
        });

      return day;
    });

  return (
    <ul className="program__review">
      {eventsItems.map((item, ind) => (
        <ProgramCard key={ind} program={item} />
      ))}
    </ul>
  );
};

export default Review;
