import React from "react";
import { avatarPlus, CreateWebDelete } from "../../../assets";
import { generateTextAboutAvailableResolution } from "../../../utils/generateTextMessage";

const allowedImageFileType = "image/png, image/jpeg, image/jpg";

const MaxImageSizeMb = 5;
const MinImageResolution = { width: 1136, height: 640 };
const MaxImageResolution = { width: 1920, height: 1080 };
const TextAboutAllowedResolution = `Разрешение: ${generateTextAboutAvailableResolution(
  MinImageResolution,
  MaxImageResolution
)}`;

export const UserImage = ({ image, onClear, onChange, index, title }) => {
  return (
    <div className="web-item__dropfield-mini">
      {image && <p className="web-item__img-title mini">{title}</p>}
      <div className="dropfield-mini">
        {image && (
          <div className="web-item__img-delete product">
            <img onClick={() => onClear({ index })} src={CreateWebDelete} alt="delete" />
          </div>
        )}
        {image && <img className="dropfield__image" src={image} alt="Add icon" />}
        <input
          onChange={(event) => onChange({ event, index })}
          className="dropfield-mini__input"
          type="file"
          id="file"
          accept={allowedImageFileType}
        />
        {!image && (
          <div className="dropfield-mini__content">
            <div className="dropfield-mini__plus">
              <img src={avatarPlus} alt="Add icon" />
            </div>
            <div className="dropfield-mini__text">
              <span>Загрузить изображение</span>
            </div>
          </div>
        )}
      </div>
      <div className="dropfield__notation">
        <div>Используйте только .jpg, .png типы файлов</div>
        <div>Максимальный размер: {MaxImageSizeMb} Мб</div>
        <div>{TextAboutAllowedResolution}</div>
      </div>
    </div>
  );
};
