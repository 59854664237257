import React from "react";
import { clearString } from "../../../../utils";

const DemoFooter = ({ text, contactInfo }) => {
  return (
    <footer className="demo-footer">
      <div className="demo__container">
        <div className="demo-footer__main">
          <div className="demo-footer__info">
            {clearString(text)}
          </div>
          <div className="demo-footer__contacts">
            <span>
              {clearString(contactInfo?.mobile)}
            </span>
            <span>
              {clearString(contactInfo?.email)}
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default DemoFooter;
