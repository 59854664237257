import { getToken, instance } from ".";
import { defaultEmailTemplate } from "../assets";

export const getDefaultTemplate = () => {
  return defaultEmailTemplate;
};

export const getMailingTemplates = ({ type, id }) => {
  const url = `/api/v1/${type}/${id}/mailing-templates`;
  const token = getToken();

  return instance.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
}

export const createMailingTemplate = ({ type, id, mailing_template }) => {
  const url = `/api/v1/${type}/${id}/mailing-templates/create`;
  const token = getToken();

  return instance.post(url, {
    ...mailing_template,
  }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  })
}

export const updateMailingTemplate = ({ type, id, mailingTemplateId, mailing_template }) => {
  const url = `/api/v1/${type}/${id}/mailing-templates/${mailingTemplateId}/update`;
  const token = getToken();

  return instance.post(url, {
    ...mailing_template,
  }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  })
}

export const deleteMailingTemplate = ({ type, id, templateId }) => {
  const url = `/api/v1/${type}/${id}/mailing-templates/${templateId}/delete`;
  const token = getToken();

  return instance.post(url, {}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  })
}

export const getMailings = ({ type, id }) => {
  const url = `/api/v1/${type}/${id}/mailings`;
  const token = getToken();

  return instance.get(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  })
}

export const sendMailing = ({ type, id, templateId, subject, recipients }) => {
  const url = `/api/v1/${type}/${id}/mailings/${templateId}/send`;
  const token = getToken();

  return instance.post(url, {subject, recipients}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  })
}
