import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLanding } from "../../api/landing";
import Loader from "../../components/shared/Loader";
import {
  DemoAbout,
  DemoAdvantages,
  DemoFooter,
  DemoHeader,
  DemoMain,
  DemoPresentation,
  DemoProducts,
} from "../../components/stand/promotion/demo";
import { errorHandler } from "../../utils/errorHandler";

const DemoSite = ({ isDemoPreview }) => {
  const demo = useSelector((state) => state.app.demo);
  const slug = window.location.pathname.replace(/^\/stand-[0-9]+-(.*)/, "$1");
  const [isLoading, setIsLoading] = useState(true);

  const { id } = useParams();

  const isDemo = isDemoPreview || slug === "/";
  const [siteInfo, setSiteInfo] = useState(demo);

  useEffect(() => {
    const fetchSiteInfo = async () => {
      try {
        setIsLoading(true);
        // if (isDemo) {
        //   return;
        // }

        let params = {};
        if (!id) {
          params = { slug };
        } else {
          params = { standId: id };
        }

        const { data } = await getLanding(params);
        const output = data.data;

        let formData = output.main;
        if (isDemo) {
          formData = output.draft || output.main;
        }

        formData &&
          setSiteInfo({
            ...formData,
            main: {
              ...formData.main,
              products: formData.main.products.filter(
                (pr) => pr.name || pr.img || pr.description
              ),
              advantages: formData.main.advantages.filter((ad) => ad.length > 0),
            },
          });
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (!isDemo) {
      fetchSiteInfo();
    }
  }, [slug, isDemo, id]);

  if (isLoading && !isDemo) {
    return (
      <div className="demo">
        <div className="demo__loader">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div className="demo" id="demo-site">
      {isDemo && <DemoHeader />}
      <DemoMain
        title={siteInfo.header.title || ""}
        subtitle={siteInfo.header.subtitle || ""}
        urls={siteInfo.urls || {}}
        colorTitle={siteInfo.color.title || ""}
        buttonColor={siteInfo.color.text || ""}
        colorBackground={siteInfo.color.background}
        bgImage={siteInfo.header.background}
        advantages={siteInfo.main.advantages}
        products={siteInfo.main.products}
        text={siteInfo.main.about || ""}
        contactInfo={siteInfo.header.contactInfo || {}}
      />
      <div className="demo-info">
        <DemoAbout
          text={siteInfo.main.about || ""}
          colorTitle={siteInfo.color.title || ""}
          colorText={siteInfo.color.text || ""}
          logo={siteInfo.header.logo}
        />
        <DemoAdvantages
          colorTitle={siteInfo.color.title || ""}
          colorText={siteInfo.color.text || ""}
          advantages={siteInfo.main.advantages}
        />
        <DemoProducts
          colorTitle={siteInfo.color.title || ""}
          colorText={siteInfo.color.text || ""}
          products={siteInfo.main.products}
        />
        <DemoPresentation
          colorTitle={siteInfo.color.title || ""}
          pdf={siteInfo.main.pdf}
        />
      </div>
      <DemoFooter
        contactInfo={siteInfo.header.contactInfo || {}}
        text={siteInfo.footer.text}
      />
    </div>
  );
};

export default DemoSite;
