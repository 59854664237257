import React from "react";

const ContactsTable = ({ contacts }) => {
  return (
    <main className="table-responsive">
      <table className="table table-bordered">
        <thead className="table__head">
          <tr>
            <th scope="col">Контактное лицо</th>
            <th scope="col">E-mail</th>
            <th scope="col">Контактный телефон</th>
            <th scope="col">Компания</th>
          </tr>
        </thead>
        <tbody className="table__body table__body--contacts">
          {contacts.map((contact, i) => (
            <tr key={i + 1}>
              <td>{contact.name}</td>
              <td>{contact.email}</td>
              <td>{contact.mobile}</td>
              <td>{contact.company}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};

export default ContactsTable;
