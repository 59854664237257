import { getToken, instance } from ".";

export const getActualEvents = () => {
  const token = getToken();

  return instance.get("api/v1/actual-events", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
