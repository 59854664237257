import React from "react";
import { popupClose } from "../../../assets";

const CreateWebSuccessPopup = ({ url, onClose }) => {
  return (
    <div className="event-popup">
      <div className="event-popup__main">
        <h5 className="event-popup__title">Ваш сайт успешно создан!</h5>
        <a target="_blank" rel="noreferrer" href={url} className="event-popup__text link">
          Ссылка - <span>{url}</span>
        </a>
        <div className="event-popup__buttons">
          <a href={url} target="_blank" rel="noreferrer" className="button event-popup__link">
            Перейти
          </a>
        </div>
        <div onClick={onClose} className="event-popup__close">
          <img src={popupClose} alt="close" />
        </div>
      </div>
      <div
        className="event-popup__overlay
          event-popup__overlay-sb"
      ></div>
    </div>
  );
};

export default CreateWebSuccessPopup;
