import { getToken, instance } from ".";

export const createStandContact = ({ standId, user }) => {
  const token = getToken();

  const { fullName, company, position, email, mobile, avatar } = user;

  const formData = new URLSearchParams();
  formData.append("fullname", fullName);
  company && formData.append("company_name", company);
  position && formData.append("position", position);
  formData.append("email", email);
  formData.append("phone", mobile);
  formData.append("avatar", avatar);

  return instance.post(`/api/v1/stand/${standId}/contacts`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandContacts = ({ standId }) => {
  const token = getToken();

  return instance.get(`/api/v1/stand/${standId}/contacts`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getEventContacts = ({ eventId }) => {
  const token = getToken();

  return instance.get(`/api/v1/event/${eventId}/contacts`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getPreparedContacts = async ({ type, id, selfId }) => {
  try {
    let contacts = [];

    if (type === "stand") {
      const { data } = await getStandContacts({ standId: id });
      contacts = data.data;
    }

    if (type === "event") {
      const { data } = await getEventContacts({ eventId: id });
      contacts = data.data.event_members;
    }

    return contacts
      .map((c) => ({
        id: c.id,
        name: c.name || `${c.first_name} ${c.last_name}`,
        email: c.email ?? "-",
        mobile: c.phone ?? "-",
        company: c.company ?? "-",
        role: "",
        avatar: c.avatar,
      }))
      .filter((c) => c.id !== selfId);
  } catch (error) {
    console.log(error);
  }
};
