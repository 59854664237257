import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { landingUpBtn } from "../../assets";
import {
  LandingAboutUs,
  LandingClosestEvents,
  LandingFooter,
  LandingHowItWorks,
  LandingIntro,
  LandingPerspective,
  LandingPlatformAbilities,
  LandingValuesJEP,
} from "../../components/landing";

const Landing = () => {
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 700) {
        setIsScrollButtonVisible(true);
      } else if (scrolled <= 700) {
        setIsScrollButtonVisible(false);
      }
    };

    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [isScrollButtonVisible]);

  return (
    <div className="landing">
      {isScrollButtonVisible && (
        <Link
          to="landing-intro"
          className="landing__up-btn"
          activeClass="active"
          spy={true}
          smooth={true}
          duration={300}
        >
          <img src={landingUpBtn} alt="" />
        </Link>
      )}

      <LandingIntro />
      <LandingAboutUs />
      <LandingClosestEvents />
      <LandingHowItWorks />
      <LandingValuesJEP />
      <LandingPlatformAbilities />
      {/* <LandingDigitalAbilities /> */}
      <LandingPerspective />
      <LandingFooter />
    </div>
  );
};

export default Landing;
