import { immediateToast } from "izitoast-react";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { WebinarCardOptions } from ".";
import { promotionDate } from "../../../assets";
import { useConfirmPopup } from "../../../hooks/useConfirmPopup";
import { deleteWebinar, setCurrentWebinar } from "../../../redux/webinarReducer";
import { ConfirmPopup } from "../../shared";
import { deleteWebinar as deleteWebinarApi } from "../../../api/stand";
import { getWebinarLink } from "../../../api/webinar";
import { errorHandler } from "../../../utils/errorHandler";

// allow organizer to launch webinar before initial start time
// sync it with value in backend
const Card = ({
  selectedWebinarType,
  id,
  title,
  startDay,
  startTime,
  endDay,
  endTime,
  description,
  openCreatePopup,
}) => {
  const dispatch = useDispatch();
  const { closePopup, popupInfo, setPopupInfo } = useConfirmPopup();

  const [isSettingsPopupOpened, setIsSettingsPopupOpened] = useState(false);

  const onSettingsButtonClick = () => {
    setIsSettingsPopupOpened(!isSettingsPopupOpened);
  };

  const onLaunchBroadcast = async () => {
    try {
      setIsSettingsPopupOpened(false);

      const data = await getWebinarLink({
        id: id,
      });

      if (data?.success && data?.data?.link) {
        // open webinar
        return data?.data?.link;
      } else {
        errorHandler({ response: { data } });
      }
    } catch (error) {
      closePopup();
      setIsSettingsPopupOpened(false);

      errorHandler(error);
    }
  };

  const onEditClick = () => {
    dispatch(
      setCurrentWebinar({
        id,
        title,
        startDay: startDay?.toString(),
        endTime: endTime?.toString(),
        startTime: startTime?.toString(),
        description,
        endDay: endDay?.toString(),
      })
    );
    setIsSettingsPopupOpened(false);
    openCreatePopup();
  };

  const onDeleteConfirmClick = async () => {
    try {
      await deleteWebinarApi({ webinarId: id });

      dispatch(deleteWebinar({ webinarId: id, webinarType: selectedWebinarType }));
      immediateToast("success", {
        message: "Вебинар успешно удален.",
        position: "topCenter",
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  const onDeleteClick = () => {
    setPopupInfo({
      title: "Вебинар",
      text: "Вы действительно хотите удалить вебинар?",
      isOpened: true,
      onCancelClick: closePopup,
      onConfirmClick: onDeleteConfirmClick,
      customConfirmText: "Удалить",
    });
  };

  const onStartClick = () => {
    onLaunchBroadcast().then((link) => {
      setPopupInfo({
        title: "Вебинар",
        text: "Вы действительно хотите запустить вебинар?",
        isOpened: true,
        link,
        onCancelClick: closePopup,
        onConfirmClick: closePopup,
      });
    });
  };

  return (
    <div className="webinar__card webinar-card">
      {popupInfo.isOpened && (
        <ConfirmPopup popupInfo={popupInfo} onCloseClick={closePopup} />
      )}
      <div className="webinar-card__header">
        <div className="webinar-card__title">{title}</div>
        <div className="webinar-card__id">ID:{id}</div>
        <div className="webinar-card__date">
          <img src={promotionDate} alt="" />
          <span>
            {moment(startDay).format("DD MMMM")}
            <div>{moment(startTime).format("HH:mm")}</div>
          </span>
          <span>-</span>
          <span>
            {moment(endDay).format("DD MMMM")}
            <div>{moment(endTime).format("HH:mm")}</div>
          </span>
        </div>
        <div className="webinar-card__more">
          <button onClick={onSettingsButtonClick} className="webinar-card__more-btn">
            <span>...</span>
          </button>
          {isSettingsPopupOpened && (
            <WebinarCardOptions
              onDeleteClick={onDeleteClick}
              onEditClick={onEditClick}
              cardType={selectedWebinarType}
              onStartClick={onStartClick}
            />
          )}
        </div>
      </div>
      <div className="webinar-card__main">{description}</div>
    </div>
  );
};

export default Card;
