import React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCreateStandInfo } from "../../../redux/appReducer";
import {
  useParticipantTariffs,
  ParticipantTariffsTable,
} from "../../shared/ParticipantTariffsTable";

const FirstStep = () => {
  const dispatch = useDispatch();
  const currentTariffId = useSelector((state) => state.app.createStandInfo.tariff?.id);

  const onTariffChange = useCallback(
    (tariff) => {
      dispatch(setCreateStandInfo({ tariff }));
    },
    [dispatch]
  );

  const { tariffs } = useParticipantTariffs({
    currentTariffId,
    onTariffChange,
  });

  return <ParticipantTariffsTable tariffs={tariffs} />;
};

export default FirstStep;
