import React from "react";
import { useHistory } from "react-router-dom";
import { popupClose } from "../../assets";

const EventConfirmPopup = ({
  eventLink,
  closePopup,
  eventTitle = "Мероприятие",
  eventText = "Желаете перейти к мероприятию?",
  afterClose = () => {},
}) => {
  const history = useHistory();

  const onConfirmClick = () => {
    if (eventLink === "/events/not-started") {
      return closePopup();
    }
    history.push(eventLink);
  };

  const onCloseClick = () => {
    afterClose();
    closePopup();
  };

  return (
    <div className="event-popup">
      <div className="event-popup__main">
        <h5 className="event-popup__title">{eventTitle}</h5>
        <div className="event-popup__text">{eventText}</div>
        <div className="event-popup__buttons">
          <button onClick={onCloseClick} className="white-btn event-popup__button">
            Отмена
          </button>
          <button onClick={onConfirmClick} className="red-btn event-popup__button">
            Перейти
          </button>
        </div>
        <div onClick={onCloseClick} className="event-popup__close">
          <img src={popupClose} alt="close" />
        </div>
      </div>
      <div className="event-popup__overlay"></div>
    </div>
  );
};

export default EventConfirmPopup;
