import React from "react";
import { NavLink, useParams } from "react-router-dom";

const Navigation = () => {
  const { id, type } = useParams();

  return (
    <nav className="program__nav">
      <div className="favorite-nav favorite-nav--notification">
        <NavLink to={`/${type}/${id}/settings/program/edit`} activeClassName="active">
          <div className="favorite-nav__item">Редактирование</div>
        </NavLink>
        <NavLink to={`/${type}/${id}/settings/program/review`} activeClassName="active">
          <div className="favorite-nav__item">Просмотр</div>
        </NavLink>
      </div>
    </nav>
  );
};

export default Navigation;
