import React from "react";

const PopupGoToEventButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="red-btn event-popup__button">
      Перейти
    </button>
  );
};

export default PopupGoToEventButton;
