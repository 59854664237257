import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  FavoritePopupControlButton,
  FavoritePopupEditButton,
  FavoritePopupGoToEventButton,
} from ".";
import { getFillingPageName } from "../../api/event";
import { getStandInfo } from "../../api/stand";
import { setCreateStandInfo } from "../../redux/appReducer";
import { createEventPages } from "../organizer-panel/CreateEvent";
import { generateFullItemLink } from "../organizer-panel/CreateEvent/EventSidebar";
import { Loader } from "../shared";

const PopupButtons = ({ eventInfo, closePopup }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const role = useSelector((state) => state.user.profile.role);
  const createStandInfo = useSelector((state) => state.app.createStandInfo);

  const {
    statusCode: eventStatusCode,
    type,
    standId,
    id: eventId,
    startDate,
    endDate,
    standStatusCode,
    position,
  } = eventInfo;

  const momentNow = moment();

  const status = role === "участник" ? standStatusCode : eventStatusCode;

  const isActual = type === "actual";
  const isPast = type === "pass";
  const isFilling = type === "filling";
  const isRequest = type === "request";

  const isDraft = status === 0;
  const isRejected = status === 40 || status === 42;
  const isModerating = status === 10 || status === 41;
  const isWaitingForPay = status === 20;
  const isWaitingForFill = status === 21;
  const isAvailable = status === 15 || status === 60;

  const [isLoading, setIsLoading] = useState(false);

  const isEventStarted = new Date(startDate) <= new Date();
  const isEventGoing = momentNow >= moment(startDate) && momentNow < moment(endDate);

  const [editEventLink, setEditEventLink] = useState("");

  const onEditClick = async () => {
    if (role === "организатор") {
      if (status === 15) {
        return history.push(`/event/${eventId}/settings/model`);
      }

      if (editEventLink) {
        return history.push(editEventLink);
      }

      let eventLink = `/organizer-panel/packages/${eventId}`;

      if (position?.length > 0) {
        eventLink = `/organizer-panel/detail-desc/${eventId}`;
      }

      return history.push(eventLink);
    }

    if (role === "участник") {
      let createStandLink = `/events/create/${eventId}?currentStep=1`;

      if (createStandInfo.standPositionId) {
        createStandLink = `/events/create/${eventId}?currentStep=3`;
      }

      if (createStandInfo.companyName) {
        createStandLink = `/events/create/${eventId}?currentStep=4`;
      }

      return history.push(createStandLink);
    }
  };

  const onControlClick = async () => {
    if (role === "организатор") {
      if (status === 15) {
        return history.push(`/event/${eventId}/settings/model`);
      }

      if (editEventLink) {
        return history.push(editEventLink);
      }

      return history.push(`/event/${eventId}/settings/model`);
    }

    if (role === "участник" && standId) {
      let url = `/stand/${standId}/settings/model`;

      if (isPast) {
        url = `/stand/${standId}/settings/contacts/list`;
      }
      return history.push(url);
    }
  };

  const onGoToEventClick = () => {
    return history.push(`/events/${eventId}?location=lobby`);
  };

  const onCancelClick = () => {
    closePopup();
  };

  useEffect(() => {
    let isMounted = true;
    const fetchStandInfo = async () => {
      try {
        if (role !== "участник") {
          return;
        }
        setIsLoading(true);

        const { data } = await getStandInfo({ eventId });

        if (!isMounted) {
          return;
        }

        const standInfo = data.data.info;

        dispatch(
          setCreateStandInfo({
            standId: data.data?.id || "",
            companyName: data.data?.company || "",
            branch: data.data?.occupation_id || "",
            shortDescription: standInfo?.short || "",
            organization: standInfo?.organization || "",
            inn: standInfo?.inn || "",
            registrationNumber: standInfo?.registration_number || "",
            bic: standInfo?.bic || "",
            bankName: standInfo?.bank_name || "",
            billNumber: standInfo?.bill_number || "",
            correspondingNumber: standInfo?.corresponding_number || "",
            tarif: "",
            standPositionId: data.data?.position,
          })
        );

        // файл логотипа
        window.targetStandLogo = null;
      } catch (error) {}
      setIsLoading(false);
    };

    const fetchEventInfo = async () => {
      try {
        if (role !== "организатор") return;
        const fillingPageName = await getFillingPageName({
          eventId,
        });

        const link = createEventPages.find((p) => p.name === fillingPageName);

        if (!link) return;

        setEditEventLink(
          generateFullItemLink({
            eventId,
            link,
          })
        );
      } catch (error) {}
    };

    fetchStandInfo();
    fetchEventInfo();

    return () => {
      isMounted = false;
    };
  }, [dispatch, eventId, role, setIsLoading]);

  if (isLoading) {
    return (
      <div style={{ margin: "0 auto" }}>
        <Loader />
      </div>
    );
  }

  if (role === "посетитель") {
    return (
      <>
        <button onClick={onCancelClick} className="white-btn event-popup__cancel-btn">
          Отмена
        </button>
        <FavoritePopupGoToEventButton onClick={onGoToEventClick} />
      </>
    );
  }

  if (isRequest) {
    if (isDraft || isRejected) {
      return (
        <div className="event-popup__solo-btn">
          <FavoritePopupEditButton onClick={onEditClick} />
        </div>
      );
    }

    if (isModerating || isWaitingForPay) {
      return null;
    }
  }

  if (isFilling) {
    if (isWaitingForFill || isDraft || isRejected) {
      return (
        <div className="event-popup__solo-btn">
          <FavoritePopupControlButton onClick={onControlClick} />
        </div>
      );
    }

    if (isModerating) {
      return null;
    }
  }

  if (isPast) {
    if (role === "организатор") {
      return (
        <>
          <FavoritePopupControlButton onClick={onControlClick} />
          <FavoritePopupGoToEventButton onClick={onGoToEventClick} />
        </>
      );
    } else {
      return (
        <div className="event-popup__solo-btn">
          <FavoritePopupControlButton onClick={onControlClick} />
        </div>
      );
    }
  }

  if (isActual) {
    if ((role === "участник" || role === "организатор") && isAvailable) {
      return (
        <>
          <FavoritePopupControlButton onClick={onControlClick} />
          <FavoritePopupGoToEventButton onClick={onGoToEventClick} />
        </>
      );
    }

    return isEventStarted && isEventGoing ? (
      <>
        <FavoritePopupControlButton onClick={onControlClick} />
        <FavoritePopupGoToEventButton onClick={onGoToEventClick} />
      </>
    ) : (
      <div className="event-popup__solo-btn">
        <FavoritePopupControlButton onClick={onControlClick} />
      </div>
    );
  }

  return null;
};

export default PopupButtons;
