import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";

const PanelLink = ({
  path,
  text,
  img,
  isActive,
  onClick,
  activeQueryParams,
  disableActiveQueryParams,
  className,
}) => {
  const location = useLocation();

  const checkIsActive = () => {
    return (
      activeQueryParams.some((qp) => location.search.includes(qp)) &&
      !disableActiveQueryParams.some((qp) => location.search.includes(qp))
    );
  };

  return (
    <NavLink
      to={path}
      className={classNames(
        "panel-link",
        {
          "panel-link--disabled": !isActive,
        },
        className
      )}
      activeClassName="panel-link--active"
      onClick={onClick}
      isActive={checkIsActive}
    >
      <div className="panel-link__img-wrapper">
        <img src={img} alt="" />
      </div>
      {text}
    </NavLink>
  );
};

export default PanelLink;
