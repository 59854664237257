export const truncateString = (input, len = 15) => {
  if (input.length > len) {
    return input.substring(0, len) + '...';
  }
  return input;
};

export const cloneObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};


export const extractVideoPath = (uri) => {
  let type;
  if(validateYoutubeLink(uri)){
    type = 'youtube';
  }else if(validateVimeoLink(uri)){
    type = 'vimeo';
  }
  let url = new URL(uri);
  let param = url.searchParams.get('v') || url.pathname.replace(/^\/([0-9A-Za-z\-_]+)$/, "$1");
  if(type === "vimeo"){
    //eslint-disable-next-line
    param = uri.match(/(?:https?:\/\/(?:www\.)?)?vimeo.com\/(?:channels\/|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/)
    param = param ? param[3] : null;
  }

  return param;
};

export const validateYoutubeLink = (uri = null) => {
  if(!uri){
    return true;
  }
  try{
    let url = new URL(uri);
    if((url.host === "www.youtube.com" || url.host === "m.youtube.com" || url.host === "youtube.com") && url.searchParams.get('v').length > 4){
      return true;
    }else if(url.host === "youtu.be" && url.pathname.match(/^\/([0-9A-Za-z\-_]+)$/)){
      return true;
    }
  }catch(e){}
  return false;
};

export const validateVimeoLink = (uri = null) => {
  if(!uri){
    return true;
  }
  try{
    //eslint-disable-next-line
    if(uri.match(/(?:https?:\/\/(?:www\.)?)?vimeo.com\/(?:channels\/|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/)){ 
      return true;
    }
  }catch(e){}
  return false;
};

export const clearString = (str) => {
  if(!str){
    return "";
  }
  return str.replace(/卐/g, " ")
            .replace(/卍/g, " ")
            .replace(/©/g, " ")
}

export function validateURLMatch(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export function validateURLConstructor(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
