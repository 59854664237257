import {immediateToast} from "izitoast-react";

export const  alertHandler = (location) => {
  const params = new URLSearchParams(location.search);
  let error = params.get('error');
  let warning = params.get('warning');
  let success = params.get('success');
  let info = params.get('info');
  if(error){
    if(typeof error === "string"){
      error = [error];
    }
    for (let k in error){
      let msg = error[k];
      immediateToast("error", {
        message: msg,
        position: "topCenter",
      });
    }
  }
  if(warning){
    if(typeof warning === "string"){
      warning = [warning];
    }
    for (let k in warning){
      let msg = warning[k];
      immediateToast("warning", {
        message: msg,
        position: "topCenter",
      });
    }
  }
  if(success){
    if(typeof success === "string"){
      success = [success];
    }
    for (let k in success){
      let msg = success[k];
      immediateToast("success", {
        message: msg,
        position: "topCenter",
      });
    }
  }
  if(info){
    if(typeof info === "string"){
      info = [info];
    }
    for (let k in info){
      let msg = info[k];
      immediateToast("info", {
        message: msg,
        position: "topCenter",
      });
    }
  }
  // params.delete('error');
  // params.delete('warning');
  // params.delete('success');
  // params.delete('info');
  // location.replace(params.toString());
};