import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { ModerationBanners, ModerationStands } from "../../components/events";
import { Layout } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import { fetchModerationStats } from "../../redux/eventReducer";

const ModerationList = () => {
  let { id, mType } = useParams();
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.events.moderationStats);

  useEffect(() => {
    dispatch(fetchModerationStats(id));
  }, [id, mType, dispatch]);

  const isPageDisabled = false;

  return (
    <Layout>
      <div className="organizer-panel__main">
        <NavStand />
        <div className="stand-settings">
          <StandHeader title="Модерация" withHint={isPageDisabled} />
          <div className="participants manage-moderation">
            <nav className="participants-nav">
              <div className="favorite-nav favorite-nav--notification">
                <NavLink
                  to={`/event/${id}/settings/moderation/stands/list`}
                  activeClassName="active"
                >
                  <div className="favorite-nav__item">
                    <p>
                      Стенды{" "}
                      {stats && stats.stands > 0 && (
                        <span className="badge badge-danger">{stats.stands}</span>
                      )}
                    </p>
                  </div>
                </NavLink>
                <NavLink
                  to={`/event/${id}/settings/moderation/banners/list`}
                  activeClassName="active"
                >
                  <div className="favorite-nav__item">
                    <p>
                      Баннеры{" "}
                      {stats && stats.banners > 0 && (
                        <span className="badge badge-danger">{stats.banners}</span>
                      )}
                    </p>
                  </div>
                </NavLink>
                {/* <NavLink
                  to={`/event/${id}/settings/moderation/webinars/list`}
                  activeClassName="active"
                >
                  <div className="favorite-nav__item">
                    <p>Вебинары { stats && stats.webinars > 0 && <span className="badge badge-danger">{stats.webinars}</span> }</p>
                  </div>
                </NavLink> */}
              </div>
            </nav>

            {mType === "stands" && <ModerationStands />}
            {mType === "banners" && <ModerationBanners />}
            {/* {mType === "webinars" && <ModerationWebinars />} */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ModerationList;
