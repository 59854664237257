import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  FifthStep,
  FirstStep,
  FourthStep,
  SecondStep,
  SixthStep,
  ThirdStep,
} from "./CreateEvent";
import { pageNames } from "./CreateEvent/EventSidebar";

const pages = [
  {
    url: "short-desc",
    Component: FirstStep,
    name: pageNames.SHORT_DESC,
  },
  {
    url: "packages",
    Component: SecondStep,
    name: pageNames.TARIFF_CHOSE,
  },
  {
    url: "hall-choose",
    Component: ThirdStep,
    name: pageNames.HALL_CHOSE,
  },
  {
    url: "hall-plan",
    Component: FourthStep,
    name: pageNames.HALL_PLAN,
  },
  {
    url: "detail-desc",
    Component: FifthStep,
    name: pageNames.DESCRIPTION,
  },
  {
    url: "juridical-info",
    Component: SixthStep,
    name: pageNames.JURIDICAL_INFO,
  },
];

export const Content = () => {
  const { pageContent } = useParams();

  const fillingPageName = useSelector((state) => state.events.fillingPageName);
  const currentEvent = useSelector((state) => state.events.currentEvent);
  const [eventStatusType] = currentEvent?.status_type || [];

  const page = pages.find((p) => p.url === pageContent);

  if (!page) {
    return null;
  }

  let isPageInputsDisabled = fillingPageName !== page.name;

  // activate juridical info after event reject
  if (
    eventStatusType !== "actual" &&
    page.name === pageNames.JURIDICAL_INFO &&
    fillingPageName === pageNames.DOCUMENTS
  ) {
    isPageInputsDisabled = false;
  }

  return <page.Component disabled={isPageInputsDisabled} />;
};
