import React from "react";

const ColorPopupFooter = ({ onCancelClick, onConfirmClick }) => {
  return (
    <div className="color-popup__footer">
      <button onClick={onCancelClick} className="color-popup__cancel-btn white-btn">
        Отмена
      </button>
      <button onClick={onConfirmClick} className="color-popup__confirm-btn red-btn">
        Применить
      </button>
    </div>
  );
};

export default ColorPopupFooter;
