import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { avatarPlus, popupClose } from "../../assets";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import classNames from "classnames";
import { removeProfileAvatar } from "../../api";
import { immediateToast } from "izitoast-react";
import { setNewAvatar } from "../../redux/appReducer";
import { errorHandler } from "../../utils/errorHandler";
import { isFileSizeAllowed } from "../../utils/isFileSizeAllowed";
import { errorMessage } from "../../utils/errorMessage";
import { generateTextErrorMaxSize } from "../../utils/generateTextMessage";

const IMAGE_MAX_SIZE = 5;

const DropField = () => {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.user.profile);
  const [isUserHaveAvatar, setIsUserHaveAvatar] = useState(
    profile.avatar && !profile.avatar.includes("/img/not-avatar.png")
  );

  // если фото с сервера, то кроп не активируется
  const [isAvatarFromServer, setIsAvatarFromServer] = useState(true);

  useEffect(() => {
    if (profile.avatar.includes("http")) {
      setIsAvatarFromServer(true);
    }
  }, [profile.avatar]);

  const [avatar, setAvatar] = useState(profile.avatar);

  useEffect(() => {
    setAvatar(profile.avatar);
  }, [profile.avatar]);

  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 9 / 9 });
  const previewCanvasRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  // логика для кропа фото
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    window.personalAvatarCanvas = previewCanvasRef.current;
    window.personalAvatarCrop = completedCrop;
  }, [completedCrop]);

  async function handleChangeAvatar(e) {
    let avatar = e.target.files[0];
    if (!avatar) {
      return;
    }

    if (!isFileSizeAllowed({ file: avatar, size: IMAGE_MAX_SIZE })) {
      return errorMessage(generateTextErrorMaxSize(IMAGE_MAX_SIZE));
    }

    setIsAvatarFromServer(false);
    setIsUserHaveAvatar(true);

    const avatarURL = URL.createObjectURL(avatar);
    setAvatar(avatarURL);
  }

  const onDeleteClick = async () => {
    try {
      const { data } = await removeProfileAvatar();

      setIsUserHaveAvatar(false);
      dispatch(setNewAvatar(data.data.default));
      immediateToast("success", {
        message: "Аватар успешно удален.",
        position: "topCenter",
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div
      className={classNames("avatar", {
        "avatar--from-server": isAvatarFromServer,
      })}
    >
      <div
        className="avatar__field"
        style={!isAvatarFromServer ? { width: 350, height: 300 } : {}}
      >
        {isUserHaveAvatar && isAvatarFromServer && (
          <button onClick={onDeleteClick} className="avatar__delete">
            <img src={popupClose} alt="" />
          </button>
        )}
        <div className="avatar__content">
          <div className="avatar__btn">
            <input
              className="avatar__input"
              type="file"
              onChange={(e) => handleChangeAvatar(e)}
              id="file"
              accept="image/png, image/jpeg, image/jpg, image/svg+xml"
            />
            <div className="avatar__plus">
              <img src={avatarPlus} alt="Add icon" />
            </div>
            <div className="avatar__text">
              <span>Загрузить аватар</span>
            </div>
          </div>
          <div className="avatar__wrap-image">
            {isUserHaveAvatar ? (
              avatar && isAvatarFromServer ? (
                <img className="avatar__image" src={avatar} alt="" />
              ) : (
                <ReactCrop
                  src={avatar}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                  imageStyle={{ maxHeight: "100%", maxWidth: "100%" }}
                  style={{
                    height: "100%",
                  }}
                  className="avatar__crop"
                />
              )
            ) : null}
          </div>
        </div>
      </div>
      <div className="avatar__notation">
        Используйте только .jpg, .png типы файлов
        <div>Максимальный размер: {IMAGE_MAX_SIZE} Мб</div>
      </div>
      <div style={{ position: "absolute", width: "1px", height: "1px", opacity: 0 }}>
        <canvas
          ref={previewCanvasRef}
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
          }}
        />
      </div>
    </div>
  );
};

export default DropField;
