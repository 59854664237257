import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const toggleElems = ({ type, hiddenElementsSelectors = [] }) => {
  const hiddenElements = [];

  hiddenElementsSelectors.forEach((el) => {
    hiddenElements.push(...document.querySelectorAll(el));
  });

  hiddenElements.forEach((it) => {
    if (!it) return;
    it.style.display = type ? "block" : "none";
  });
};

export const exportPageToPdf = ({
  wrapperSelector,
  hiddenElementsSelectors,
  fileName = "report",
}) => {
  toggleElems({
    type: 0,
    hiddenElementsSelectors,
  });
  const data = document.querySelector(wrapperSelector);
  const originStyle = { pd: data.style.padding };
  data.style.padding = "15px";

  if (data) {
    // load external event logo image with cors for pdf { useCORS: true }
    html2canvas(data, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg");
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;

      const pdf = new jsPDF({
        orientation: imageWidth > imageHeight ? "l" : "p",
        unit: "px",
        format: [imageWidth, imageHeight],
      });

      pdf.addImage(imgData, "JPEG", 0, 0, imageWidth, imageHeight);

      // reset
      data.style.padding = originStyle.pd;
      toggleElems({
        type: 1,
        hiddenElementsSelectors,
      });

      pdf.save(`${fileName}.pdf`);
    });
  } else {
    // reset
    data.style.padding = originStyle.pd;
    toggleElems({
      type: 1,
      hiddenElementsSelectors,
    });
  }
};
