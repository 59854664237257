import React, { useState } from "react";
import { useSelector } from "react-redux";
import { forgotPassword } from "../../api";
import { EntryConfirmSvg } from "../../assets";
import { EntryLogo, MainAbout } from "../../components/entry";
import { errorHandler } from "../../utils/errorHandler";
import { successMessage } from "../../utils/successMessage";

const ResetPasswordConfirm = () => {
  const reset = useSelector((state) => state.app.reset);
  const [isResetting, setIsResetting] = useState(false);

  const onClick = async () => {
    setIsResetting(true);
    try {
      const { data } = await forgotPassword(reset.email);
      if(data.success){
        successMessage(data.message);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsResetting(false);
    }
  }
  
  return (
    <div className="registration-confirm">
      <EntryLogo />
      <MainAbout noScroll />
      <main className="registration-confirm__content">
        <h3 className="registration-confirm__title">Сброс пароля JUSTEVENTS</h3>
        <div className="registration-confirm__main">
          <img
            className="registration-confirm__icon"
            src={EntryConfirmSvg}
            alt="Confirm Icon"
          />
          <h5 className="registration-confirm__notification">
            Мы отправили вам письмо по адресу
          </h5>
          <p className="registration-confirm__email">{reset.email}</p>
          <p className="registration-confirm__text">
            Нажмите по ссылке подтверждения в электронном письме, чтобы сбросить
            пароль JEP
          </p>
          <p className="registration-confirm__promt">
            Если вы не получали данное письмо:
          </p>
          <p onClick={onClick} className="registration-confirm__again">
            { isResetting ? 'Отправляем...' : 'Отправить уведомление ещё раз'  }
          </p>
        </div>
      </main>
    </div>
  );
};

export default ResetPasswordConfirm;
