import React from "react";
import { promotionArrow } from "../../../assets";

const PromotionWebHeader = ({ title, setVisible }) => {
  return (
    <div onClick={setVisible} className="web-header">
      <h5 className="web-header__title">{title}</h5>
      <div className="web-header__button">
        <img src={promotionArrow} alt="Button icon" />
      </div>
    </div>
  );
};

export default PromotionWebHeader;
