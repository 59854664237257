import React from "react";
import classNames from "classnames";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import { list, table } from "../../../assets";
import { useEffect } from "react";
import {fetchMembers} from "../../../redux/eventReducer";
import {useDispatch, useSelector} from "react-redux";

const ParticipantsNav = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const members = useSelector(state => state.events.members);

  useEffect(() => {
    dispatch(fetchMembers(id));
  }, [dispatch, id]);

  return (
    <nav className="participants-nav">
      <div className="favorite-nav favorite-nav--notification">
        <NavLink to={`/event/${id}/settings/participants/sale`} activeClassName="active">
          <div className="favorite-nav__item">
            <p>Продажа стендов и экранов</p>
          </div>
        </NavLink>
        <NavLink
          to={`/event/${id}/settings/participants/list?view=table`}
          activeClassName="active"
        >
          <div className="favorite-nav__item">
            <p>Участники <span>{members ? members.length : 0}</span></p>
          </div>
        </NavLink>
      </div>
      {history.location.pathname.includes(
        `/event/${id}/settings/participants/list`
      ) && (
        <div className="stand-settings__contacts-nav">
          <Link
            to={`/event/${id}/settings/participants/list?view=table`}
            className={classNames({
              active:
                history.location.pathname + history.location.search ===
                `/event/${id}/settings/participants/list?view=table`,
            })}
          >
            <div className="event-calendar__nav-item">
              <img src={list} alt="Nav Icon" />
            </div>
          </Link>
          <Link
            to={`/event/${id}/settings/participants/list?view=cards`}
            className={classNames({
              active:
                history.location.pathname + history.location.search ===
                `/event/${id}/settings/participants/list?view=cards`,
            })}
          >
            <div className="event-calendar__nav-item">
              <img src={table} alt="Nav Icon" />
            </div>
          </Link>
        </div>
      )}
    </nav>
  );
};

export default ParticipantsNav;
