
/**
 * Generate text about available resolution
 * от MinWidth x MinHeight до MaxWidth x MaxHeight
 * от MinWidth x MinHeight
 * до MaxWidth x MaxHeight
 */
export function generateTextAboutAvailableResolution(minFileResolution, maxFileResolution) {
  const TextPartResolutionFrom = (minFileResolution.width > 0 || minFileResolution.height > 0)
    ? `${minFileResolution.width}px x ${minFileResolution.height}px` : null;
  const TextPartResolutionTo = (maxFileResolution.width && maxFileResolution.height)
    ? `${maxFileResolution.width}px x ${maxFileResolution.height}px` : null;
  let TextPartResolution = null;
  if (TextPartResolutionFrom && TextPartResolutionTo) {
    TextPartResolution = `от ${TextPartResolutionFrom} до ${TextPartResolutionTo}`;
  } else if (TextPartResolutionFrom) {
    TextPartResolution = `от ${TextPartResolutionFrom}`;
  } else if (TextPartResolutionFrom) {
    TextPartResolution = `до ${TextPartResolutionTo}`;
  }
  return TextPartResolution;
}

export function generateTextErrorMinResolution(MinWidth, MinHeight) {
    return `Минимальный размер изображения ${MinWidth}px x ${MinHeight}px`;
}

export function generateTextErrorMaxResolution(MaxWidth, MaxHeight) {
    return `Максимальный размер изображения ${MaxWidth}px x ${MaxHeight}px`;
}

export function generateTextErrorMaxSize(MaxSizeMb) {
    return `Максимальный размер файла ${MaxSizeMb} МБ`;
}
