import classNames from "classnames";
import queryString from "query-string";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { resetPassword } from "../../api";
import { EntryHeader, EntryLogo, MainAbout } from "../../components/entry";
import { Button } from "../../components/shared";
import { errorHandler } from "../../utils/errorHandler";

const ResetPasswordForm = () => {
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);

  function checkPassword(password) {
    if (password.length >= 1 && password.length < 5) {
      return true;
    }
  }
  function isAllow() {
    if (
      newPassword.length >= 5 &&
      confirmPassword.length >= 5 &&
      newPassword === confirmPassword
    ) {
      return true;
    }
  }

  const onSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const email = parsedQuery.email.replace("%40", "@");
      const token = parsedQuery.token;

      await resetPassword({
        token,
        email,
        password: newPassword,
        password_confirmation: confirmPassword,
      });
      console.log("here");
      history.push("/entry/reset/finally");
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="registration registration--reset">
      <EntryLogo />
      <MainAbout noScroll />
      <main className="registration-form">
        <EntryHeader login />
        <div className="registration-form__main">
          <h3 className="registration-form__title">Сброс пароля JUSTEVENTS</h3>
          <form className="registration-form__inputs">
            <div className="input">
              <label className="input__label">Новый пароль*</label>
              <input
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className={classNames(
                  "input__main",
                  { error: checkPassword(newPassword) },
                  { filled: newPassword.length >= 5 }
                )}
                placeholder="Введите новый пароль"
                type="password"
                maxLength={255}
              />
              {checkPassword(newPassword) ? (
                <p className="input__text">Поле должно содержать не менее 5 символов</p>
              ) : null}
            </div>
            <div className="input">
              <label className="input__label" htmlFor="currentPassword">
                Повторите новый пароль
              </label>
              <input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className={classNames(
                  "input__main",
                  {
                    error: checkPassword(confirmPassword),
                  },
                  { filled: confirmPassword.length >= 5 }
                )}
                placeholder="Введите пароль ещё раз"
                type="password"
                maxLength={255}
              />
              {checkPassword(confirmPassword) ? (
                <p className="input__text">Поле должно содержать не менее 5 символов</p>
              ) : null}
              <Link to="/entry/login" className="input__password">
                Вспомнили пароль?
              </Link>
            </div>
            <Button
              onClick={onSubmit}
              className="registration-reset__link"
              disabled={!isAllow()}
            >
              Установить новый пароль
            </Button>
          </form>
        </div>
        {/* <EntryFooter login /> */}
      </main>
    </div>
  );
};

export default ResetPasswordForm;
