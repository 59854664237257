import { getToken, instance } from ".";

export const createStandDescription = ({
  occupationId,
  company,
  standId,
  shortDescription,
  logo,
}) => {
  const token = getToken();

  const formData = new FormData();
  formData.append("occupation_id", occupationId);
  formData.append("company", company);
  formData.append("short_description", shortDescription);
  formData.append("logo", logo);

  return instance.post(`/api/v1/stand/${standId}/registration`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getOccupations = () => {
  const token = getToken();

  return instance.get("/api/v1/occupations", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandTemplates = ({ tmplId = 0, eventId = 0 }) => {
  const token = getToken();
  let url = "/api/v1/stand-templates";
  if (tmplId) {
    url += `?room_tmpl_id=${tmplId}`;
  } else if (eventId) {
    url += `?event_id=${eventId}`;
  }
  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createStandChooseTemplate = ({ tmplId, standId, standPositionId }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("tmpl_id", tmplId);
  formData.append("position", standPositionId);

  return instance.post(`/api/v1/stand/${standId}/choose-tmpl`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createStandLegalInfo = ({
  organizationName,
  inn,
  registrationNumber,
  bankName,
  billNumber,
  correspondingNumber,
  standId,
  withVAT,
  bic,
  registrationCode,
  directorName,
  actionBasis,
  juridicalAdress,
  contactPhone,
}) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("organization", organizationName);
  formData.append("inn", inn);
  formData.append("registration_number", registrationNumber);
  formData.append("bank_name", bankName);
  formData.append("bill_number", billNumber);
  formData.append("corresponding_number", correspondingNumber);
  formData.append("bic", bic);
  formData.append("vat", withVAT);
  formData.append("kpp", registrationCode);
  formData.append("head_fio", directorName);
  formData.append("justification", actionBasis);
  formData.append("address", juridicalAdress);
  formData.append("phone", contactPhone);

  return instance.post(`/api/v1/stand/${standId}/legal-info`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const createStandChooseTariff = ({ tariffId, eventId }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("tariff_id", tariffId);
  formData.append("event_id", eventId);

  return instance.post(`/api/v1/stand/choose-tariff`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getTariffs = () => {
  const token = getToken();

  return instance.get(`/api/v1/tariffs`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const confirmCreateStand = ({ standId }) => {
  const token = getToken();

  return instance.get(`/api/v1/stand/${standId}/confirmation`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const updateOrganizationInfo = ({
  organization_name,
  inn,
  registration_number,
  kpp,
  justification,
  address,
}) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("organization_name", organization_name);
  formData.append("inn", inn);
  formData.append("registration_number", registration_number);
  if (kpp || kpp === '') formData.append("kpp", kpp);
  if (justification || justification === '') formData.append("justification", justification);
  if (address || address === '') formData.append("address", address);
  return instance.post(`/api/v1/user/legal-info`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const updateBankInfo = ({
  valute,
  bank_name,
  bill_number,
  corresponding_number,
  bic,
}) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("valute", valute);
  formData.append("bank_name", bank_name);
  formData.append("bill_number", bill_number);
  formData.append("corresponding_number", corresponding_number);
  formData.append("bic", bic);

  return instance.post(`/api/v1/user/bank-info`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getMemberFullInfo = () => {
  const token = getToken();

  return instance.get("/api/v1/user/full", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandStats = ({ type }) => {
  const token = getToken();

  let url = "/api/v1/stands-stats";
  if (type) {
    url = `/api/v1/stands-stats?type=${type}`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setStandSiteHeaderBg = ({ bg, standId }) => {
  if (!bg) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", bg);

  return instance.post(`/api/v1/cache/file/${standId}-stand-site-bg`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setStandSiteLogo = ({ logo, standId }) => {
  if (!logo) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", logo);

  return instance.post(`/api/v1/cache/file/${standId}-stand-site-logo`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setStandSitePdf = ({ pdf, standId }) => {
  if (!pdf) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", pdf);

  return instance.post(`/api/v1/cache/file/${standId}-stand-site-pdf`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandSiteHeaderBg = ({ standId }) => {
  const token = getToken();

  return instance.get(`/api/v1/cache/get/${standId}-stand-site-bg`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setStandSiteForm = ({ form, standId }) => {
  const token = getToken();

  const formData = new URLSearchParams();
  formData.append("data", form);

  return instance.post(`/api/v1/cache/set/${standId}-stand-site-form`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandSiteForm = ({ standId }) => {
  const token = getToken();

  return instance.get(`/api/v1/cache/get/${standId}-stand-site-form`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setStandSiteProductImage = ({ image, standId, productNumber }) => {
  if (!image) {
    return;
  }
  const token = getToken();

  const formData = new FormData();
  formData.append("file", image);

  return instance.post(
    `/api/v1/cache/file/${standId}-stand-site-product-img${productNumber}`,
    formData,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    }
  );
};

export const setStandWebinar = ({ webinar, standId }) => {
  const token = getToken();
  const formData = new URLSearchParams();
  formData.append("data", webinar);
  return instance.post(`/api/v1/cache/set/${standId}-stand-webinar`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandWebinar = ({ standId }) => {
  const token = getToken();

  return instance.get(`/api/v1/cache/get/${standId}-stand-webinar`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const setStandMedia = ({ standId, mediaType, file, rect, thumb, link, name }) => {
  const token = getToken();

  const formData = new FormData();

  formData.append("rect", rect);
  if (file) formData.append("file", file);
  if (thumb) formData.append("thumb", thumb);
  if (link) formData.append("link", link);
  if (name) formData.append("name", name);

  return instance.post(`/api/v1/stand/${standId}/media/${mediaType}`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandInfo = ({ eventId, standId }) => {
  const token = getToken();

  let url;

  if (eventId) {
    url = `/api/v1/stand?event_id=${eventId}`;
  }

  if (standId) {
    url = `/api/v1/stand?id=${standId}`;
  }

  return instance.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const getStandMedia = ({ standId }) => {
  const token = getToken();

  return instance.get(`/api/v1/stand/${standId}/media`, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};

export const changeStandField = ({ standId, fieldName, value }) => {
  const token = getToken();

  const formData = new FormData();
  formData.append(fieldName, value);

  return instance.post(`/api/v1/stand/${standId}/update`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  });
};
