import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { fetchBannersModeration } from "../../redux/eventReducer";

const ModerationBannersList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const banners = useSelector((state) => state.events.banners);

  const onCardClick = (bannerId) => {
    history.push(`/event/${id}/settings/moderation/banner/${bannerId}`);
  };

  useEffect(() => {
    dispatch(fetchBannersModeration({ eventId: id }));
  }, [id, dispatch]);

  return (
    <div style={{ width: "100%" }} className="table-responsive">
      <table className="table table-bordered moderation-table participants-table participants-table--cards">
        <thead className="moderation-table__thead table__head">
          <tr className="moderation-table__tr">
            <th className="moderation-table__th" style={{ width: "5%" }} scope="col">
              #
            </th>
            <th
              className="moderation-table__th"
              style={{ width: "70%" }}
              colSpan="2"
              scope="col"
            >
              Название
            </th>
            <th className="moderation-table__th" style={{ width: "25%" }} scope="col">
              Статус
            </th>
          </tr>
        </thead>
        <tbody className="moderation-table__tbody table__body">
          {banners &&
            banners.length > 0 &&
            banners.map((banner, ind) => {
              let status = <span className="badge badge-md badge-danger">Заявка</span>;
              if (banner.status_code === 41) {
                status = <span className="badge badge-md badge-info">Наполнение</span>;
              }else if (banner.status_code === 20) {
                status = <span className="badge badge-md badge-primary">Оплата</span>;
              }
              return (
                <tr
                  onClick={() => onCardClick(banner.id)}
                  className="moderation-table__tr"
                  key={ind}
                >
                  <td className="moderation-table__td">{banner.id}</td>
                  <td
                    className="moderation-table__td moderation-table__logo"
                    style={{ width: "5%", textAlign: "center" }}
                  >
                    <img src={banner.image} alt="" width="50" />
                  </td>
                  <td className="moderation-table__td">
                    <span className="moderation-table__title">{banner.name}</span>
                    <span className="moderation-table__target-link target-link">
                      <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
                        <g transform="matrix(0.625 0 0 0.625 0 0)">
                          <path
                            d="M14.851 11.923C 14.672 11.282001 14.33 10.677 13.826 10.174001C 12.264 8.612 9.731001 8.611001 8.169001 10.174001L8.169001 10.174001L3.1710005 15.172001C 1.6090004 16.735 1.6080005 19.267 3.1710005 20.829C 4.7330008 22.392 7.2670007 22.390001 8.827001 20.829L8.827001 20.829L12.669001 16.988L13.002001 16.997002C 13.406001 16.997002 13.804001 16.957 14.191001 16.880001L14.191001 16.880001L9.534 21.536001C 8.559 22.512001 7.2790003 23.000002 5.9990005 23.000002C 4.719001 23.000002 3.4390006 22.512001 2.4640005 21.536001C 0.51200044 19.585001 0.51200044 16.416 2.4640005 14.465001L2.4640005 14.465001L7.462001 9.467001C 8.437001 8.491001 9.718 8.003001 10.998001 8.003001C 12.277001 8.003001 13.558001 8.491001 14.533001 9.467001C 15.026001 9.960001 15.394001 10.530001 15.6380005 11.139001L15.6380005 11.139001L14.851001 11.923001L14.851 11.923zM9.148 12.070001C 9.326 12.713 9.669 13.320001 10.174 13.826C 11.736 15.389 14.27 15.387 15.83 13.826L15.83 13.826L20.829 8.828C 22.392 7.266 22.392 4.7330003 20.829 3.171C 19.267 1.609 16.734001 1.608 15.172001 3.171L15.172001 3.171L11.331001 7.012L10.998001 7.0030003C 10.594001 7.0030003 10.196001 7.043 9.809001 7.1200004L9.809001 7.1200004L14.465001 2.4640002C 15.4400015 1.4880002 16.721 1.0000002 18.001001 1.0000002C 19.28 1.0000002 20.561 1.4880003 21.536001 2.4640002C 23.487001 4.415 23.487001 7.583 21.536001 9.535L21.536001 9.535L16.537 14.533C 15.562 15.509 14.282001 15.997 13.002001 15.997C 11.722001 15.997 10.442001 15.509 9.467001 14.533C 8.973001 14.038 8.604001 13.466 8.360001 12.855L8.360001 12.855L9.148001 12.07z"
                            stroke="none"
                            fill="#000000"
                          />
                        </g>
                      </svg>
                      <i>перейти</i>
                    </span>
                  </td>
                  <td className="moderation-table__td">{status}</td>
                </tr>
              );
            })}
          {banners && !banners.length && (
            <tr>
              <td colSpan="4">Нет данных для отображения</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ModerationBannersList;
