import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { createEventDetailDescription } from "../../../api/organizer";
import { avatarPlus, popupClose, questionDelete, questionEdit } from "../../../assets";
import {
  setChangeQuestion,
  setCreateEventInfo,
  setDeleteQuestion,
  setEditQuestion,
  setNewQuestion,
  setSaveQuestion,
} from "../../../redux/appReducer";
import { setFillingPageName } from "../../../redux/eventReducer";
import { errorHandler } from "../../../utils/errorHandler";
import { errorMessage } from "../../../utils/errorMessage";
import {
  generateTextAboutAvailableResolution,
  generateTextErrorMaxResolution,
  generateTextErrorMaxSize,
  generateTextErrorMinResolution,
} from "../../../utils/generateTextMessage";
import { getImageSize } from "../../../utils/getImageSize";
import { successMessage } from "../../../utils/successMessage";
import { Input } from "../../shared";
import { EventHeader } from "./";
import { pageNames } from "./EventSidebar";
import SaveButton from "./SaveButton";

const FifthStep = ({ disabled }) => {
  const { id: eventId } = useParams();

  const dispatch = useDispatch();
  const createEventInfo = useSelector((state) => state.app.createEventInfo);
  const access = createEventInfo.access;
  const questions = createEventInfo.questions;
  const maxQuests = createEventInfo.maxQuests;

  const MaxImageSizeMb = 25;
  const MinImageResolution = { width: 1136, height: 640 };
  const MaxImageResolution = { width: 1920, height: 1080 };
  const TextAboutAllowedResolution = `Разрешение: ${generateTextAboutAvailableResolution(
    MinImageResolution,
    MaxImageResolution
  )}`;

  const onAccessChange = (type) => {
    dispatch(setCreateEventInfo({ access: type }));
  };

  const onMaxQuestsChange = (e) => {
    dispatch(setCreateEventInfo({ maxQuests: e.target.value }));
  };

  const onEditQuestions = (id) => {
    if (disabled) return;
    dispatch(setEditQuestion(id));
  };

  const onChangeQuestions = (id, e) => {
    if (disabled) return;
    dispatch(setChangeQuestion({ id: id, text: e.target.value }));
  };

  const onNewQuestion = () => {
    if (disabled) return;
    onEditQuestions();
    const obj = { number: Math.random(), text: "", edit: true };
    dispatch(setNewQuestion(obj));
  };

  const onDeleteQuestion = (id) => {
    if (disabled) return;
    dispatch(setDeleteQuestion(id));
    onEditQuestions();
  };

  const onSaveQuestion = (id, e) => {
    if (disabled) return;
    if (e.code === "Enter") {
      dispatch(setSaveQuestion(id));
    }
  };

  const isEditing = () => {
    if (questions.filter((question) => question.edit).length >= 1) {
      return true;
    } else {
      return false;
    }
  };

  const logo = createEventInfo.logo;

  const onLogoChange = async (e) => {
    const logo = e.target.files[0];

    if (!logo) {
      return;
    }

    // check size
    if (logo.size > MaxImageSizeMb * 1024 * 1024) {
      return errorMessage(generateTextErrorMaxSize(MaxImageSizeMb));
    }

    // check resolution
    const { width, height } = await getImageSize(logo);
    if (!width && !height) {
      return;
    }
    if (width < MinImageResolution.width || height < MinImageResolution.height) {
      return errorMessage(
        generateTextErrorMinResolution(
          MinImageResolution.width,
          MinImageResolution.height
        )
      );
    }
    if (width > MaxImageResolution.width || height > MaxImageResolution.height) {
      return errorMessage(
        generateTextErrorMaxResolution(
          MaxImageResolution.width,
          MaxImageResolution.height
        )
      );
    }

    window.createEventLogo = logo;
    const localImageUrl = window.URL.createObjectURL(logo);
    dispatch(setCreateEventInfo({ logo: localImageUrl }));
  };

  const onLogoDelete = () => {
    window.createEventLogo = null;
    dispatch(setCreateEventInfo({ logo: "" }));
  };

  const shortDescription = createEventInfo.shortDescription;

  const onShortDescription = (e) => {
    dispatch(setCreateEventInfo({ shortDescription: e.target.value }));
  };

  const onSave = async () => {
    try {
      const logoFile = window.createEventLogo;

      await createEventDetailDescription({
        eventId,
        questions: questions.map((question) => question.text),
        limitDaily: maxQuests,
        limit: "",
        type: access,
        logo: logoFile,
        shortDescription,
      });

      dispatch(
        setFillingPageName({
          eventId,
          name: pageNames.PROGRAMM,
        })
      );

      successMessage("Подробное описание успешно сохранено.");
    } catch (error) {
      errorHandler(error);
    }
  };

  const isAllow = shortDescription.length >= 10 && logo && !disabled;

  return (
    <div className="create-stand__content create-stand-content">
      <EventHeader
        text="Общее описание и регистрация"
        SaveButton={<SaveButton disabled={!isAllow} onClick={onSave} />}
      />
      <div className="create-stand-content__main">
        <div className="create-event-content__form" style={{ maxWidth: 650 }}>
          <Input
            error={
              shortDescription && shortDescription.length < 10 ? (
                <p className="input__text">
                  Описание не может содержать меньше 10 символов
                </p>
              ) : null
            }
            maxLength={255}
            isFilled={shortDescription.length >= 10}
            disabled={disabled}
            placeholder="Опишите предстоящее мероприятие"
            value={shortDescription}
            onChange={onShortDescription}
            label="Описание*"
            component="textarea"
          />
          <div hidden className="create-promotion__radio">
            <span>Приём участников</span>
            <div className="create-promotion__radio-item">
              <label>
                <input
                  checked={access === 10}
                  onChange={() => onAccessChange(10)}
                  type="radio"
                />
                Открыт для лимита
              </label>
            </div>
            <div className="create-promotion__radio-item">
              <label>
                <input
                  checked={access === 20}
                  onChange={() => onAccessChange(20)}
                  type="radio"
                />
                Только по приглашениям
              </label>
            </div>
          </div>
          <div hidden className="input input--promotion">
            <label className="input__label">Макс. число гостей в день</label>
            <input
              value={maxQuests}
              onChange={onMaxQuestsChange}
              className={classNames("input__main", { filled: maxQuests })}
              placeholder="Введите число"
              type="number"
              min={0}
            />
          </div>
          <div className="create-event__questions">
            <h5 className="create-event__questions-title">
              Вопросы при регистрации посетителей
            </h5>
            <div className="create-event__questions-main">
              {questions.map((question, index) =>
                !question.edit ? (
                  <div key={index} className="create-event__questions-item">
                    <div className="create-event__questions-item-text">
                      <span>{index + 1 + "."}</span>
                      {question.text + "*"}
                    </div>
                    <div className="create-event__questions-item-icon">
                      <img
                        onClick={() => onEditQuestions(question.number)}
                        src={questionEdit}
                        alt="Edit"
                      />
                      <img
                        onClick={() => onDeleteQuestion(question.number)}
                        src={questionDelete}
                        alt="Delete"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="create-event__questions-item-main">
                    <div key={index} className="create-event__questions-item">
                      <form className="create-event__questions-item-text create-event__questions-item-text--input">
                        <label>{index + 1 + "."}</label>
                        <input
                          value={question.text}
                          onChange={(e) => onChangeQuestions(question.number, e)}
                          key={index}
                          text="type"
                          onKeyPress={(e) => onSaveQuestion(question.number, e)}
                          autoFocus
                        />
                      </form>
                      <div className="create-event__questions-item-icon">
                        <img
                          onClick={() => onDeleteQuestion(question.number)}
                          src={questionDelete}
                          alt="Delete"
                        />
                      </div>
                    </div>
                    <p
                      className="create-event__questions-item-save"
                      onClick={() => onEditQuestions()}
                    >
                      Сохранить вопрос
                    </p>
                  </div>
                )
              )}
            </div>
            {!isEditing() ? (
              <div
                onClick={onNewQuestion}
                className="popup-user__add popup-user__add--web"
              >
                Добавить вопрос
              </div>
            ) : null}
          </div>
        </div>
        <div className="create-stand-content__sidebar">
          <div className="create-stand-content__avatar">
            <div
              className={classNames("create-stand-content__avatar-input", {
                "create-stand-content__avatar-input--red": !logo,
              })}
            >
              {logo && (
                <button
                  onClick={onLogoDelete}
                  className="create-stand-content__avatar-delete"
                >
                  <img src={popupClose} alt="Del" />
                </button>
              )}
              <input
                id="standInputLogo"
                type="file"
                className="create-stand-content__avatar-file"
                onChange={onLogoChange}
                accept="image/png, image/jpeg, image/jpg"
                disabled={disabled}
              />
              {logo && (
                <img src={logo} className="create-stand-content__avatar-image" alt="" />
              )}
              {!logo && (
                <div className="create-stand-content__add-avatar-text">
                  <img
                    src={avatarPlus}
                    alt=""
                    className="create-stand-content__add-avatar-add-img"
                  />
                  <h3>Загрузить логотип</h3>
                  <span>Или перетащите файл сюда</span>
                </div>
              )}
            </div>
            <div className="create-stand-content__avatar-posttitle">
              <div>Используйте только .jpg, .png типы файлов</div>
              <div>Максимальный размер: {MaxImageSizeMb} Мб</div>
              <div>{TextAboutAllowedResolution}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FifthStep;
