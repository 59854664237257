import React from "react";
import cn from "classnames";
import { createEventHintSvg } from "../../assets";

export const Hint = ({ hintText }) => {
  const hintIcon = createEventHintSvg;

  return (
    <div
      className={cn("event-hint", {
        "event-hint--warning": false,
      })}
    >
      <img src={hintIcon} alt="" />
      {hintText}
    </div>
  );
};
