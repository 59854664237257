import queryString from "query-string";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CreateStandSidebar, PageContent } from "../../components/events/CreateStand";
import { Header, Sidebar, SidePopupChat } from "../../components/shared";
import {useDispatch} from "react-redux";
import {fetchTariff, fetchEvent} from "../../redux/eventReducer";
import { useParams } from 'react-router-dom';

const CreateStand = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const parsedQuery = queryString.parse(history.location.search);

  const currentStep = parseInt(parsedQuery.currentStep);

  useEffect(() => {
    if(currentStep === 1){
      dispatch(fetchTariff(id));
    }
    if (currentStep < 1 || currentStep > 4 || !currentStep) {
      history.push(history.location.pathname + "?currentStep=1");
    }
  }, [dispatch, id, currentStep, history]);

  useEffect(() => {
    dispatch(fetchEvent({ eventId: id }));
  }, [dispatch, id]);

  return (
    <div className="create-stand">
      <Sidebar />
      <SidePopupChat />
      <Header />
      <main className="create-stand__main">
        <CreateStandSidebar />
        <PageContent currentStep={currentStep} />
      </main>
    </div>
  );
};

export default CreateStand;
