import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { analyticsLogoPlaceholder } from "../../../assets";

const StandInfo = () => {
  const { type } = useParams();

  const currentStand = useSelector((state) => state.events.currentStand);
  const currentEvent = useSelector((state) => state.events.currentEvent);

  let title;
  let fromDate;
  let endDate;
  let description;
  let logo;

  if (type === "event" && currentEvent) {
    fromDate = moment(currentEvent.start_date).format("DD MMM YYYY");
    endDate = moment(currentEvent.end_date).format("DD MMM YYYY");
    description = currentEvent.short_description;
    title = currentEvent.name;
    logo = currentEvent.logo;
  }

  if (type === "stand" && currentStand) {
    fromDate = moment(currentStand.event?.start_date).format("DD MMM YYYY");
    endDate = moment(currentStand.event?.end_date).format("DD MMM YYYY");
    description = currentStand.info?.short;
    title = currentStand.name;
    logo = currentStand.logo;
  }

  return (
    <div className="stand-analytics__stand-info analytics-stand-info">
      <div className="analytics-stand-info__img">
        <img src={logo || analyticsLogoPlaceholder} alt="" />
      </div>
      <div className="analytics-stand-info__main">
        <div className="analytics-stand-info__title">{title}</div>
        <div className="analytics-stand-info__date">
          <span>Даты: </span>с {fromDate || "02 фев 2021"} по {endDate || "10 фев 2021"}
        </div>
        <div className="analytics-stand-info__about">
          {description ||
            "Общее описание выставки. Посетители пространства проводят время с пользой: посещают лекции и мастер-классы, работают над проектами, читают и делятся подробнее"}
        </div>
      </div>
    </div>
  );
};

export default StandInfo;
