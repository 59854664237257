import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";

const RegistrationLink = ({ className }) => {
  return (
    <div className={cn("landing__registration", className)}>
      <Link to="/entry/registration" className="red-btn">
        Зарегистрируйся сейчас
      </Link>
    </div>
  );
};

export default RegistrationLink;
