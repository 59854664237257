import classNames from "classnames";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { closePopupSvg } from "../../../assets";
import { Button } from "../../../components/shared";
import { addStandContact } from "../../../redux/contactsReducer";
import {errorHandler} from "../../../utils/errorHandler";

const ContactsPopup = ({ setPopupFalse }) => {
  const [fullName, setFullName] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");

  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      const obj = {
        name: fullName || "-",
        company: company || "-",
        position: position || "-",
        email: email || "-",
        mobile: mobile || "-",
        address: address || "-",
        avatar: "/img/not-avatar.png",
      };

      dispatch(addStandContact(obj));
      setPopupFalse();
    } catch (error) {
      errorHandler(error);
    }
  };

  return (
    <div className="contacts-popup">
      <div className="contacts-popup__main">
        <h5 className="contacts-popup__title">Добавить контакт</h5>
        <form>
          <div className="input">
            <label className="input__label">ФИО*</label>
            <input
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              className={classNames("input__main")}
              placeholder="Введите ваше ФИО"
              type="text"
            />
          </div>
          <div className="input ">
            <label className="input__label">Компания</label>
            <input
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              className={classNames("input__main")}
              placeholder="Компания"
              type="text"
            />
          </div>
          <div className="input ">
            <label className="input__label">Должность</label>
            <input
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              className={classNames("input__main")}
              placeholder="Должность"
              type="text"
            />
          </div>
          <div className="input ">
            <label className="input__label">E-mail*</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={classNames("input__main")}
              placeholder="Введите e-mail"
              type="text"
            />
          </div>
          <div className="input ">
            <label className="input__label">Ваш контактный телефон*</label>
            <input
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              className={classNames("input__main")}
              placeholder="+375 29 667 43 23"
              type="tel"
              minLength="10"
              maxLength="12"
              required
            />
          </div>
          <div className="input ">
            <label className="input__label">Адрес</label>
            <input
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className={classNames("input__main")}
              placeholder="Адрес"
            />
          </div>
          <Link to="#" className="popup-user__add">
            Добавить еще контакт
          </Link>
          <div className="contacts-popup__buttons">
            <Button
              onClick={setPopupFalse}
              className="event-popup__link event-popup__link--cancel"
            >
              Отмена
            </Button>
            <Button onClick={onSubmit} className="event-popup__link">
              Добавить
            </Button>
          </div>
        </form>
        <div onClick={setPopupFalse} className="contacts-popup__close">
          <img src={closePopupSvg} alt="Close Icon"></img>
        </div>
      </div>
      <div className="contacts-popup__overlay"></div>
    </div>
  );
};

export default ContactsPopup;
