import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { errorHandler } from "../utils/errorHandler";
import { getBanners, getMyBanners, getBannerPlaces } from "../api/banners";

const initialState = {
  items: [],
  types: [],
  global: [],
};

export const fetchGlobalBannerPlaces = createAsyncThunk("global/banner-places", async (params) => {
  try {
    const { data } = await getBannerPlaces(params);

    const resp = data.data;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {
    errorHandler(error);
  }
});

export const fetchBanners = createAsyncThunk("banners", async (params) => {
  try {
    const { data } = await getBanners(params);

    const resp = data.data;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {
    errorHandler(error);
  }
});

export const fetchMyBanners = createAsyncThunk("banners", async (params) => {
  try {
    const { data } = await getMyBanners(params);

    const resp = data.data;
    if (!resp) {
      return;
    }

    return resp;
  } catch (error) {
    errorHandler(error);
  }
});

export const bannerSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    setTypes(state, { payload }) {
      const { type, count, pattern } = payload;
      for(let i = 1; i <= parseInt(count); i++){
        let id = `${type}${i}`;
        let check = state.types.find(item => item.id === id);
        if(!check){
          state.types.push({
            id, type,
            order: i,
            price: 0,
            name: `${pattern} #${i}`
          });
        }
      }
    },
  },
  extraReducers: {
    [fetchGlobalBannerPlaces.fulfilled]: (state, { payload }) => {
      state.global = payload;
    },
    [fetchBanners.fulfilled]: (state, { payload }) => {
      state.items = payload;
    },
    [fetchMyBanners.fulfilled]: (state, { payload }) => {
      state.items = payload;
    },
  },
});

export const { setTypes } = bannerSlice.actions;
export default bannerSlice.reducer;
