import React from "react";
import { PopupWithTabs, PopupWrapper } from "../../shared";
import cn from "classnames";
import { useState } from "react";
import { bannerIcons } from "../../../pages/stand/3dModel";
import { LogoDropfield } from "./LogoDropfield";

const MAX_DESC_LENGTH = 150;

const getAllowedFileExt = (fileType) => {
  if (fileType === "image") return ["image/jpeg", "image/png"];
  if (fileType === "video") {
    return ["video/mp4", "video/mov", "video/avi"];
  }
  if (fileType === "presentation") {
    return ["application/pdf"];
  }

  return "*";
};

export const UploadMaterialPopup = ({
  onClose,
  onSave,
  loadedDesc,
  loadedImage,
  loadedFile,
  loadedLogo,
}) => {
  const [description, setDescription] = useState(loadedDesc || "");

  const [mainContent, setMainContent] = useState(loadedImage || "");
  const [mainContentParams, setMainContentParams] = useState({});

  const [logoParams, setLogoParams] = useState({});

  const [popupOpened, setPopupOpened] = useState(false);
  const [popupType, setPopupType] = useState("");

  return (
    <>
      {popupOpened && (
        <PopupWithTabs
          hideTabs={popupType !== "video"}
          image={mainContent}
          file={loadedFile}
          hasDesc={false}
          title="Загрузить файл"
          hasPreview={true}
          validFileExtensions={getAllowedFileExt(popupType)}
          typeWarnMsg={`файлы форматов: ${getAllowedFileExt(popupType).map(
            (ext) => " " + ext.split("/")[1]
          )}`}
          onClose={() => setPopupOpened(false)}
          onSave={(params) => {
            const selectedFile = params.thumb;
            const reader = new FileReader();

            reader.onload = function (event) {
              setMainContent(event.target.result);
            };

            if (selectedFile) {
              reader.readAsDataURL(selectedFile);
            }

            setMainContentParams(params);
          }}
        />
      )}
      <PopupWrapper width={450} title="Загрузка материалов стенда" onCloseClick={onClose}>
        <div className="upload-material-popup">
          <LogoDropfield setLogoParams={setLogoParams} imageFromServer={loadedLogo} />
          <div className="upload-material-popup__content">
            {mainContent ? (
              <img src={mainContent} className="upload-material-popup__img" alt="" />
            ) : (
              ""
            )}
            <div className="model__banner-icons">
              {bannerIcons.map((i, ind) => (
                <img
                  key={ind}
                  onClick={() => {
                    setPopupOpened(true);
                    setPopupType(i.type);
                  }}
                  src={i.icon}
                  alt=""
                />
              ))}
            </div>
          </div>
          <div className="upload-material-popup__desc">
            <div>
              <textarea
                style={{ width: "100%" }}
                placeholder={`Описание: ${MAX_DESC_LENGTH} символов`}
                type="text"
                className={cn("default-input model-popup__description-input", {
                  "default-input--error": description?.length >= MAX_DESC_LENGTH,
                })}
                value={description || ""}
                onChange={(e) => setDescription(e.target.value)}
              />
              {description?.length >= MAX_DESC_LENGTH && (
                <span className="input-error">
                  Максимальная длина описания {MAX_DESC_LENGTH} символов
                </span>
              )}
            </div>
          </div>
          <div className="upload-material-popup__buttons">
            <button className="white-btn" onClick={onClose}>
              Отмена
            </button>
            <button
              className="red-btn"
              onClick={() => {
                onSave({ mainContentParams, logoParams, desc: description });
                onClose();
              }}
            >
              Сохранить
            </button>
          </div>
        </div>
      </PopupWrapper>
    </>
  );
};
