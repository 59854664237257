import React, { useEffect, useRef, useState } from "react";
import { fullScreenSvg, fullScreenBackSvg, standsPlaceholder } from "../../assets";
import { Loader } from "../shared";
import cn from "classnames";
import { useSelector } from "react-redux";
import { errorHandler } from "../../utils/errorHandler";
import { getEventInfo } from "../../api/event";

// количество фреймов в компоненте
const framesCount = 1;

const PlanExample = ({
  eventId,
  onStandClick = () => {},
  externalFrameRef = null,
  wrapperClassName = "",
  withViewerSidePanel = false,
}) => {
  const innerFrameRef = useRef(null);
  const frameRef = externalFrameRef || innerFrameRef;

  const [wideFrame, setWideFrame] = useState(false);
  const [stands, setStands] = useState([]);

  const event = useSelector((state) => state.events.currentEvent);
  const planViewerUrl = process.env.REACT_APP_PLANVIEWER_URL || `/planviewer/index.html`;

  const [framesLoaded, setFramesLoaded] = useState(0);

  const currentTariffId =
    useSelector((state) => state.app.createStandInfo.tariff?.id) ||
    event?.stand_tariff?.id ||
    event?.tariff_id;
  let event_data = undefined;

  useEffect(() => {
    if (event) {
      let items = [];
      if (event?.plan) {
        items.push(event.plan);
      }
      for (let k in event.position) {
        let stand = event.position[k];
        items.push({
          number: stand.number,
          tmpl_id: stand.tmpl_id,
          loc: stand.px_position,
          size: stand.px_size,
          type: stand.size,
          angle: stand.rotation,
          name: stand.company ?? "",
          occupied: !!stand.is_reserved,
          image: stand.image,
          preview_image_top: stand.preview_image_top,
          logo: stand.logo,
        });
      }

      if (items.length > 0) {
        setStands(items);
      }
    }
  }, [event]);

  const onWideFrame = (type) => {
    if (wideFrame === type) {
      type = false;
    }
    setWideFrame(type);

    frameRef.current.contentWindow.postMessage(
      {
        type: type ? "zoomIn" : "zoomOut",
      },
      "*"
    );
  };

  useEffect(() => {
    const messageHandler = async (msg) => {
      try {
        const data = msg.data;

        if (event && data.type === "getScheme") {
          frameRef.current.contentWindow.postMessage(
            {
              type: "loadScheme",
              stands,
            },
            "*"
          );

          if (withViewerSidePanel) {
            // request more event data to get list of available stand templates
            // eslint-disable-next-line react-hooks/exhaustive-deps
            event_data = await getEventInfo({ eventId: event.id, isConstructor: true });

            let chosen_tariff_type = null;
            event.tariff_stands.forEach((element) => {
              if (element.id === currentTariffId) chosen_tariff_type = element.type;
            });

            frameRef.current.contentWindow.postMessage(
              {
                type: "setStandSelectionType",
                chosen_tariff_type,
                stands: event_data.data.data.stands,
              },
              "*"
            );
          }
        }

        if (data.type === "planviewer_selected_stand" && data.data) {
          onStandClick(data.data);
        }

        if (data.type === "setStandType" && data.number && data.new_tmpl_id) {
          console.log("setStandType", data);

          const n = data.number;
          const new_tmpl_id = data.new_tmpl_id;

          // выбрать картинку по ид шаблона
          let img_path = "";
          for (const i in event_data.data.data.stands) {
            const s = event_data.data.data.stands[i];
            if (new_tmpl_id === s.id) {
              img_path = s.source;
              break;
            }
          }
          if (img_path.length > 0)
            document.getElementById(`id_step2_stand_icon_${n}`).src = img_path;
        }
      } catch (e) {
        errorHandler(e);
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [event, eventId, frameRef, onStandClick, stands]);

  return (
    <>
      <div className={`example example--double ${wrapperClassName}`}>
        <p className="example__text">План мероприятия</p>
        <div className="example__main" style={{ minHeight: "auto" }}>
          <div className="example-nav">
            <div className="example-nav__item"></div>
            <div className="example-nav__item"></div>
            <div className="example-nav__item"></div>
          </div>
          {stands && stands?.length > 0 && framesLoaded !== framesCount && (
            <div
              style={{ minWidth: 200, minHeight: 200, padding: 20 }}
              className="example__loader"
            >
              <Loader className="example__loader-image" />
            </div>
          )}
          {!(planViewerUrl && stands && stands?.length > 0) && (
            <div className="example__places">
              <div className="example-place__iframe-wrapper">
                <img
                  className="example-place__iframe example-place__iframe-placeholder"
                  src={standsPlaceholder}
                  alt=""
                />
              </div>
            </div>
          )}
          {planViewerUrl && stands && stands?.length > 0 && (
            <div
              className={cn("example__places", {
                "example__places--loading": framesLoaded !== framesCount,
              })}
            >
              <div
                className={
                  "example-place" + (wideFrame === true ? " example-place--full" : "")
                }
              >
                <div className="example-place__shadow">
                  <div className="example-place__iframe-wrapper">
                    <div className="example-place__wrap-iframe">
                      <iframe
                        src={planViewerUrl}
                        className="example-place__iframe"
                        frameBorder="0"
                        title={"tariff-ads1"}
                        ref={frameRef}
                        onLoad={() => {
                          setFramesLoaded(1);
                          frameRef.current.contentWindow.postMessage(
                            {
                              type: "loadScheme",
                              stands,
                            },
                            "*"
                          );
                        }}
                      ></iframe>
                      <div
                        className="example-place__wrap-fullscreen"
                        onClick={() => {
                          onWideFrame(true);
                        }}
                      >
                        <img
                          src={fullScreenSvg}
                          alt=""
                          className="example-place__fullscreen"
                        />
                        <img
                          src={fullScreenBackSvg}
                          alt=""
                          className="example-place__fullscreen-back"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PlanExample;
