import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Layout } from "../../components/shared";
import { NavStand, StandHeader } from "../../components/stand";
import {
  PromotionItem,
  PromotionLinks,
  PromotionPopup,
} from "../../components/stand/promotion";
import { fetchMyBanners } from "../../redux/bannerReducer";

const Promotion = () => {
  const { type, id } = useParams();
  const dispatch = useDispatch();
  const role = useSelector((state) => state.user.profile.role);
  const [fullImg, setFullImg] = useState(null);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const banners = useSelector((state) => state.banners.items);

  useEffect(() => {
    if (role !== "организатор" && type === "event") {
      return;
    }

    dispatch(
      fetchMyBanners({
        [`${type}Id`]: id,
      })
    );
  }, [dispatch, type, id, role]);

  const title =
    (role === "участник" && "Продвижение стенда") ||
    (role === "организатор" && "Продвижение мероприятия");

  const subtitle =
    (role === "участник" &&
      "Здесь вы можете выбрать рекламу и продвижение своего стенда") ||
    (role === "организатор" &&
      "Здесь вы можете выбрать рекламу и продвижение своего мероприятия");

  const onFullBanner = (img) => {
    setFullImg(img);
    setPopupIsOpen(true);
  };

  return (
    <Layout>
      <div className="organizer-panel__main">
        {popupIsOpen && (
          <PromotionPopup fullImg={fullImg} onClose={() => setPopupIsOpen(false)} />
        )}
        <NavStand />
        <div className="stand-settings">
          <StandHeader title={title} subtitle={subtitle} withHint={false} />
          <div className="promotion">
            <h5 className="promotion__title">Мои заказы</h5>
            <main className="promotion__main">
              <div className="promotion__banners">
                {banners?.map((item, index) => (
                  <PromotionItem
                    key={index + 1}
                    title={item.name}
                    active={item.active}
                    statusCode={item.status_code}
                    status={item.status}
                    img={item.image}
                    id={item.id}
                    size={item.size}
                    price={item.amount}
                    startDate={item.started_at}
                    views={item.views}
                    clicks={item.clicks}
                    endDate={item.ended_at}
                    onOpen={() => onFullBanner(item.image)}
                    createDate={item.created_at}
                  />
                ))}
              </div>
              <PromotionLinks />
            </main>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Promotion;
