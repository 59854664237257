import classNames from "classnames";
import { immediateToast } from "izitoast-react";
import queryString from "query-string";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { SidebarNavItem } from ".";
import {
  confirmCreateStand,
  createStandChooseTariff,
  createStandChooseTemplate,
  createStandDescription,
  createStandLegalInfo,
} from "../../../api/member";
import { saveJuridicalInfo } from "../../../api/user";
import { exitFromCreate } from "../../../assets";
import { useConfirmPopup } from "../../../hooks/useConfirmPopup";
import {
  clearCreateStantInfoErrors,
  setAccount,
  setCreateStandInfo,
  setCreateStandInfoErrors,
  setProfileInfo,
  setResetStandInfo,
} from "../../../redux/appReducer";
import { errorHandler } from "../../../utils/errorHandler";
import { ButtonLoader, ConfirmPopup } from "../../shared";

export const MAX_STAND_NAME_LENGTH = 100;

const Sidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const parsedQuery = queryString.parse(history.location.search);

  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();
  const eventId = parseInt(id);

  const createStandInfo = useSelector((state) => state.app.createStandInfo);
  const event = useSelector((state) => state.events.currentEvent);
  const user = useSelector((state) => state.user.profile);

  const standId = createStandInfo.standId || event.stand_id;

  const currentStep = parseInt(parsedQuery.currentStep);

  const isAllow = (currentStep) => {
    if (currentStep === 1) {
      if (createStandInfo.tariff) {
        return true;
      } else {
        return false;
      }
    }
    if (currentStep === 2) {
      if (createStandInfo.templateId && createStandInfo.standPositionId) {
        return true;
      } else {
        return false;
      }
    }
    if (currentStep === 3) {
      if (
        createStandInfo.logo &&
        createStandInfo.companyName.length >= 5 &&
        createStandInfo.companyName.length <= MAX_STAND_NAME_LENGTH &&
        createStandInfo.branch?.value &&
        createStandInfo.shortDescription.length >= 10
      ) {
        return true;
      } else {
        return false;
      }
    }
    if (currentStep === 4) {
      if (
        createStandInfo.organization.length >= 5 &&
        createStandInfo.inn.length >= 10 &&
        createStandInfo.registrationCode.length >= 9 &&
        createStandInfo.registrationNumber.length === 13 &&
        createStandInfo.directorName.length >= 2 &&
        createStandInfo.actionBasis.length >= 2 &&
        createStandInfo.juridicalAdress.length >= 3 &&
        createStandInfo.contactPhone.length >= 11 &&
        // bank info
        createStandInfo.bic.length === 9 &&
        createStandInfo.bankName.length >= 5 &&
        createStandInfo.billNumber.length === 20 &&
        createStandInfo.correspondingNumber.length === 20
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const onNextStepClick = async () => {
    try {
      dispatch(clearCreateStantInfoErrors());

      const {
        tariff,

        templateId,
        standPositionId,

        companyName,
        branch,
        shortDescription,
      } = createStandInfo;

      setIsLoading(true);

      if (currentStep === 1) {
        const { data } = await createStandChooseTariff({
          tariffId: tariff.id,
          eventId,
        });

        const responseStandId = data.data.stand_id;
        dispatch(setCreateStandInfo({ standId: responseStandId }));
      }

      if (currentStep === 2) {
        await createStandChooseTemplate({
          tmplId: templateId,
          standId,
          standPositionId,
        });
      }

      if (currentStep === 3) {
        await createStandDescription({
          company: companyName,
          occupationId: branch.value,
          shortDescription,
          logo: window.targetStandLogo || {},
          standId,
        });
      }

      setIsLoading(false);

      history.push(history.location.pathname + `?currentStep=${currentStep + 1}`);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
      const errorData = error.response?.data?.data;

      if (!errorData) {
        return;
      }

      errorData.company &&
        dispatch(setCreateStandInfoErrors({ company: errorData.company }));
      errorData.occupation_id &&
        dispatch(setCreateStandInfoErrors({ occupation_id: errorData.occupation_id }));
      errorData.short_description &&
        dispatch(
          setCreateStandInfoErrors({ short_description: errorData.short_description })
        );
      errorData.logo &&
        dispatch(setCreateStandInfoErrors({ logo: "Вы должны загрузить логотип!" }));
      errorData.event_id &&
        dispatch(setCreateStandInfoErrors({ event_id: errorData.event_id }));
    }
  };

  const onCreateStandClick = async () => {
    const {
      organization,
      inn,
      registrationNumber,
      bic,
      bankName,
      billNumber,
      correspondingNumber,
      withVAT,
      registrationCode,
      directorName,
      actionBasis,
      juridicalAdress,
      contactPhone,
    } = createStandInfo;

    try {
      setIsLoading(true);

      const { data } = await createStandLegalInfo({
        organizationName: organization,
        inn,
        registrationNumber,
        bic,
        withVAT,
        bankName,
        billNumber,
        correspondingNumber,
        standId,
        registrationCode,
        directorName,
        actionBasis,
        juridicalAdress,
        contactPhone,
      });
      if (data.success) {
        await confirmCreateStand({ standId });
        const updatedUser = await saveJuridicalInfo({
          bankName,
          bic,
          billNumber,
          inn,
          correspondingNumber,
          organizationName: organization,
          registrationNumber,
          contactPhone,
          user,
          kpp: registrationCode,
          justification: actionBasis,
          address: juridicalAdress,
        });

        dispatch(setProfileInfo(updatedUser));
        dispatch(setAccount(updatedUser));
        setIsLoading(false);
      }

      immediateToast("success", {
        message: "Заявка на стенд успешно создана.",
        position: "topCenter",
      });

      dispatch(setResetStandInfo());
      history.push("/favorite/requests");
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  const onPreviousStep = () => {
    if (currentStep > 1) {
      history.push(history.location.pathname + `?currentStep=${currentStep - 1}`);
    }
  };

  const { popupInfo, setPopupInfo, closePopup } = useConfirmPopup();

  const onExitButtonClick = () => {
    setPopupInfo({
      title: "Стенд",
      text: "Вы уверены что хотите выйти из создания заявки?",
      onCancelClick: closePopup,
      onConfirmClick: () => {
        history.push("/favorite/requests");
      },
      isOpened: true,
    });
  };

  return (
    <div className="create-stand__sidebar create-stand-sidebar">
      {popupInfo.isOpened && (
        <ConfirmPopup popupInfo={popupInfo} onCloseClick={closePopup} />
      )}
      <div className="create-stand-sidebar__nav">
        <SidebarNavItem
          isDone={currentStep > 1}
          number={1}
          isActive={currentStep === 1}
          text="Выбор тарифа"
        />
        <SidebarNavItem
          isDone={currentStep > 2}
          number={2}
          isActive={currentStep === 2}
          text="Выбор стенда"
        />
        <SidebarNavItem
          isDone={currentStep > 3}
          number={3}
          isActive={currentStep === 3}
          text="Общее описание"
        />
        <SidebarNavItem
          isDone={currentStep > 4}
          number={4}
          isActive={currentStep === 4}
          text="Юридическая информация"
        />
      </div>
      <div className="create-stand-sidebar__buttons">
        <button
          disabled={currentStep === 1}
          className="create-stand-sidebar__skip-btn"
          onClick={onPreviousStep}
        >
          Предыдущий шаг
        </button>
        {currentStep === 4 ? (
          <button
            disabled={!isAllow(currentStep)}
            onClick={onCreateStandClick}
            className={classNames("create-stand-sidebar__next-btn", "button", {
              disabled: !isAllow(currentStep),
            })}
          >
            {isLoading ? <ButtonLoader /> : "Создать стенд"}
          </button>
        ) : (
          <button
            disabled={!isAllow(currentStep)}
            className={classNames("create-stand-sidebar__next-btn", "button", {
              disabled: !isAllow(currentStep),
            })}
            onClick={onNextStepClick}
          >
            {isLoading ? <ButtonLoader /> : `Следующий шаг ${currentStep}/4`}
          </button>
        )}
        <button onClick={onExitButtonClick} className="create-stand-sidebar__exit-btn">
          <img src={exitFromCreate} alt="" /> <span>Выйти</span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
