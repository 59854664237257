import { useSelector } from "react-redux";
import { createEventPages } from "../../components/organizer-panel/CreateEvent";
import { pageNames } from "../../components/organizer-panel/CreateEvent/EventSidebar";

const demoPages = [
  pageNames.LANDING,
  pageNames.CONTACTS,
  pageNames.MAILING,
  pageNames.DOCUMENTS,
  pageNames.PARTICIPANTS,
  pageNames.MODERATION,
  pageNames.WEBINAR,
  pageNames.PROMOTION,
  pageNames.ANALYTICS,
];

const isDemoPage = (pageName) => {
  return demoPages.some((p) => p === pageName);
};

export const useCreateEventPageDisabled = (pageName) => {
  const fillingPageName = useSelector((state) => state.events.fillingPageName);
  const currentEvent = useSelector((state) => state.events.currentEvent);
  const [eventStatusType] = currentEvent?.status_type || [];

  const currentPageIndex = createEventPages.findIndex((p) => p.name === pageName);
  const fillingPageIndex = createEventPages.findIndex((p) => p.name === fillingPageName);

  if (fillingPageIndex !== -1 && fillingPageIndex < currentPageIndex) {
    return true;
  }

  if (eventStatusType === "requests" && isDemoPage(pageName)) {
    return true;
  }

  if (
    eventStatusType === "filling" &&
    pageName !== pageNames.DOCUMENTS &&
    currentEvent?.status_code !== 42
  ) {
    return true;
  }

  return false;
};
