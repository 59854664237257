import React from "react";
import moment from "moment";

const dummyDocuments = [
  // {
  //   date: new Date(),
  //   number: 1,
  //   name: "Farm expo",
  //   price: "60000",
  //   VAT: "2000",
  //   status: "solved",
  // },
];

const TableDocuments = () => {
  return (
    <main className="documents table-responsive">
      <table className="table table-bordered">
        <thead className="table__head">
          <tr>
            <th scope="col">Дата</th>
            <th scope="col">Номер документа</th>
            <th scope="col">Услуга</th>
            <th scope="col">Стоимость, руб</th>
            <th scope="col">НДС, руб</th>
            <th scope="col">Статус</th>
          </tr>
        </thead>
        <tbody className="table__body">
          {dummyDocuments &&
            dummyDocuments.length > 0 &&
            dummyDocuments.map((d, i) => (
              <tr key={i}>
                <td>{moment(d.date).format("DD MMM YYYY")}</td>
                <td>{d.number}</td>
                <td>{d.name}</td>
                <td>{d.price}</td>
                <td>{d.VAT}</td>
                <td
                  className={
                    d.status === "solved" ? "table__status-success" : "table__status-wait"
                  }
                >
                  <span>{d.status === "solved" ? "Оплачено" : "Ожидание"}</span>
                </td>
                <td>
                  <a
                    className="table__print-btn red-btn"
                    href="/"
                    target="_blank"
                  >
                    Распечатать
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </main>
  );
};

export default TableDocuments;
