import React from "react";

const MailingSentSearch = (props) => {
    const handleChange = (e) => {
      props.onQueryChange(e.target.value);
    }

    const query = props.query;

    return (
        <input type="text" value={query} onChange={handleChange} className="mailingsearch"
               placeholder="Поиск по названию"/>
    );
  }
export default MailingSentSearch;
