import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMemberFullInfo } from "../../../api/member";
import { setCreateStandInfo } from "../../../redux/appReducer";
import JuridicalForm from "../JuridicalForm";

const FourthStep = () => {
  const createStandInfo = useSelector((state) => state.app.createStandInfo);
  const user = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();

  const withVAT = createStandInfo.withVAT;

  useEffect(() => {
    const fetchInitialInfo = async () => {
      try {
        const { data } = await getMemberFullInfo();

        const info = data?.data?.info;

        if (!info) {
          return;
        }

        // maybe load stored stand info and merge it with available user data
        dispatch(
          setCreateStandInfo({
            inn: info.inn || "",
            organization: info.organization_name || info.organization || "",
            registrationNumber: info.registration_number || "",
            bankName: info.bank_name || "",
            bic: info.bic || "",
            billNumber: info.bill_number || "",
            correspondingNumber: info.corresponding_number || "",
            withVAT: info.vat || false,
            contactPhone: user.mobile || "",
            registrationCode: info?.kpp || "",
            directorName: user.fullName || "",
            actionBasis: info?.justification || "",
            juridicalAdress: info?.address || "",
          })
        );
      } catch (error) {}
    };

    fetchInitialInfo();
  }, [dispatch, user.fullName, user.mobile]);

  const onVATChange = () => {
    dispatch(setCreateStandInfo({ withVAT: !withVAT }));
  };

  return (
    <>
      <div className="create-stand-content__posttitle">Юридическая информация</div>
      <div className="create-stand-content__main">
        <JuridicalForm
          getFieldValue={(fieldName) => createStandInfo[fieldName]}
          onChange={({ fieldName, value }) => {
            dispatch(setCreateStandInfo({ [fieldName]: value }));
          }}
          withVAT={withVAT}
          onVATChange={onVATChange}
        />
      </div>
    </>
  );
};

export default FourthStep;
