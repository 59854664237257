import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  info: { video: "", logo: "", pdf: "", firstImage: "", secondImage: "" },
};

export const eventSettingsSlice = createSlice({
  name: "eventSettings",
  initialState,
  reducers: {
    setEventSettings(state, { payload }) {
      state.info = { ...payload };
    },
  },
});

export const { setEventSettings } = eventSettingsSlice.actions;
export default eventSettingsSlice.reducer;
