import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {errorHandler} from "../utils/errorHandler";
import { createMailingTemplate, getMailings, getMailingTemplates } from "../api/mailing";

const initialState = {
  mailingTemplates: [],
  mailings: [],
};

export const fetchMailingTemplates = createAsyncThunk("mailing/templates", async (params) => {
  try {
    const { data } = await getMailingTemplates(params);

    const resp = data.data;
    if (!resp) {
      return [];
    }

    return resp;
  } catch (error) {
    errorHandler(error);
    return [];
  }
});

export const saveNewMailingTemplate = createAsyncThunk("mailing/templates/create", async ({type, id, name, template_json, template_html}) => {
  try {
    const {data} = await createMailingTemplate({ type, id, mailing_template: {name, template_json, template_html} });

    const resp = data.data;

    return resp;
  } catch (error) {
    errorHandler(error);
    return [];
  }
});

export const fetchMailings = createAsyncThunk("mailings/fetch", async ({ type, id }) => {
  try {
    const {data} = await getMailings({type, id});

    return data.data;
  } catch (error) {
    errorHandler(error);
    return [];
  }
})

export const mailingSlice = createSlice({
  name: "mailing",
  initialState,
  reducers: {
    setMailingTemplates(state, { payload }) {
      state.mailingTemplates = payload;
    },
    pushNewMailingTemplate(state, { payload }) {
      state.mailingTemplates.push(payload);
    },
    setMailingTemplateName(state, { payload }) {
      // eslint-disable-next-line
      const index = state.mailingTemplates.findIndex(template => template.id == payload.id);
      state.mailingTemplates[index].name = payload.name;
    },
  },
  extraReducers: {
    [fetchMailingTemplates.fulfilled]: (state, { payload }) => {
      state.mailingTemplates = payload;
    },
    [fetchMailings.fulfilled]: (state, { payload }) => {
      state.mailings = payload;
    }
  }
});

export const {
  setMailingTemplates,
  setMailingTemplateName,
  pushNewMailingTemplate,
} = mailingSlice.actions;
export default mailingSlice.reducer;
