import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WithContext as ReactTags } from "react-tag-input";
import { sendMailing } from "../../api/mailing";
import { CloseSvg } from "../../assets";
import { errorHandler } from "../../utils/errorHandler";
import { Loader } from "./index";

const PopupMailing = ({ title, onClose, type, id, templateId, onSent }) => {
  const [tags, setTags] = useState([]);
  const [mailingSubject, setMailingSubject] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormFulfilled, setIsFormFulfilled] = useState(false);

  useEffect(() => {
    setIsFormFulfilled(mailingSubject.length > 0 && tags.length > 0);
  }, [setIsFormFulfilled, mailingSubject, tags]);

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const re = useMemo(() => {
    return /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  }, []);

  const handleAddition = (tag) => {
    if (re.test(String(tag.text).toLowerCase())) {
      setTags([...tags, tag]);
    }
  };

  const addToTags = useCallback(
    (add_tags) => {
      let newTags = [];
      if (add_tags.includes(",")) {
        newTags = add_tags.split(",");
      } else if (add_tags.includes(" ")) {
        newTags = add_tags.split(" ");
      } else {
        newTags = [add_tags];
      }

      newTags = newTags.map((t) =>
        t
          .split("")
          .filter((letter) => letter !== "\r" && letter !== "\n")
          .join("")
          .trim()
      );

      let oldTags = [];
      newTags.forEach((element) => {
        if (re.test(String(element).toLowerCase())) {
          let inputElement = {
            id: element,
            text: element,
          };
          oldTags = [...oldTags, inputElement];
        }
      });

      setTags([...tags, ...oldTags]);
    },
    [re, tags, setTags]
  );

  const setMailingSubjectFunc = (event) => {
    setMailingSubject(event.target.value);
  };

  const callSendMailing = async () => {
    try {
      setIsLoading(true);
      await sendMailing({
        type,
        id,
        templateId,
        subject: mailingSubject,
        recipients: tags.map((tag) => tag.text),
      });
      setTags([]);
      onSent();
    } catch (error) {
      errorHandler(error);
    }

    setIsLoading(false);
  };
  const showFile = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      addToTags(text);
    };
    reader.readAsText(e.target.files[0]);
  };

  return (
    <div className="model-popup">
      <div className="model-popup__bg"></div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="model-popup__main">
          <div className="model-popup__header">
            <h3>{title}</h3>
            <button onClick={onClose}>
              <img src={CloseSvg} alt="#" />
            </button>
          </div>
          <div className="sendMailingForm">
            <label>Тема письма*</label>
            <input
              onChange={setMailingSubjectFunc}
              className="input__main mailing_input"
              type="text"
            />
            <label>E-mail*</label>
            <ReactTags
              tags={tags}
              allowDragDrop={false}
              allowUnique={true}
              handleDelete={handleDelete}
              handleAddition={handleAddition}
              maxLength={999999999999}
              allowAdditionFromPaste={false}
              delimiters={[9, 32, 188]}
              placeholder="Введите e-mail и нажмите пробел"
            />
          </div>
          <label className="contactsimportlabel">
            Импортировать контакты
            <input
              accept="*.txt"
              type="file"
              className="contactsimport"
              onChange={(e) => showFile(e)}
            />
          </label>
          <div className="mailing-helper-text">
            Загрузите файл формата .txt с емейл адресами, разделенными ","
          </div>
          <div className="model-popup__buttons">
            <button className="model-popup__cancel-btn" onClick={onClose}>
              Отмена
            </button>
            <button
              disabled={!isFormFulfilled}
              onClick={callSendMailing}
              className="model-popup__load-btn"
            >
              Отправить
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopupMailing;
