import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { chooseRole } from "../../../api";
import { setProfileInfo } from "../../../redux/userReducer";
import { errorHandler } from "../../../utils/errorHandler";
import { authManager } from "../../../utils/managers";

const finalRegistrationUrl = "/entry/registration/create";

const Roles = ({ roles }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onClick = async ({ serverName }) => {
    try {
      const { data } = await chooseRole(serverName);
      if (data.data.user) {
        data.data.user.role_id = serverName;
        data.data.user.step = "create-account";
        data.data.user.token = authManager().getToken();
        dispatch(setProfileInfo(data.data.user));

        history.push(finalRegistrationUrl);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  return roles.map((role, index) => (
    <span
      key={index}
      onClick={() => onClick(role)}
      className="registration-continue__item"
    >
      {role.icon}
      <h5 className="registration-continue__title">{role.name}</h5>
    </span>
  ));
};

export default Roles;
