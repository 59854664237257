import { mainContent } from "../assets";
import moment from "moment";

export const prepareEventList = (eventList, config) => {
  const { withoutDebug = false } = config || {};

  let filteredEvents = eventList;

  if (withoutDebug) {
    filteredEvents = filteredEvents.filter((eve) => !eve.name.includes("#debug"));
  }

  return filteredEvents.map((eve) => {
    const startDate = moment(eve.start_date, "YYYY-MM-DD HH:mm:ss").toDate();
    const endDate = moment(eve.end_date, "YYYY-MM-DD HH:mm:ss").toDate();

    const positions = Object.values(eve.position);
    const availableStandsCount = positions.reduce((acc, curr) => {
      // место для стенда занято
      if (curr.is_reserved === true) {
        return acc + 0;
      } else {
        return acc + 1;
      }
    }, 0);

    const isStandRejected = eve.stand_status_code === 40 || eve.stand_status_code === 42;

    let status = eve.status?.toLowerCase();
    if (status === "черновик") {
      status = "наполнение";
    }

    if (status === "ждёт наполнения" || status === "лобби отклонено") {
      status = "оплата";
    }

    if (status === "лобби на модерации") {
      status = "подтверждение оплаты";
    }

    return {
      title: eve.name,
      poster: eve.logo || mainContent,
      text: eve.short_description,
      visitTypeId: parseInt(eve.visit_type_id),
      availableSeats: 100,
      organizer: eve.organizer || null,
      actual: false,
      id: eve.id,
      month: startDate.getMonth() || "01",
      year: startDate.getFullYear() || "2000",
      startDate,
      endDate,
      isUserRegistered: eve.user_registered,
      isStandCreated: eve.is_stand_created,
      isEventCreated: eve.is_event_created,
      position: eve.position,
      status,
      statusCode: eve.status_code,
      standStatus: eve.stand_status?.toLowerCase(),
      standId: eve.stand_id,
      standStatusCode: eve.stand_status_code,
      availableStandsCount: availableStandsCount,
      standStatusType: (eve.stand_status_type && eve.stand_status_type[0]) || null,
      comment: (isStandRejected && eve.stand_comment) || "",
      // для евент календаря
      start: moment(eve.start_date)._d.toString(),
      end: moment(eve.end_date)._d.toString(),
      color: "blue",
      type: eve.room_type,
    };
  });
};
