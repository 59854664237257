import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowBottomSvg, ArrowLeftSvg } from "../../assets";
import { HeaderPopup, HeaderInfo } from "../../components/shared";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";

export const Header = ({ title }) => {
  const [visiblePopup, setVisiblePopup] = useState(false);
  const profile = useSelector((state) => state.user.profile);

  const toggleVisiblePopup = () => {
    setVisiblePopup(!visiblePopup);
  };

  return (
    <>
      <header className="events-header">
        <div className="events-header__bg"></div>
        <div className="events-header__block">
          <Link to="/events/list" className="events-header__exit">
            <img src={ArrowLeftSvg} alt="#" />
            Выйти
          </Link>
          <h2 className="events-header__title">{title}</h2>
        </div>
        <div className="events-header__info">
          <HeaderInfo />
        </div>
        <div className="events-header__block">
          <div className="events-header__user-panel" onClick={toggleVisiblePopup}>
            <div className="events-header__user-info">
              <div className="events-header__username">{profile.fullName}</div>
              <div className="events-header__user-role">
                {capitalizeFirstLetter(profile.role)}
              </div>
            </div>
            <img src={profile.avatar} alt="" className="events-header__user-photo" />
            <div
              onClick={() => setVisiblePopup(!visiblePopup)}
              className="events-header__dropdown-btn"
            >
              <img src={ArrowBottomSvg} alt="#" />
              <div className="events-header__popup">
                {visiblePopup && (
                  <HeaderPopup closePopup={() => setVisiblePopup(false)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
