import React from "react";

export const Contact = ({ id, name, role, status, photo, count, onClick }) => {
  return (
    <div onClick={() => onClick(id)} key={id} className="side-chat__contact">
      <div className="side-chat__contact-image">
        <img src={photo} alt="" className="side-chat__contact-photo" />
        { parseInt(count) > 0 && <div className="side-chat__contact-count">{count}</div> }
      </div>
      <div className="side-chat__contact-info">
        <div className="side-chat__contact-fullname">{name}</div>
        <div className="side-chat__contact-position">{role}</div>
      </div>
      <div className="side-chat__contact-online-status">
        {status === "online" ? "Онлайн" : null}
      </div>
    </div>
  );
};
