import React from "react";
import { settingsPopup } from "../../assets";

const PopupControlButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="white-btn event-popup__button">
      <img src={settingsPopup} alt="settings" />
      Управлять
    </button>
  );
};

export default PopupControlButton;
