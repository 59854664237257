import { immediateToast } from "izitoast-react";
import React, { useState } from "react";
import { useParams } from "react-router";
import { ConfirmPopup } from ".";
import { sendEmails } from "../../api/organizer";
import { avatarPlus, CloseSvg, fileLogo } from "../../assets";
import { useConfirmPopup } from "../../hooks/useConfirmPopup";
import { errorHandler } from "../../utils/errorHandler";

const PopupInvitation = ({
  title,
  validFileExtensions,
  typeWarnMsg,
  onSave,
  onClose,
}) => {
  const { id, type } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [emails, setEmails] = useState([""]);

  const onAddEmailClick = () => {
    setEmails([...emails, ""]);
  };

  const onEmailChange = (ind, text) => {
    setEmails((prev) => prev.map((e, i) => (i === ind ? text : e)));
  };

  const onValidateInput = (oInput) => {
    if (oInput.type === "file") {
      var sFileName = oInput.value;
      if (sFileName.length > 0) {
        var blnValid = false;
        for (var j = 0; j < validFileExtensions.length; j++) {
          var sCurExtension = validFileExtensions[j];
          if (
            sFileName
              .substr(sFileName.length - sCurExtension.length, sCurExtension.length)
              .toLowerCase() === sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }

        if (!blnValid) {
          immediateToast("error", {
            message:
              "Формат файла некорректный, доступные форматы: " +
              validFileExtensions.join(", "),
            position: "topCenter",
          });
          oInput.value = "";
          return false;
        }
      }
    }
    return true;
  };

  const onFileChange = async (e) => {
    console.log(isLoading);
    try {
      if (onValidateInput(e.target)) {
        const file = e.target.files[0];
        setCurrentFile(file);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const onLoad = async () => {
    setIsLoading(true);

    try {
      const file = currentFile;
      try {
        if (emails.length > 0) {
          await sendEmails({ [`${type}Id`]: id, emails });
        }
        if (file) {
          await onSave(file);
        }
        setTimeout(() => {
          onClose();
        }, 2000);
      } catch (e) {
        errorHandler(e);
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      errorHandler(error);
    }
  };

  const { closePopup, popupInfo, setPopupInfo } = useConfirmPopup();

  return (
    <div className="model-popup contacts-popup--participants">
      {popupInfo.isOpened && (
        <ConfirmPopup popupInfo={popupInfo} onCloseClick={closePopup} />
      )}

      <div className="model-popup__bg"></div>
      <div className="model-popup__main">
        <div className="model-popup__header">
          <h3>{title}</h3>
          <button onClick={onClose}>
            <img src={CloseSvg} alt="#" />
          </button>
        </div>
        <div style={{ paddingBottom: 20 }}>
          <div style={{ padding: 0 }} className="input input--participants">
            <label className="input__label">E-mail</label>
            {emails.map((em, ind) => (
              <input
                key={ind}
                className="input__main input__main--participants"
                placeholder="Введите e-mail"
                type="text"
                value={em}
                onChange={(e) => onEmailChange(ind, e.target.value)}
              />
            ))}
          </div>
          <button onClick={onAddEmailClick} className="popup-user__add">
            Добавить еще
          </button>
        </div>
        <div className="model-popup__file">
          <input
            onChange={onFileChange}
            type="file"
            className="model-popup__file-input"
          />

          {currentFile && (
            <div className="model-popup__wrap-file-img">
              <img src={fileLogo} alt="" className="model-popup__file-img" />
            </div>
          )}

          <div className="model-popup__file-main">
            <img src={avatarPlus} alt="" className="model-popup__file-add-img" />
            <div className="model-popup__file-text">
              <h3>Загрузить файл</h3>
              <span>Или перетащите файл сюда</span>
            </div>
          </div>
        </div>
        <div className="model-popup__posttitle">
          <h3>Используйте только {typeWarnMsg}</h3>
          <h3>Максимальный размер: 80 Мб</h3>
        </div>
        <div className="model-popup__buttons">
          <button className="white-btn" onClick={onClose}>
            Отмена
          </button>
          <button
            disabled={isLoading}
            onClick={() =>
              setPopupInfo({
                title: "Рассылка",
                isOpened: true,
                text: "Вы действительно хотите отправить рассылку?",
                onCancelClick: closePopup,
                onConfirmClick: onLoad,
              })
            }
            className="red-btn"
          >
            Отправить
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupInvitation;
