import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend
} from "recharts";
import { bannerSettings } from "../../../assets";
import { DefaultPieChartColors, forPercents } from "../../../pages/stand/Analytics";

// const dataOrigin = [
//   {
//     "dimensions": [ "female", "Age 35\u201144" ],
//     "metrics": [ 1.0, 2.0 ]
//   },
//   {
//     "dimensions": [ "male", "Age 35\u201144" ],
//     "metrics": [ 2.0, 1.0 ]
//   },
//   {
//     "dimensions": [ "female", "Age 45-54" ],
//     "metrics": [ 3.0, 4.0 ]
//   },
//   {
//     "dimensions": [ "male", "Not specified" ],
//     "metrics": [ 2.0, 2.0 ]
//   },
// ];

const Demography = () => {
  // dem [ { dimensions: [male|female, Age], metrics: [1, 2] } ]
  const dem = useSelector(state => state.events.analytics.dem);
  // data { age: {male: number, female: number} }
  const [data, setData] = useState([]);
  const [totalUsersVisits, setTotalUsersVisits] = useState(0);

  useEffect(() => {
    const totalUV = dem?.reduce((prev, current) => prev + current?.metrics?.reduce((p, c) => p + c) || 0, 0) || 0;
    setTotalUsersVisits(totalUV);

    const dataMap = {};
    dem?.forEach((e) => {
      if (e?.dimensions?.length > 1) {
        let age = e.dimensions[1];
        if (age.indexOf('Age') === 0) age = 'Возраст' + age.substring(3, age.length); // replace 'Age'
        if (!(age in dataMap)) dataMap[age] = {};
        dataMap[age][e.dimensions[0]] = e.metrics?.reduce((prev, current) => prev + current, 0) || 0;
        dataMap[age][e.dimensions[0]] = totalUV ? forPercents(dataMap[age][e.dimensions[0]], totalUV) : 0;
      }
    });
    const dataArr = [];
    for (const age in dataMap) {
      dataArr.push({...dataMap[age], age});
    }

    setData(dataArr);
  }, [dem]);

  return (
    <div className="stand-analytics__geography analytics-stand-geography">
      <div className="analytics-stand-geography__header">
        <h2 className="analytics-stand-geography__title">Демография</h2>
        <button className="analytics-stand-geography__more-btn">
          <img src={bannerSettings} alt="" />
        </button>
      </div>
      <div className="analytics-stand-geography__main">
        <div className="analytics-stand-geography__chart-wrapper">
        <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={data}
              margin={{
                top: 30,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="age" fontSize="14"/>
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="male" minPointSize="10" name="Мужчины" fill="#0E42C8" />
              <Bar dataKey="female" minPointSize="10" name="Женщины" fill="#DD344F" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="analytics-stand-geography__footer">
          {data.map((c, i) => (
              <div key={i} className="analytics-stand-geography__country">
                {/* round sum of percents with 100 */}
                <span>{totalUsersVisits ? forPercents(parseFloat(c.male || 0) + parseFloat(c.female || 0), 100) : 0} %</span>
                <h3>
                  <span style={{ backgroundColor: DefaultPieChartColors[i % DefaultPieChartColors.length] }}></span>
                  {c.age}
                </h3>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Demography;
