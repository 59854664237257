import React from "react";
import { LoaderGif } from "../../assets";
import cn from "classnames";

const Loader = ({ className, ...attrs }) => {
  return (
    <img src={LoaderGif} {...attrs} alt="Загрузка..." className={cn("loader-image", className)} />
  );
};

export default Loader;
